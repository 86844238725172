import React from 'react';
import { Helmet } from 'react-helmet';

class Git extends React.Component {
    componentDidMount() {
        window.open('https://github.com/baileyam99', '_blank');
    }

    render() {
        return (
            <main>
                <div>
                    <Helmet>
                        <title>Alex Bailey Resume | GitHub</title>
                    </Helmet>
                    <h1>Redirecting...</h1>
                </div>
            </main>
        )
    }
}

export const GitPage = () => {
    return (
        <Git />
    )
}