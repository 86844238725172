import React, {useState} from 'react';
import {IoIosArrowDropupCircle} from 'react-icons/io';
import { FloatButton } from './Buttons';
  
const ScrollButton = () =>{

  let screens = ({
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
    large: window.matchMedia("all and (min-device-width: 1601px)").matches
  });
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

  let r;
  let size = '4rem';

  if (screens.small) { r = '1px'; size = '2.5rem';}
  if (screens.medium) { r = '1em'; }
  if (screens.large) { r = '1.5em'; }
  
  return (
    <FloatButton style={{right: r, fontSize: size, zIndex: 9}}>
     <IoIosArrowDropupCircle onClick={scrollToTop} 
     style={{display: visible ? 'inline' : 'none'}} />
    </FloatButton>
  );
}
  
export default ScrollButton;