import styled from 'styled-components';

export const DeleteButton = styled.button`
  font-family: 'Comfortaa';
  background-color: red;
  color: white;
  font-size: 100%;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: maroon;
    font-weight: bold;
    cursor: pointer
  }
`;

export const ViewButton = styled.button`
  font-family: 'Comfortaa';
  background-color: #0C4773;
  color: white;
  font-size: 100%;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: #105f9c;
    font-weight: bold;
    cursor: pointer
  }
`;

export const FormSubmitButton = styled.button`
  font-family: 'Comfortaa';
  background-color: #0C4773;
  color: white;
  font-size: 110%;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: #105f9c;
    font-weight: bold;
    cursor: pointer
  }
`;

export const CancelButton = styled.button`
  font-family: 'Comfortaa';
  background-color: red;
  color: white;
  font-size: 110%;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: maroon;
    font-weight: bold;
    cursor: pointer
  }
`;

export const GeneralButton = styled.button`
  font-family: 'Comfortaa';
  background-color: #0C4773;
  color: white;
  font-size: 15px;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 5px;
  cursor: pointer;

  &:hover {
    background-color: #105f9c;
    font-weight: bold;
    cursor: pointer
  }
`;

export const GeneralButtonLg = styled.button`
  font-family: 'Comfortaa';
  background-color: #0C4773;
  color: white;
  font-size: 175%;
  padding: 5px 20px;
  border-radius: 5px;
  margin: 10px 20px;
  cursor: pointer;

  &:hover {
    background-color: #105f9c;
    font-weight: bold;
    cursor: pointer
  }
`;

export const FloatButton = styled.button`
  font-family: 'Comfortaa';
  background-color: transparent;
  color: #0C4773;
  font-size: 3rem;
  padding: 1px 1px;
  border-style: none;
  margin: 10px 5px;
  cursor: pointer;
  position: fixed;
	bottom: 70px;
	right: 0.5em;
`;