import React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import { GeneralButton } from '../Components/Buttons';
import { Helmet } from 'react-helmet';
import SimpleImageSlider from "react-simple-image-slider";
import one from '../Images/HomeSlider/1.jpeg';
import two from '../Images/HomeSlider/2.jpg';
import three from '../Images/HomeSlider/3.jpeg';
import four from '../Images/HomeSlider/4.jpeg';
import five from '../Images/HomeSlider/5.jpeg';
import six from '../Images/HomeSlider/6.jpg';
import seven from '../Images/HomeSlider/7.jpg';
import eight from '../Images/HomeSlider/8.JPG';
import nine from '../Images/HomeSlider/9.jpg';
import ten from '../Images/HomeSlider/10.jpeg';
import eleven from '../Images/HomeSlider/11.jpg';
import twelve from '../Images/HomeSlider/12.jpeg';
import thirteen from '../Images/HomeSlider/13.jpeg';
import fourteen from '../Images/HomeSlider/14.jpeg';
import fifteen from '../Images/HomeSlider/15.jpeg';
import sixteen from '../Images/HomeSlider/16.jpeg';
import seventeen from '../Images/HomeSlider/17.jpeg';
import eighteen from '../Images/HomeSlider/18.jpeg';
import nineteen from '../Images/HomeSlider/19.jpeg';
import twenty from '../Images/HomeSlider/20.jpeg';
import twentyone from '../Images/HomeSlider/21.jpeg';
import twentytwo from '../Images/HomeSlider/22.jpeg';
import '../Styles/Home.css';

const slideImages = [
  { url: one }, { url: two },
  { url: three }, { url: four },
  { url: five }, { url: six },
  { url: seven }, { url: eight },
  { url: nine }, { url: ten },
  { url: eleven }, { url: twelve },
  { url: thirteen }, { url: fourteen },
  { url: fifteen }, { url: sixteen },
  { url: seventeen }, { url: eighteen },
  { url: nineteen }, { url: twenty },
  { url: twentyone }, { url: twentytwo },
];

class Slideshow extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      small: window.matchMedia("all and (max-device-width: 640px)").matches,
      medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
      large: window.matchMedia("all and (min-device-width: 1601px)").matches,
      tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches
    };
  }

  componentDidMount() {
    const handler = e => this.setState({small: e.matches});
    const handler2 = e => this.setState({medium: e.matches});
    const handler3 = e => this.setState({large: e.matches});
    const handler4 = e => this.setState({tablet: e.matches});
    window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
    window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").addEventListener('change', handler2);
    window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
    window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").addEventListener('change', handler4)
  }

  render() {
    var h;
    var w;
    var bullets = true;
    var navMar = 30;
    if(this.state.small) {h = 200; w = 315; bullets = false; navMar = 1;}
    if(this.state.tablet) {h = 350; w = 450;}
    if(this.state.medium) {h = 425; w = 725;}
    if(this.state.large) {h = 650; w = 975;}
    return (
      <div >
        <SimpleImageSlider
          style={{ margin: '0 auto'}}
          width={w}
          height={h}
          slideDuration={.5}
          autoPlayDelay={4}
          autoPlay={true}
          images={slideImages}
          navMargin={navMar}
          showBullets={bullets}
          showNavs={true}
        />
      </div>
    );
  }
}

export const Home = () => {
    let screens = ({ small: window.matchMedia("all and (max-device-width: 640px)").matches });
    return (
      <main>

        <Helmet>
          <title>Alex Bailey Resume | Home</title>
        </Helmet>
        
        <h1 className='home' style={screens.small ? {fontSize: 21} : {}}>Welcome to </h1>
        <h1 className='reduce' style={screens.small ? {fontSize: 24} : {}}>Alex Bailey's Resume Site!</h1>
        <p>This is a site to showcase my programming projects and abilities!</p>

        <Slideshow />

        <div style={{overflow: 'auto', paddingBottom: '15px'}}>
          <Link to="/resume/view_resume">
            <GeneralButton>Resume</GeneralButton>
          </Link>
          <Link to="/resume/about">
            <GeneralButton>About Me</GeneralButton>
          </Link>
          <Link to="/contact">
            <GeneralButton>Contact Me</GeneralButton>
          </Link>
          
        </div>

      </main>
      
    );
  };