import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDGFQnuWUI3PRWfd0QFeF4VVL3tkrfaf-4",
    authDomain: "resume-site-alex-bailey.firebaseapp.com",
    databaseURL: "https://resume-site-alex-bailey-default-rtdb.firebaseio.com",
    projectId: "resume-site-alex-bailey",
    storageBucket: "resume-site-alex-bailey.appspot.com",
    messagingSenderId: "805439019259",
    appId: "1:805439019259:web:c5812fec54cac3911db85e"
};

export default function StartFire() {
    const app = initializeApp(firebaseConfig);
    const database = getDatabase(app);
    return database;
}

export function GetAuth() {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    return auth;
}

export function GetUser() {
    const app = initializeApp(firebaseConfig);
    const auth = getAuth(app);
    let loggedIn = false;
    onAuthStateChanged(auth, (currentUser) => {
        if (currentUser) { loggedIn = true; } 
    });
    return loggedIn;
}


