import React, { Component, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import StartFire from "../Components/FirebaseConfig/Index";
import { ref, get, child } from "firebase/database";
import { UnderConst } from "./UnderConstruction";
import { GeneralButton, GeneralButtonLg } from "../Components/Buttons";
import { Helmet } from 'react-helmet';
import ScrollButton from "../Components/ScrollButton";
import "../Styles/JobDetails.css";
// Store Experience Guide images
import yeti from "../Images/YetiGuide/YetiLogo.jpg";
import yetiBack from "../Images/YetiGuide/1.jpg";
import yetiChs from "../Images/YetiGuide/YetiChs.jpg";
import yetiChsLogo from "../Images/YetiGuide/YetiChsLogo.jpg";
import ocearch from "../Images/YetiGuide/Ocearch.jpg";
// Lowe's Home Improvement Images
import lowes from "../Images/LowesImp/Logo.jpg";
import cashier from "../Images/LowesImp/COM.png";
import nmp from "../Images/LowesImp/LowesNMP.jpg";
import redVest from "../Images/LowesImp/RedVest.jpg";
import redVest2 from "../Images/LowesImp/RedVest2.jpg";
// Lowe's Foods Images
import foods from "../Images/LowesFood/logo.jpg";
import mtp from "../Images/LowesFood/LowesFoodMtP.png";
import chkn from "../Images/LowesFood/chickenkitchen.png";
import chkn2 from "../Images/LowesFood/chickenkitchen2.png";

// Database reference
const db = StartFire();

// Yeti Store Experience Lead
class YetiLead extends Component {
    constructor(props) {
        super(props);
        this.state = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
            large: window.matchMedia("all and (min-device-width: 1601px)").matches,
            Company: '',
            Title: '',
            StartDate: '',
            EndDate: ''
        }
    }

    componentDidMount() {
        const dbRef = ref(db);
        get(child(dbRef, 'Jobs/J3')).then((snapshot)=>{
          this.setState({
              Company: snapshot.val().Company,
              Title: snapshot.val().Title,
              StartDate: snapshot.val().StartDate,
              EndDate: snapshot.val().EndDate
            })
        });

        const handler = e => this.setState({small: e.matches});
        const handler2 = e => this.setState({medium: e.matches});
        const handler3 = e => this.setState({large: e.matches});
        window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
        window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").addEventListener('change', handler2);
        window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
    }

    render() {
        var backH;
        var backSize;
        var indent;
        var padBot;
        var repeat;
        let Chs;
        let Resp;
        let Exp;
        let top = '';
        let Title = () => {
            return (
                <div>
                    <h1 className="yetiTitle">{this.state.Title}</h1>
                    <h2 className="yetiSubtitle">{this.state.Company}</h2>
                </div>
            )
        };

        if (this.state.small) {
            indent = '15px'; 
            backH = 300; 
            backSize = '640px 300px';
            padBot = '100px'; 
            repeat = 'no-repeat';
            top = '60px';

            Title = () => {
                return (
                    <div>
                        <h1 className="yetiTitle" style={{fontSize: '200%', paddingTop: '10em'}}>{this.state.Title}</h1>
                        <h2 className="yetiSubtitle" style={{color: 'black'}}>{this.state.Company}</h2>
                    </div>
                )
            }

            Resp = () => {
                return (
                    <div> 
                        <p id='left' style={{margin: "0 1em"}}>
                            Duties and Responsibilites include the following:
                            <ul>
                                <li>Report to General Store Manager and Assistant Store Managers</li>
                                <li>Support driving store sales and achieving store sales goals</li>
                                <li>Drive excellence in customer service in store by modeling brand service standards</li>
                                <li>Monitor customer feedback and address all customer concerns quickly</li>
                                <li>Ensure store team engages with each customer to create an authentic brand experience</li>
                                <li>Cultivate customer-centric culture by recognizing and rewarding teams’ successes</li>
                                <li>Represent your store as an active brand ambassador within the community, including proactively 
                                    seeking opportunities to engage with customers in community activations
                                </li>
                                <li>Support execution of store marketing events and grow relationships in the community to generate brand awareness and drive traffic</li>
                                <li>Coach store team to YETI standard of customer service and engagement, brand identity, product knowledge, and operations</li>
                                <li>Support execution of training programs</li>
                                <li>Ensure team is educated on product and assortment to enhance selling and customer engagement</li>
                                <li>Provide timely training, coaching, and feedback to enable team success and growth</li>
                                <li>Help ensure team follows all company and store policies and procedures</li>
                                <li>Manage stockroom inventory and supply levels and organization</li>
                                <li>Execute inventory receiving and replenishment processes efficiently and accurately</li>
                                <li>Be a brand and product expert to better serve customers</li>
                                <li>Replenish any store supplies or products throughout the day</li>
                                <li>Ring customers at checkout</li>
                                <li>Any additional duties assigned</li>
                            </ul>
                        </p>

                        <div>
                            <img src={yeti} style={{padding: '5px', height: '85%', width: '85%'}} alt='YetiLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Chs = () => {
                return (
                    <div>
                        <div>
                            <img src={yetiChs} style={{padding: '1px', height: '75%', width: '75%'}} alt='YetiChs.jpg' align='middle' />
                        </div>
                        <div>
                            <img src={yetiChsLogo} style={{padding: '1px', height: '55%', width: '55%'}} alt='YetiChsLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div>
                        <div>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Overall, my experience working for YETI has been great! The work environment created in their store
                                is one of the best out there. In my time working for YETI, I learned a lot about the company and 
                                their products, as well as excellent customer service. YETI also treats their employees very well. 
                                Pictured to the left is a small excursion we took in the Spring of 2022. <a 
                                href='https://www.ocearch.org/about/'>Ocearch</a> has been partnered with YETI for a very long time.
                                So, while their ship was dock in Charleston for a few days, YETI sent a few of us out to meet them. 
                                We got to tour thier vessel as well as converse with the crew. It was very cool to discuss with the 
                                crew not only about their company, but also about YETI products, particularly the plethora of product
                                being utilized by the crew.
                            </p>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Becoming a member of the leadership team has been an excellent experience for me. Despite my previous 
                                leadership expereiences, there was still something new to learn every day. Working for YETI as a Store 
                                Experience Leader has not only been an excellent work opportunity, but also an excellent learning
                                experience that has grown both my customer service and leadership skills.
                            </p>
                            <div>
                                <img src={ocearch} style={{padding: '1px', height: '80%', width: '80%'}} alt='ocearch.jpg' align='middle' />
                            </div>
                            <br />
                            <a href='https://www.yeti.com/'>
                                <GeneralButtonLg>YETI</GeneralButtonLg>
                            </a>
                        </div>
                    </div>
                )
            }
        }

        if (this.state.medium) {
            indent = '30px'; 
            backH = 300; 
            padBot = '75px';
            Title = () => {
                return (
                    <div>
                        <h1 className="yetiTitle">{this.state.Title}</h1>
                        <h2 className="yetiSubtitle" style={{color: 'black'}}>{this.state.Company}</h2>
                    </div>
                )
            };

            Resp = () => {
                return (
                    <div id='wrapper'>
                        <div id='first-single'>
                            <p id='left' style={{margin: "0 1em"}}>
                                Duties and Responsibilites include the following:
                                <ul>
                                    <li>Report to General Store Manager and Assistant Store Managers</li>
                                    <li>Support driving store sales and achieving store sales goals</li>
                                    <li>Drive excellence in customer service in store by modeling brand service standards</li>
                                    <li>Monitor customer feedback and address all customer concerns quickly</li>
                                    <li>Ensure store team engages with each customer to create an authentic brand experience</li>
                                    <li>Cultivate customer-centric culture by recognizing and rewarding teams’ successes</li>
                                    <li>Represent your store as an active brand ambassador within the community, including proactively 
                                        seeking opportunities to engage with customers in community activations
                                    </li>
                                    <li>Support execution of store marketing events and grow relationships in the community to generate brand awareness and drive traffic</li>
                                    <li>Coach store team to YETI standard of customer service and engagement, brand identity, product knowledge, and operations</li>
                                    <li>Support execution of training programs</li>
                                    <li>Ensure team is educated on product and assortment to enhance selling and customer engagement</li>
                                    <li>Provide timely training, coaching, and feedback to enable team success and growth</li>
                                    <li>Help ensure team follows all company and store policies and procedures</li>
                                    <li>Manage stockroom inventory and supply levels and organization</li>
                                    <li>Execute inventory receiving and replenishment processes efficiently and accurately</li>
                                    <li>Be a brand and product expert to better serve customers</li>
                                    <li>Replenish any store supplies or products throughout the day</li>
                                    <li>Ring customers at checkout</li>
                                    <li>Any additional duties assigned</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <img src={yeti} style={{padding: '15px', height: '20%', width: '20%'}} alt='YetiLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Chs = () => {
                return (
                    <div id='wrapper'>
                        <div id='first'>
                            <img src={yetiChs} style={{padding: '1px', height: '69%', width: '69%'}} alt='YetiChs.jpg' align='middle' />
                        </div>
                        <div id='second'>
                            <img src={yetiChsLogo} style={{padding: '1px', height: '53%', width: '53%'}} alt='YetiChsLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div id='wrapper'>
                        <div id='first'>
                            <img src={ocearch} style={{padding: '1px', height: '85%', width: '85%'}} alt='ocearch.jpg' align='middle' />
                        </div>
                        <div id='second'>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Overall, my experience working for YETI has been great! The work environment created in their store
                                is one of the best out there. In my time working for YETI, I learned a lot about the company and 
                                their products, as well as excellent customer service. YETI also treats their employees very well. 
                                Pictured to the left is a small excursion we took in the Spring of 2022. <a 
                                href='https://www.ocearch.org/about/'>Ocearch</a> has been partnered with YETI for a very long time.
                                So, while their ship was dock in Charleston for a few days, YETI sent a few of us out to meet them. 
                                We got to tour thier vessel as well as converse with the crew. It was very cool to discuss with the 
                                crew not only about their company, but also about YETI products, particularly the plethora of product
                                being utilized by the crew.
                            </p>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Becoming a member of the leadership team has been an excellent experience for me. Despite my previous 
                                leadership expereiences, there was still something new to learn every day. Working for YETI as a Store 
                                Experience Leader has not only been an excellent work opportunity, but also an excellent learning
                                experience that has grown both my customer service and leadership skills.
                            </p>
                            <br />
                            <a href='https://www.yeti.com/'>
                                <GeneralButtonLg>YETI</GeneralButtonLg>
                            </a>
                        </div>
                    </div>
                )
            }
        }

        if (this.state.large) {
            indent = '30px'; 
            backH = 400;
            padBot = '100px';

            Resp = () => {
                return (
                    <div id='wrapper'>
                        <div id='first-single'>
                            <p id='left' style={{margin: "0 1em"}}>
                                Duties and Responsibilites include the following:
                                <ul>
                                    <li>Report to General Store Manager and Assistant Store Managers</li>
                                    <li>Support driving store sales and achieving store sales goals</li>
                                    <li>Drive excellence in customer service in store by modeling brand service standards</li>
                                    <li>Monitor customer feedback and address all customer concerns quickly</li>
                                    <li>Ensure store team engages with each customer to create an authentic brand experience</li>
                                    <li>Cultivate customer-centric culture by recognizing and rewarding teams’ successes</li>
                                    <li>Represent your store as an active brand ambassador within the community, including proactively 
                                        seeking opportunities to engage with customers in community activations
                                    </li>
                                    <li>Support execution of store marketing events and grow relationships in the community to generate brand awareness and drive traffic</li>
                                    <li>Coach store team to YETI standard of customer service and engagement, brand identity, product knowledge, and operations</li>
                                    <li>Support execution of training programs</li>
                                    <li>Ensure team is educated on product and assortment to enhance selling and customer engagement</li>
                                    <li>Provide timely training, coaching, and feedback to enable team success and growth</li>
                                    <li>Help ensure team follows all company and store policies and procedures</li>
                                    <li>Manage stockroom inventory and supply levels and organization</li>
                                    <li>Execute inventory receiving and replenishment processes efficiently and accurately</li>
                                    <li>Be a brand and product expert to better serve customers</li>
                                    <li>Replenish any store supplies or products throughout the day</li>
                                    <li>Ring customers at checkout</li>
                                    <li>Any additional duties assigned</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <img src={yeti} style={{padding: '15px', height: '30%', width: '30%'}} alt='YetiLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Chs = () => {
                return (
                    <div id='wrapper'>
                        <div id='first'>
                            <img src={yetiChs} style={{padding: '1px', height: '99%', width: '99%'}} alt='YetiChs.jpg' align='middle' />
                        </div>
                        <div id='second'>
                            <img src={yetiChsLogo} style={{padding: '1px', height: '73%', width: '73%'}} alt='YetiChsLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div id='wrapper'>
                        <div id='first'>
                            <img src={ocearch} style={{padding: '1px', height: '65%', width: '65%'}} alt='ocearch.jpg' align='middle' />
                        </div>
                        <div id='second'>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Overall, my experience working for YETI has been great! The work environment created in their store
                                is one of the best out there. In my time working for YETI, I learned a lot about the company and 
                                their products, as well as excellent customer service. YETI also treats their employees very well. 
                                Pictured to the left is a small excursion we took in the Spring of 2022. <a 
                                href='https://www.ocearch.org/about/'>Ocearch</a> has been partnered with YETI for a very long time.
                                So, while their ship was dock in Charleston for a few days, YETI sent a few of us out to meet them. 
                                We got to tour thier vessel as well as converse with the crew. It was very cool to discuss with the 
                                crew not only about their company, but also about YETI products, particularly the plethora of product
                                being utilized by the crew.
                            </p>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Becoming a member of the leadership team has been an excellent experience for me. Despite my previous 
                                leadership expereiences, there was still something new to learn every day. Working for YETI as a Store 
                                Experience Leader has not only been an excellent work opportunity, but also an excellent learning
                                experience that has grown both my customer service and leadership skills.
                            </p>
                            <br />
                            <a href='https://www.yeti.com/'>
                                <GeneralButtonLg>YETI</GeneralButtonLg>
                            </a>
                        </div>
                    </div>
                )
            }
        }

        return (
            <main style={{ marginTop: top, backgroundImage: `url(${yetiBack})`, backgroundRepeat: repeat, backgroundPosition: 'center', 
                backgroundSize: backSize, height: backH, width: 'auto'}}>
                <div id='top' style={{overflow: 'auto', paddingBottom: padBot}}>
                    <Helmet>
                        <title>Alex Bailey Resume | {this.state.Title}</title>
                    </Helmet>
                    <Title />

                    <hr style={{margin: '0 1em'}} />

                    <br />
                    <h3>Job Description</h3>
                    <p id='left' style={{textIndent: indent}}>
                        As a Store Experience Leader, my duties include informing the customer about YETI products, ringing
                        customers at checkout, and general upkeep duties such as restocking and general housekeeping. 
                        Additionally, I am responsible for leading our team of associates, managing/deligating responsibilites, 
                        and opening/closing the store. As described on YETI's job listing, "You will provide leadership support 
                        and direction to the store team and successfully execute company strategies. You will help hire, coach, 
                        and motivate a team of brand advocates who in turn will provide a "wow" factor level of customer 
                        experience. You drive store profitability by upholding store operational and visual merchandising 
                        standards and protecting company assets. You have a customer first mindset, and thrive in fast-paced, 
                        ever-changing entrepreneurial environments."
                    </p>

                    <br />
                    <br />
                    <br />

                    <h3>Responsibilites</h3>
                    <Resp />

                    <br />
                    <br />
                    <br />

                    <h3>YETI Charleston</h3>
                    <p id="left" style={{textIndent: indent}}>
                        YETI Charleston is one of only ten YETI Retail Stores across the United States. As stated on YETI's website,
                        "In June 2019, we opened our second-ever store in Charleston, South Carolina. The city choice was a no-brainer. 
                        Charleston appreciates the same simple pleasures YETI exists for: fresh catch for food, long days on the water, 
                        and heading in only after the sun goes down. To pay respects to the city's true southern hospitality, we created 
                        an indoor haven where the community can feel right at home — an easy escape from the city bustle." The 
                        5000-square-foot store features a stage for live music, a large projection screen displaying Yeti-produced films,
                        and every Yeti product the brand has to offer!
                    </p>
                    <Chs />

                    <br />
                    <br />
                    <br />

                    <h3>Experiences</h3>
                    <Exp />

                    <p>Images Courtesy of <a href="https://www.yeti.com/">YETI</a></p>
                    <Link to='/resume/view_resume'>
                        <GeneralButton>Back</GeneralButton>
                    </Link>
                    <Link to='/home'>
                        <GeneralButton>Home</GeneralButton>
                    </Link>

                    <ScrollButton />

                </div>
            </main>
        )
    }
}

// Yeti Store Experience Guide 
class YetiGuide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
            large: window.matchMedia("all and (min-device-width: 1601px)").matches,
            Company: '',
            Title: '',
            StartDate: '',
            EndDate: ''
        }
    }

    componentDidMount() {
        const dbRef = ref(db);
        get(child(dbRef, 'Jobs/J2')).then((snapshot)=>{
          this.setState({
              Company: snapshot.val().Company,
              Title: snapshot.val().Title,
              StartDate: snapshot.val().StartDate,
              EndDate: snapshot.val().EndDate
            })
        });

        const handler = e => this.setState({small: e.matches});
        const handler2 = e => this.setState({medium: e.matches});
        const handler3 = e => this.setState({large: e.matches});
        window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
        window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").addEventListener('change', handler2);
        window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
    }

    render() {
        var backH;
        var backSize;
        var indent;
        var padBot;
        var repeat;
        let Chs;
        let Resp;
        let Exp;
        let top = '';
        let Title = () => {
            return (
                <div>
                    <h1 className="yetiTitle">{this.state.Title}</h1>
                    <h2 className="yetiSubtitle">{this.state.Company}</h2>
                </div>
            )
        };

        if (this.state.small) {
            indent = '15px'; 
            backH = 300; 
            backSize = '640px 300px';
            padBot = '100px'; 
            repeat = 'no-repeat';
            top = '60px';

            Title = () => {
                return (
                    <div>
                        <h1 className="yetiTitle" style={{fontSize: '200%', paddingTop: '10em'}}>{this.state.Title}</h1>
                        <h2 className="yetiSubtitle" style={{color: 'black'}}>{this.state.Company}</h2>
                    </div>
                )
            }

            Resp = () => {
                return (
                    <div> 
                        <p id='left' style={{margin: "0 1em"}}>
                            Duties and Responsibilites include the following:
                            <ul>
                                <li>Report to Store Experience Leader</li>
                                <li>
                                    Represent the YETI brand by authentically engaging each customer and their individual 
                                    needs
                                </li>
                                <li>Become a brand and product expert to better serve customers</li>
                                <li>Replenish any store supplies or products throughout the day</li>
                                <li>Ring customers at checkout</li>
                                <li>Any additional duties assigned by leadership</li>
                            </ul>
                        </p>

                        <div>
                            <img src={yeti} style={{padding: '5px', height: '85%', width: '85%'}} alt='YetiLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Chs = () => {
                return (
                    <div>
                        <div>
                            <img src={yetiChs} style={{padding: '1px', height: '75%', width: '75%'}} alt='YetiChs.jpg' align='middle' />
                        </div>
                        <div>
                            <img src={yetiChsLogo} style={{padding: '1px', height: '55%', width: '55%'}} alt='YetiChsLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div>
                        <div>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Overall, my experience working for YETI has been great! The work environment created in their store
                                is one of the best out there. In my time working for YETI, I learned a lot about the company and 
                                their products, as well as excellent customer service. YETI also treats their employees very well. 
                                Pictured to the left is a small excursion we took in the Spring of 2022. <a 
                                href='https://www.ocearch.org/about/'>Ocearch</a> has been partnered with YETI for a very long time.
                                So, while their ship was dock in Charleston for a few days, YETI sent a few of us out to meet them. 
                                We got to tour thier vessel as well as converse with the crew. It was very cool to discuss with the 
                                crew not only about their company, but also about YETI products, particularly the plethora of product
                                being utilized by the crew.
                            </p>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Working for YETI has not only been an excellent work opportunity, but also an excellent learning
                                experience.
                            </p>
                            <div>
                                <img src={ocearch} style={{padding: '1px', height: '80%', width: '80%'}} alt='ocearch.jpg' align='middle' />
                            </div>
                            <br />
                            <a href='https://www.yeti.com/'>
                                <GeneralButtonLg>YETI</GeneralButtonLg>
                            </a>
                        </div>
                    </div>
                )
            }
        }

        if (this.state.medium) {
            indent = '30px'; 
            backH = 300; 
            padBot = '75px';
            Title = () => {
                return (
                    <div>
                        <h1 className="yetiTitle">{this.state.Title}</h1>
                        <h2 className="yetiSubtitle" style={{color: 'black'}}>{this.state.Company}</h2>
                    </div>
                )
            };

            Resp = () => {
                return (
                    <div id='wrapper'>
                        <div id='first-single'>
                            <p id='left' style={{margin: "0 1em"}}>
                                Duties and Responsibilites include the following:
                                <ul>
                                    <li>Report to Store Experience Leader</li>
                                    <li>
                                        Represent the YETI brand by authentically engaging each customer and their individual 
                                        needs
                                    </li>
                                    <li>Become a brand and product expert to better serve customers</li>
                                    <li>Replenish any store supplies or products throughout the day</li>
                                    <li>Ring customers at checkout</li>
                                    <li>Any additional duties assigned by leadership</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <img src={yeti} style={{padding: '15px', height: '20%', width: '20%'}} alt='YetiLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Chs = () => {
                return (
                    <div id='wrapper'>
                        <div id='first'>
                            <img src={yetiChs} style={{padding: '1px', height: '69%', width: '69%'}} alt='YetiChs.jpg' align='middle' />
                        </div>
                        <div id='second'>
                            <img src={yetiChsLogo} style={{padding: '1px', height: '53%', width: '53%'}} alt='YetiChsLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div id='wrapper'>
                        <div id='first'>
                            <img src={ocearch} style={{padding: '1px', height: '85%', width: '85%'}} alt='ocearch.jpg' align='middle' />
                        </div>
                        <div id='second'>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Overall, my experience working for YETI has been great! The work environment created in their store
                                is one of the best out there. In my time working for YETI, I learned a lot about the company and 
                                their products, as well as excellent customer service. YETI also treats their employees very well. 
                                Pictured to the left is a small excursion we took in the Spring of 2022. <a 
                                href='https://www.ocearch.org/about/'>Ocearch</a> has been partnered with YETI for a very long time.
                                So, while their ship was dock in Charleston for a few days, YETI sent a few of us out to meet them. 
                                We got to tour thier vessel as well as converse with the crew. It was very cool to discuss with the 
                                crew not only about their company, but also about YETI products, particularly the plethora of product
                                being utilized by the crew.
                            </p>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Working for YETI has not only been an excellent work opportunity, but also an excellent learning
                                experience.
                            </p>
                            <br />
                            <a href='https://www.yeti.com/'>
                                <GeneralButtonLg>YETI</GeneralButtonLg>
                            </a>
                        </div>
                    </div>
                )
            }
        }

        if (this.state.large) {
            indent = '30px'; 
            backH = 400;
            padBot = '100px';

            Resp = () => {
                return (
                    <div id='wrapper'>
                        <div id='first-single'>
                            <p id='left' style={{margin: "0 1em"}}>
                                Duties and Responsibilites include the following:
                                <ul>
                                    <li>Report to Store Experience Leader</li>
                                    <li>
                                        Represent the YETI brand by authentically engaging each customer and their individual 
                                        needs
                                    </li>
                                    <li>Become a brand and product expert to better serve customers</li>
                                    <li>Replenish any store supplies or products throughout the day</li>
                                    <li>Ring customers at checkout</li>
                                    <li>Any additional duties assigned by leadership</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <img src={yeti} style={{padding: '15px', height: '30%', width: '30%'}} alt='YetiLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Chs = () => {
                return (
                    <div id='wrapper'>
                        <div id='first'>
                            <img src={yetiChs} style={{padding: '1px', height: '99%', width: '99%'}} alt='YetiChs.jpg' align='middle' />
                        </div>
                        <div id='second'>
                            <img src={yetiChsLogo} style={{padding: '1px', height: '73%', width: '73%'}} alt='YetiChsLogo.jpg' align='middle' />
                        </div>
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div id='wrapper'>
                        <div id='first'>
                            <img src={ocearch} style={{padding: '1px', height: '65%', width: '65%'}} alt='ocearch.jpg' align='middle' />
                        </div>
                        <div id='second'>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Overall, my experience working for YETI has been great! The work environment created in their store
                                is one of the best out there. In my time working for YETI, I learned a lot about the company and 
                                their products, as well as excellent customer service. YETI also treats their employees very well. 
                                Pictured to the left is a small excursion we took in the Spring of 2022. <a 
                                href='https://www.ocearch.org/about/'>Ocearch</a> has been partnered with YETI for a very long time.
                                So, while their ship was dock in Charleston for a few days, YETI sent a few of us out to meet them. 
                                We got to tour thier vessel as well as converse with the crew. It was very cool to discuss with the 
                                crew not only about their company, but also about YETI products, particularly the plethora of product
                                being utilized by the crew.
                            </p>
                            <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                Working for YETI has not only been an excellent work opportunity, but also an excellent learning
                                experience.
                            </p>
                            <br />
                            <a href='https://www.yeti.com/'>
                                <GeneralButtonLg>YETI</GeneralButtonLg>
                            </a>
                        </div>
                    </div>
                )
            }
        }

        return (
            <main style={{ marginTop: top, backgroundImage: `url(${yetiBack})`, backgroundRepeat: repeat, backgroundPosition: 'center', 
                backgroundSize: backSize, height: backH, width: 'auto'}}>
                <div id='top' style={{overflow: 'auto', paddingBottom: padBot}}>
                    <Helmet>
                        <title>Alex Bailey Resume | {this.state.Title}</title>
                    </Helmet>
                    <Title />

                    <hr style={{margin: '0 1em'}} />

                    <br />
                    <h3>Job Description</h3>
                    <p id='left' style={{textIndent: indent}}>
                        As a Store Experience Guide, my duties include informing the customer about YETI products, ringing
                        customers at checkout, and general upkeep duties such as restocking and general housekeeping. As 
                        described on YETI's job listing, "You have a customer first mindset, and thrive in fast-paced, 
                        ever-changing entrepreneurial environments. You will be promoting and supporting a culture that 
                        delivers a "wow" factor level of customer experience through genuine and engaging interactions 
                        and maintaining an extraordinary level of visual excellence. You represent the YETI brand by 
                        upholding our core values and always going the extra mile for customers and teammates. You are 
                        passionate about helping customers find the right YETI product that fits their pursuits."
                    </p>

                    <br />
                    <br />
                    <br />

                    <h3>Responsibilites</h3>
                    <Resp />

                    <br />
                    <br />
                    <br />

                    <h3>YETI Charleston</h3>
                    <p id="left" style={{textIndent: indent}}>
                        YETI Charleston is one of only ten YETI Retail Stores across the United States. As stated on YETI's website,
                        "In June 2019, we opened our second-ever store in Charleston, South Carolina. The city choice was a no-brainer. 
                        Charleston appreciates the same simple pleasures YETI exists for: fresh catch for food, long days on the water, 
                        and heading in only after the sun goes down. To pay respects to the city's true southern hospitality, we created 
                        an indoor haven where the community can feel right at home — an easy escape from the city bustle." The 
                        5000-square-foot store features a stage for live music, a large projection screen displaying Yeti-produced films,
                        and every Yeti product the brand has to offer!
                    </p>
                    <Chs />

                    <br />
                    <br />
                    <br />

                    <h3>Experiences</h3>
                    <Exp />

                    <p>Images Courtesy of <a href="https://www.yeti.com/">YETI</a></p>
                    <Link to='/resume/view_resume'>
                        <GeneralButton>Back</GeneralButton>
                    </Link>
                    <Link to='/home'>
                        <GeneralButton>Home</GeneralButton>
                    </Link>

                    <ScrollButton />

                </div>
            </main>
        )
    }
}

// Lowe's Home Improvement
class LowesHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px").matches,
            large: window.matchMedia("all and (min-device-width: 1601px)").matches,
            Company: '',
            Title: '',
            StartDate: '',
            EndDate: ''
        }
    }

    componentDidMount() {
        const dbRef = ref(db);
        get(child(dbRef, 'Jobs/J1')).then((snapshot)=>{
          this.setState({
              Company: snapshot.val().Company,
              Title: snapshot.val().Title,
              StartDate: snapshot.val().StartDate,
              EndDate: snapshot.val().EndDate
            })
        });

        const handler = e => this.setState({small: e.matches});
        const handler2 = e => this.setState({medium: e.matches});
        const handler3 = e => this.setState({large: e.matches});
        window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
        window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px").addEventListener('change', handler2);
        window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
    }

    render() {
        var backH;
        var backSize;
        var indent;
        var padBot;
        var mar;
        var h = 300;
        var w = 300;
        let top = '';
        let Exp;
        let Resp;
        let Title;
        
        if (this.state.small) {
            indent = '15px'; 
            backH = 150; 
            backSize = '320px 150px';
            padBot = '100px';
            mar = '0 1em';
            top = '60px'

            Title = () => {
                return (
                    <div>
                        <h1 className="LoImpTitle" style={{fontSize: '175%', paddingTop: '6em'}}>{this.state.Title}</h1>
                        <h2 className="LoImpSubtitle" style={{fontSize: '150%'}}>{this.state.Company}</h2>
                    </div>
                )
            }

            Resp = () => {
                return (
                    <div> 
                        <p id='left' style={{margin: "0 1em"}}>
                            Duties and Responsibilites include the following:
                            <ul>
                                <li>Report to Head Cashier</li>
                                <li>Ring customers at checkout</li>
                                <li>Prevent shoplifting by providing excellent customer service</li>
                                <li>Replenish any store supplies throughout the day</li>
                                <li>Keep workstation clean & organized</li>
                                <li>Any additional duties assigned by leadership</li>
                            </ul>
                        </p>
                        <a href="https://www.facebook.com/mtpleasant3317/">
                            <img src={nmp} style={{padding: '5px', height: h, width: w}} alt='LowesNMP.jpg' align='middle'/>
                        </a>
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div>
                        <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                            During my time working for Lowe's Home Improvement, I learned a great number of
                            of things. I greatly improved my customer service skills. In order to deter 
                            dishonest customers from shoplifting, associates are trained to deliver quality
                            service to every customer. Dishonest customers are less likely to shoplift if 
                            a store associate is nearby. Delivering quality customer service to a potentially
                            dishonest customer is a way to deter theft without making the customer feel like
                            they are being attacked. In addition to that, I also learned how to deal with rude
                            or impatient customers in a professional manor as well as how to make a successful
                            sales pitch to a customer inquiring about a product.
                        </p>

                        <img src={redVest} style={{padding: '5px', height: '85%', width: '85%'}} alt='RedVest.jpg' align='middle' />

                        <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                            Another great takeaway from my time working at Lowe's is I have a better understanding
                            of how a retail store operates. I was able to expand my knowledge to a few different 
                            departments, rather than just the one I was hired into. For example, I learned a lot
                            about how Lowe's restocks their shelves and rules for pricing & discounts. I also 
                            learned a good bit about what powertools are better for certain jobs as well as which
                            ones are more at risk for theft. Another department I learned about was the Lumber
                            Department. I learned a lot about which wood is better for decking, whether composite
                            decking is a better option or not, and many other things!
                        </p>

                        <img src={redVest2} style={{padding: '5px', height: '85%', width: '85%'}} alt='RedVest2.jpg' align='middle' />
                        <img src={cashier} style={{padding: '5px', height: '85%', width: '85%'}} alt='COM.jpg' align='middle' />

                        <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                            Overall, working for Lowe's Home Improvement was a great experience for me. I met a lot
                            of great people; coworkers and customers! I learned a ton in my time working there and
                            even recived a pay raise, three Service Star Awards, and Cashier of the month three times
                            as reward for my detication to delivering quality customer service!
                        </p>

                        <a href='https://www.lowes.com/'>
                                <GeneralButtonLg>Lowe's</GeneralButtonLg>
                        </a>
                    </div>
                )
            }
        }

        if (this.state.medium) {
            indent = '30px'; 
            backH = 300; 
            backSize = '600px 300px';
            padBot = '100px';
            mar = '0 3em'; 

            Title = () => {
                return (
                    <div>
                        <h1 className="LoImpTitle">{this.state.Title}</h1>
                        <h2 className="LoImpSubtitle">{this.state.Company}</h2>
                    </div>
                )
            };

            Resp = () => {
                return (
                    <div id='wrapper'> 
                        <div id='first-single'>
                        <h3>Responsibilites</h3>
                            <p id='left' style={{margin: "0 1em"}}>
                                Duties and Responsibilites include the following:
                                <ul>
                                    <li>Report to Head Cashier</li>
                                    <li>Ring customers at checkout</li>
                                    <li>Prevent shoplifting by providing excellent customer service</li>
                                    <li>Replenish any store supplies throughout the day</li>
                                    <li>Keep workstation clean & organized</li>
                                    <li>Any additional duties assigned by leadership</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <a href="https://www.facebook.com/mtpleasant3317/">
                                <img src={nmp} style={{padding: '5px', height: h, width: w}} alt='LowesNMP.jpg' align='middle'/>
                            </a>
                        </div>   
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div>
                        <div id='wrapper'>
                            <h3>Experiences</h3>
                            <div id='first'>
                                <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                    During my time working for Lowe's Home Improvement, I learned a great number of
                                    of things. I greatly improved my customer service skills. In order to deter 
                                    dishonest customers from shoplifting, associates are trained to deliver quality
                                    service to every customer. Dishonest customers are less likely to shoplift if 
                                    a store associate is nearby. Delivering quality customer service to a potentially
                                    dishonest customer is a way to deter theft without making the customer feel like
                                    they are being attacked. In addition to that, I also learned how to deal with rude
                                    or impatient customers in a professional manor as well as how to make a successful
                                    sales pitch to a customer inquiring about a product.
                                </p>
                            </div>
                            
                            <div id='second'>
                                <img src={redVest} style={{padding: '5px', height: '25%', width: '25%'}} alt='RedVest.jpg' align='middle' />
                            </div>
                        </div>
                        
                        <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                            Another great takeaway from my time working at Lowe's is I have a better understanding
                            of how a retail store operates. I was able to expand my knowledge to a few different 
                            departments, rather than just the one I was hired into. For example, I learned a lot
                            about how Lowe's restocks their shelves and rules for pricing & discounts. I also 
                            learned a good bit about what powertools are better for certain jobs as well as which
                            ones are more at risk for theft. Another department I learned about was the Lumber
                            Department. I learned a lot about which wood is better for decking, whether composite
                            decking is a better option or not, and many other things!
                        </p>
                        

                        <div id='wrapper'>
                            <div id='first'>
                                <img src={redVest2} style={{padding: '5px', height: '25%', width: '25%'}} alt='RedVest2.jpg' align='middle' />
                            </div>
                            <div id='second'>
                                <img src={cashier} style={{padding: '5px', height: '35%', width: '35%'}} alt='COM.jpg' align='middle' />
                            </div>
                        </div>
                        

                        <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                            Overall, working for Lowe's Home Improvement was a great experience for me. I met a lot
                            of great people; coworkers and customers! I learned a ton in my time working there and
                            even recived a pay raise, three Service Star Awards, and Cashier of the month three times
                            as reward for my detication to delivering quality customer service!
                        </p>

                        <a href='https://www.lowes.com/'>
                                <GeneralButtonLg>Lowe's</GeneralButtonLg>
                        </a>
                    </div>
                )
            }
        }

        if (this.state.large) {
            indent = '30px'; 
            backH = 350; 
            backSize = '750px 350px';
            padBot = '100px';
            mar = '0 10em'; 

            Title = () => {
                return (
                    <div>
                        <h1 className="LoImpTitle" style={{paddingTop: '10em'}}>{this.state.Title}</h1>
                        <h2 className="LoImpSubtitle">{this.state.Company}</h2>
                    </div>
                )
            };

            Resp = () => {
                return (
                    <div id='wrapper'> 
                        <div id='first-single'>
                        <h3>Responsibilites</h3>
                            <p id='left' style={{margin: "0 1em"}}>
                                Duties and Responsibilites include the following:
                                <ul>
                                    <li>Report to Head Cashier</li>
                                    <li>Ring customers at checkout</li>
                                    <li>Prevent shoplifting by providing excellent customer service</li>
                                    <li>Replenish any store supplies throughout the day</li>
                                    <li>Keep workstation clean & organized</li>
                                    <li>Any additional duties assigned by leadership</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <a href="https://www.facebook.com/mtpleasant3317/">
                                <img src={nmp} style={{padding: '5px', height: h, width: w}} alt='LowesNMP.jpg' align='middle'/>
                            </a>
                        </div>   
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div>
                        <div id='wrapper'>
                            <div id='first'>
                                <h3>Experiences</h3>

                                <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                                    During my time working for Lowe's Home Improvement, I learned a great number of
                                    of things. I greatly improved my customer service skills. In order to deter 
                                    dishonest customers from shoplifting, associates are trained to deliver quality
                                    service to every customer. Dishonest customers are less likely to shoplift if 
                                    a store associate is nearby. Delivering quality customer service to a potentially
                                    dishonest customer is a way to deter theft without making the customer feel like
                                    they are being attacked. In addition to that, I also learned how to deal with rude
                                    or impatient customers in a professional manor as well as how to make a successful
                                    sales pitch to a customer inquiring about a product.
                                </p>
                            </div>
                            
                            <div id='second'>
                                <img src={redVest} style={{padding: '5px', height: '25%', width: '25%'}} alt='RedVest.jpg' align='middle' />
                            </div>
                        </div>
                        
                        <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                            Another great takeaway from my time working at Lowe's is I have a better understanding
                            of how a retail store operates. I was able to expand my knowledge to a few different 
                            departments, rather than just the one I was hired into. For example, I learned a lot
                            about how Lowe's restocks their shelves and rules for pricing & discounts. I also 
                            learned a good bit about what powertools are better for certain jobs as well as which
                            ones are more at risk for theft. Another department I learned about was the Lumber
                            Department. I learned a lot about which wood is better for decking, whether composite
                            decking is a better option or not, and many other things!
                        </p>
                        

                        <div id='wrapper'>
                            <div id='first'>
                                <img src={redVest2} style={{padding: '5px', height: '25%', width: '25%'}} alt='RedVest2.jpg' align='middle' />
                            </div>
                            <div id='second'>
                                <img src={cashier} style={{padding: '5px', height: '35%', width: '35%'}} alt='COM.jpg' align='middle' />
                            </div>
                        </div>
                        

                        <p id='left' style={{margin: "0 1em", textIndent: indent}}>
                            Overall, working for Lowe's Home Improvement was a great experience for me. I met a lot
                            of great people; coworkers and customers! I learned a ton in my time working there and
                            even recived a pay raise, three Service Star Awards, and Cashier of the month three times
                            as reward for my detication to delivering quality customer service!
                        </p>

                        <a href='https://www.lowes.com/'>
                                <GeneralButtonLg>Lowe's</GeneralButtonLg>
                        </a>
                    </div>
                )
            }
        }

        return (
            <main style={{ marginTop: top, backgroundImage: `url(${lowes})`, backgroundPosition: 'center', 
                backgroundRepeat: 'no-repeat', backgroundSize: backSize, height: backH, width: 'auto'}}>
                
                <div id='top' style={{overflow: 'auto', paddingBottom: padBot, margin: mar}}>
                    <Helmet>
                        <title>Alex Bailey Resume | {this.state.Title}</title>
                    </Helmet>
                    <Title />

                    <hr style={{margin: mar}} />

                    <br />
                    <br />

                    <h3>Job Description</h3>
                    <p id='left' style={{textIndent: indent}}>
                        As a Front End Associate, my is to provide quality customer service, ring customers at
                        checkout, and other general retail tasks such as restocking supplies as well as keeping
                        my area of the store clean. My primary position, in general terms, can be described as a
                        cashier. However, I also had the training to work the customer service desk as well as 
                        direct customers to other departments and answer any general questions about the locations
                        of items or questions about their projects.
                    </p>

                    <h3>Lowe's North Mount Pleasant</h3>
                    <p id='left' style={{textIndent: indent}}>
                        Lowe's of North Mount Pleasant (#3317) opened in <a 
                            href='https://abcnews4.com/news/local/new-lowes-to-open-in-mt-pleasant-next-month'>
                                January 2018</a> and contains over 37,000 items on store shelves across its 103,000 
                        sqaure feet of floor space. "Your local Lowe's in Mount Pleasant, SC is your true one-stop shop 
                        for home improvement. We're always community first, and that's why we truly enjoy helping people 
                        love where they live. Whether you're looking for the best deals on major appliances, plants, 
                        paint or helpful tools, you can find them and more here at the North Mount Pleasant Lowe's. And 
                        if you need help, our expert red vest staff members are always close by." 
                        -<a 
                            href='https://www.lowes.com/store/SC-Mount-Pleasant/3317#storeDescription'>Lowes.com
                        </a>
                    </p>

                    <br />
                    <br />

                    <Resp />

                    <br />
                    <br />

                    <Exp />
                    
                    <p>
                        Logos courtesy of <a href="https://www.lowes.com/l/about/logos">Lowes.com</a>
                    </p>
                    <Link to='/resume/view_resume'>
                        <GeneralButton>Back</GeneralButton>
                    </Link>
                    <Link to='/home'>
                        <GeneralButton>Home</GeneralButton>
                    </Link>
                    
                    <ScrollButton />

                </div>
            </main>
        )
    }
}

// Lowe's Foods
class ChickenKitchen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Company: '',
            Title: '',
            StartDate: '',
            EndDate: '',
            small: window.matchMedia("screen and (max-device-width: 640px)").matches,
            medium: window.matchMedia("screen and (min-device-width: 641px) and (max-device-width: 1600px").matches,
            large: window.matchMedia("screen and (min-device-width: 1601px)").matches
        }
    }

    componentDidMount() {
        const dbRef = ref(db);
        get(child(dbRef, 'Jobs/J0')).then((snapshot)=>{
          this.setState({
              Company: snapshot.val().Company,
              Title: snapshot.val().Title,
              StartDate: snapshot.val().StartDate,
              EndDate: snapshot.val().EndDate
            })
        });

        const handler = e => this.setState({small: e.matches});
        const handler2 = e => this.setState({medium: e.matches});
        const handler3 = e => this.setState({large: e.matches});
        window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
        window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px").addEventListener('change', handler2);
        window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
    }

    render() {
        var backH;
        var backSize;
        var indent;
        var padBot;
        var mar;
        var h = 300;
        var w = 300;
        let top = '';
        let Exp;
        let Resp;
        let Title;
        
        if (this.state.small) {
            indent = '15px'; 
            backH = 150; 
            backSize = '320px 150px';
            padBot = '100px';
            mar = '0 1em';
            h = 150;
            top = '60px';

            Title = () => {
                return (
                    <div>
                        <h1 className="LoFoTitle" style={{fontSize: '175%', paddingTop: '6em'}}>{this.state.Title}</h1>
                        <h2 className="LoFoSubtitle" style={{fontSize: '150%'}}>{this.state.Company}</h2>
                    </div>
                )
            }

            Resp = () => {
                return (
                    <div> 
                        <p id='left' style={{margin: mar}}>
                            Duties and Responsibilites include the following:
                            <ul>
                                <li>Report to Shift Leader</li>
                                <li>Take customer orders</li>
                                <li>Ensure various entrees and sides are fully cooked and available for customers to order</li>
                                <li>Replenish any store supplies throughout the day</li>
                                <li>Clean all equptment and ensure all workstations are sanitary and organized</li>
                                <li>Any additional duties assigned by leadership</li>
                            </ul>
                        </p>
                        <a href="https://mc-2c2007e7-68ca-4429-8b17-5560-cdn-endpoint.azureedge.net/-/media/alex-lee/lf/logos/chickenkitchen_logo_cream.png?rev=fb4e23d25df24a258ef082b2dea7e6fe&h=281&w=675&la=en&hash=65401DA3C2129577AFAB47126C1994C5">
                            <img src={chkn} style={{padding: '5px', height: h, width: w}} alt='chickenkitchen.png' align='middle'/>
                        </a>
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div>
                        <p id='left' style={{margin: mar, textIndent: indent}}>
                            During my time working at Lowes Foods, I learned a lot about customer service and cooking in a
                            commercial kitchen. Because this was my first <font style={{fontStyle: 'italic'}}>real</font> job,
                            most of the things I was experiencing was new. However, I caught on fairly quick and became very 
                            comfortable with my required tasks. I also made some good friends from this job. We worked well 
                            together as a team at work and communcated as friends outside of work. Due to my status as full 
                            time student at the College of Charleston, I decided to leave this job when the 2019 Fall Semester 
                            began in August. Overall, working at Lowes Foods was a good experience, but it was a bit too much
                            to continue to do during the school year. I'd say, it was a pretty good first job for me!
                        </p>

                        <a href="https://abcnews4.com/news/local/lowes-foods-celebrate-grand-opening">
                            <img src={mtp} style={{padding: '5px', height: '85%', width: '85%'}} alt='LowesFoodMtP.jpg' align='middle' />
                        </a>
                        <a href="https://wildfireideas.com/work/lowes-foods-chicken-kitchen/">
                            <img src={chkn2} style={{padding: '5px', height: '85%', width: '85%'}} alt='chickenkitchen2.jpg' align='middle' />
                        </a>
                        
                        <a href='https://www.lowesfoods.com/'>
                                <GeneralButtonLg>Lowes Foods</GeneralButtonLg>
                        </a>
                    </div>
                )
            }
        }

        if (this.state.medium) {
            indent = '30px'; 
            backH = 150; 
            backSize = '320px 150px';
            padBot = '100px';
            mar = '0 7em';
            w = 450;
            h = 200;

            Title = () => {
                return (
                    <div>
                        <h1 className="LoFoTitle" style={{paddingTop: '4em'}}>{this.state.Title}</h1>
                        <h2 className="LoFoSubtitle">{this.state.Company}</h2>
                    </div>
                )
            }

            Resp = () => {
                return (
                    <div id='wrapper'> 
                        <div id='first-single'>
                            <p id='left' style={{margin: mar}}>
                                Duties and Responsibilites include the following:
                                <ul>
                                    <li>Report to Shift Leader</li>
                                    <li>Take customer orders</li>
                                    <li>Ensure various entrees and sides are fully cooked and available for customers to order</li>
                                    <li>Replenish any store supplies throughout the day</li>
                                    <li>Clean all equptment and ensure all workstations are sanitary and organized</li>
                                    <li>Any additional duties assigned by leadership</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <a href="https://mc-2c2007e7-68ca-4429-8b17-5560-cdn-endpoint.azureedge.net/-/media/alex-lee/lf/logos/chickenkitchen_logo_cream.png?rev=fb4e23d25df24a258ef082b2dea7e6fe&h=281&w=675&la=en&hash=65401DA3C2129577AFAB47126C1994C5">
                                <img src={chkn} style={{padding: '5px', height: h, width: w}} alt='chickenkitchen.png' align='middle'/>
                            </a>
                        </div>
                        
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div>
                        <div>
                            <p id='left' style={{margin: mar, textIndent: indent}}>
                                During my time working at Lowes Foods, I learned a lot about customer service and cooking in a
                                commercial kitchen. Because this was my first <font style={{fontStyle: 'italic'}}>real</font> job,
                                most of the things I was experiencing was new. However, I caught on fairly quick and became very 
                                comfortable with my required tasks. I also made some good friends from this job. We worked well 
                                together as a team at work and communcated as friends outside of work. Due to my status as full 
                                time student at the College of Charleston, I decided to leave this job when the 2019 Fall Semester 
                                began in August. Overall, working at Lowes Foods was a good experience, but it was a bit too much
                                to continue to do during the school year. I'd say, it was a pretty good first job for me!
                            </p>
                        </div>

                        <div id='wrapper'>
                            <div id='first'>
                                <a href="https://abcnews4.com/news/local/lowes-foods-celebrate-grand-opening">
                                    <img src={mtp} style={{padding: '5px', height: '70%', width: '70%'}} alt='LowesFoodMtP.jpg' align='middle' />
                                </a>
                            </div>
                            <div id='second'>
                                <a href="https://wildfireideas.com/work/lowes-foods-chicken-kitchen/">
                                    <img src={chkn2} style={{padding: '5px', height: '70%', width: '70%'}} alt='chickenkitchen2.jpg' align='middle' />
                                </a>
                            </div>
                        </div>   
                             
                        <a href='https://www.lowesfoods.com/'>
                                <GeneralButtonLg>Lowes Foods</GeneralButtonLg>
                        </a>
                    </div>
                )
            }
        }

        if (this.state.large) {
            indent = '30px'; 
            backH = 150; 
            backSize = '320px 150px';
            padBot = '100px';
            mar = '0 5em';
            w = 450;
            h = 200;

            Title = () => {
                return (
                    <div>
                        <h1 className="LoFoTitle" style={{paddingTop: '4em'}}>{this.state.Title}</h1>
                        <h2 className="LoFoSubtitle">{this.state.Company}</h2>
                    </div>
                )
            }

            Resp = () => {
                return (
                    <div id='wrapper'> 
                        <div id='first-single'>
                            <p id='left' style={{margin: mar}}>
                                Duties and Responsibilites include the following:
                                <ul>
                                    <li>Report to Shift Leader</li>
                                    <li>Take customer orders</li>
                                    <li>Ensure various entrees and sides are fully cooked and available for customers to order</li>
                                    <li>Replenish any store supplies throughout the day</li>
                                    <li>Clean all equptment and ensure all workstations are sanitary and organized</li>
                                    <li>Any additional duties assigned by leadership</li>
                                </ul>
                            </p>
                        </div>
                        <div>
                            <a href="https://mc-2c2007e7-68ca-4429-8b17-5560-cdn-endpoint.azureedge.net/-/media/alex-lee/lf/logos/chickenkitchen_logo_cream.png?rev=fb4e23d25df24a258ef082b2dea7e6fe&h=281&w=675&la=en&hash=65401DA3C2129577AFAB47126C1994C5">
                                <img src={chkn} style={{padding: '5px', height: h, width: w}} alt='chickenkitchen.png' align='middle'/>
                            </a>
                        </div>
                        
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div>
                        <div>
                            <p id='left' style={{margin: mar, textIndent: indent}}>
                                During my time working at Lowes Foods, I learned a lot about customer service and cooking in a
                                commercial kitchen. Because this was my first <font style={{fontStyle: 'italic'}}>real</font> job,
                                most of the things I was experiencing was new. However, I caught on fairly quick and became very 
                                comfortable with my required tasks. I also made some good friends from this job. We worked well 
                                together as a team at work and communcated as friends outside of work. Due to my status as full 
                                time student at the College of Charleston, I decided to leave this job when the 2019 Fall Semester 
                                began in August. Overall, working at Lowes Foods was a good experience, but it was a bit too much
                                to continue to do during the school year. I'd say, it was a pretty good first job for me!
                            </p>
                        </div>

                        <div id='wrapper'>
                            <div id='first'>
                                <a href="https://abcnews4.com/news/local/lowes-foods-celebrate-grand-opening">
                                    <img src={mtp} style={{padding: '5px', height: '70%', width: '70%'}} alt='LowesFoodMtP.jpg' align='middle' />
                                </a>
                            </div>
                            <div id='second'>
                                <a href="https://wildfireideas.com/work/lowes-foods-chicken-kitchen/">
                                    <img src={chkn2} style={{padding: '5px', height: '70%', width: '70%'}} alt='chickenkitchen2.jpg' align='middle' />
                                </a>
                            </div>
                        </div>   
                             
                        <a href='https://www.lowesfoods.com/'>
                                <GeneralButtonLg>Lowes Foods</GeneralButtonLg>
                        </a>
                    </div>
                )
            }
        }

        return (
            <main style={{ marginTop: top, backgroundImage: `url(${foods})`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center', 
                backgroundSize: backSize, height: backH, width: 'auto'}}>
                <div style={{overflow: 'auto', paddingBottom: padBot}}>
                    <Helmet>
                        <title>Alex Bailey Resume | {this.state.Title}</title>
                    </Helmet>
                    <Title />

                    <hr style={{margin: mar}} />

                    <br />
                    <h3>Job Description</h3>
                    <p id='left' style={{margin: mar, textIndent: indent}}>
                        As a Chicken Kitchen Clerk, my duties included cooking fried chicken and various sides, cleaning
                        kitchen equiptment such as deep fryers, ovens, dishes, and floors as well as servicing customers.
                        The <font style={{fontStyle: 'italic'}}>Chicken Kitchen</font> serves numerous items including: 
                        mixed boxes of hand-breaded fried chicken, fried chicken tenders, fried potato wedges, mashed 
                        potatoes, fried okra, mac & cheese, chicken pot pie, and wings. As a Chicken Kitchen Clerk, my 
                        job was to ensure these items were fully cooked and readily available for customers to order. 
                    </p>

                    <br />
                    <br />
                    <br />

                    <h3>Responsibilites</h3>
                    <Resp />

                    <br />
                    <br />
                    <br />

                    <h3>Lowes Foods</h3>
                    <p id="left" style={{margin: mar, textIndent: indent}}>
                        "<a href='https://www.lowesfoods.com/'>Lowes Foods</a> began in 1954 as a 
                        single store in North Wilkesboro, NC, and has grown to become a major supermarket chain operating 
                        in North Carolina, South Carolina and Virginia. The company grew rapidly during the 1960s, '70s 
                        and early '80s. Lowes Foods was acquired by Merchants Distributors, Inc. (MDI) in 1984 and began 
                        an aggressive expansion program in 1986, opening 19 stores over the next four years. In 1997, Lowes 
                        Foods acquired Byrds Food Stores, located in eastern North Carolina. It continued its growth in 
                        2000 with the purchase of 13 Hannaford stores. Today Lowes Foods operates close to 100 stores and 
                        continues to open new stores throughout North and South Carolina. Lowes Foods continued to expand by 
                        opening its first Lowes Foods Fuel station in 2007. In 2010, a new Lowes Foods concept store was 
                        opened in Hickory, NC and in 2011, we introduced a new value format called Just$ave. In 2013, Lowes 
                        Foods set out to break the mold of the standard supermarket and create a distinctive grocery shopping 
                        experience worthy of our Carolina roots. The development began with in depth consumer research and a 
                        series of overnight workshops designed to get the very best ideas from managers across all facets of 
                        the business including all store managers and many from MDI. During these workshops, our new vision 
                        for Lowes Foods was born: “We bring community back to the table,” and, a number of new concepts were 
                        created including Chicken Kitchen, SausageWorks, Pick & Prep, and the Community Table. In January, 
                        2013, Lowes Foods re-opened the first store to offer all of the newly developed concepts in Clemmons, 
                        NC. This spirit of transformation is being carried through all of the Lowes Foods and Just$ave stores, 
                        and, the spark of innovation has led to continued development of new concepts to further differentiate 
                        our stores. While selection may vary from store to store, our commitment to quality, value and service 
                        are the same no matter where you shop." -<a href="https://www.lowesfoods.com/about-us/company-history">Lowes Foods Company History</a>
                    </p>

                    <br />
                    <br />
                    <br />

                    <h3>Experiences</h3>
                    <Exp />

                    <p>Images Courtesy of <a href="https://www.lowesfoods.com/about-us/media/media-kit">Lowes Foods</a></p>
                    <Link to='/resume/view_resume'>
                        <GeneralButton>Back</GeneralButton>
                    </Link>
                    <Link to='/home'>
                        <GeneralButton>Home</GeneralButton>
                    </Link>
                    <ScrollButton />

                </div>
            </main>
        )
    }
}

// Determines Page
function page(id) {

    if (id === "J3") { return YetiLead }
    
    else if (id === "J2") { return YetiGuide }

    else if (id === "J1") { return LowesHome }

    else if (id === "J0") { return ChickenKitchen }

    else { return UnderConst }
}

// Export Page
export const JDetails = () => {
    const { id } = useParams();
    let Page = page(id);
    useEffect(() => {window.scrollTo(0, 0)}, []);
    return (
        <Page />
    )
}