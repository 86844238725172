import { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { GeneralButton } from "../Components/Buttons";
import SimpleImageSlider from "react-simple-image-slider";
import one from '../Images/Desktop/1.jpeg';
import two from '../Images/Desktop/2.jpeg';
import three from '../Images/Desktop/3.jpeg';
import four from '../Images/Desktop/4.jpeg';
import five from '../Images/Desktop/5.jpeg';
import six from '../Images/Desktop/6.jpeg';
import seven from '../Images/Desktop/7.jpeg';
import eight from '../Images/Desktop/8.jpeg';
import nine from '../Images/Desktop/9.jpeg';
import ten from '../Images/Desktop/10.jpeg';
import eleven from '../Images/Desktop/11.jpeg';
import twelve from '../Images/Desktop/12.jpeg';
import thirteen from '../Images/Desktop/13.jpeg';
import fourteen from '../Images/Desktop/14.jpeg';
import one1 from '../Images/Desktop/Original/1.jpeg';
import two2 from '../Images/Desktop/Original/2.jpeg';
import three3 from '../Images/Desktop/Original/3.jpeg';
import four4 from '../Images/Desktop/Original/4.jpeg';
import five5 from '../Images/Desktop/Original/5.jpeg';
import six6 from '../Images/Desktop/Original/6.jpeg';
import seven7 from '../Images/Desktop/Original/7.jpeg';
import '../Styles/Desktop.css';
import ScrollButton from "../Components/ScrollButton";

const slideImages = [
    { url: one }, { url: two },
    { url: three }, { url: four },
    { url: five }, { url: six },
    { url: seven }, { url: eight },
    { url: nine }, { url: ten },
    { url: eleven }, { url: twelve },
    { url: thirteen }, { url: fourteen }
]; 

const slideImages2 = [
  { url: one1 }, { url: two2 },
  { url: three3 }, { url: four4 },
  { url: five5 }, { url: six6 },
  { url: seven7 }
]; 
  
class Slideshow extends Component {
    constructor(props) {
      super(props)
      this.state = { 
        small: window.matchMedia("all and (max-device-width: 640px)").matches,
        medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
        large: window.matchMedia("all and (min-device-width: 1601px)").matches,
        tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches
      };
    }
  
    componentDidMount() {
      const handler = e => this.setState({small: e.matches});
      const handler2 = e => this.setState({medium: e.matches});
      const handler3 = e => this.setState({large: e.matches});
      const handler4 = e => this.setState({tablet: e.matches});
      window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
      window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").addEventListener('change', handler2);
      window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
      window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").addEventListener('change', handler4)
    }
  
    render() {
      var h;
      var w;
      var bullets = true;
      var navMar = 30;
      if(this.state.small) {h = 200; w = 315; bullets = false; navMar = 1;}
      if(this.state.tablet) {h = 350; w = 450;}
      if(this.state.medium) {h = 350; w = 500;}
      if(this.state.large) {h = 450; w = 675;}
      return (
        <div>
          <SimpleImageSlider
            style={{margin: '0 auto'}}
            width={w}
            height={h}
            slideDuration={.5}
            autoPlayDelay={4}
            autoPlay={true}
            images={slideImages}
            navMargin={navMar}
            showBullets={bullets}
            showNavs={true}
          />
        </div>
      );
    }
}

class Slideshow2 extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      small: window.matchMedia("all and (max-device-width: 640px)").matches,
      medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
      large: window.matchMedia("all and (min-device-width: 1601px)").matches,
      tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches
    };
  }

  componentDidMount() {
    const handler = e => this.setState({small: e.matches});
    const handler2 = e => this.setState({medium: e.matches});
    const handler3 = e => this.setState({large: e.matches});
    const handler4 = e => this.setState({tablet: e.matches});
    window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
    window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").addEventListener('change', handler2);
    window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
    window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").addEventListener('change', handler4)
  }

  render() {
    var h;
    var w;
    var bullets = true;
    var navMar = 30;
    if(this.state.small) {h = 200; w = 315; bullets = false; navMar = 1;}
    if(this.state.tablet) {h = 350; w = 450;}
    if(this.state.medium) {h = 350; w = 500;}
    if(this.state.large) {h = 450; w = 675;}
    return (
      <div>
        <SimpleImageSlider
          style={{margin: '0 auto'}}
          width={w}
          height={h}
          slideDuration={.5}
          autoPlayDelay={4}
          autoPlay={true}
          images={slideImages2}
          navMargin={navMar}
          showBullets={bullets}
          showNavs={true}
        />
      </div>
    );
  }
}

class DesktopPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").matches,
            large: window.matchMedia("all and (min-device-width: 1601px)").matches,
        }
    }

    componentDidMount() {

        const handler = e => this.setState({small: e.matches});
        const handler2 = e => this.setState({medium: e.matches});
        const handler3 = e => this.setState({large: e.matches});
        const handler4 = e => this.setState({tablet: e.matches});
        window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
        window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").addEventListener('change', handler2);
        window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
        window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").addEventListener('change', handler4);
    }

    render() {
        var padBot = '6em';
        var top;
        var contTop = '3em';
        let indent = '30px';
        let descMar = '1em 5em 0';
        let margin = '2em 5em 0';
        let height = 450;
        let descHeight = 350;
        let positionY = -500;
        let backSize = '1500px 1125px';
        let over = 'scroll';
        let Desc = () => {
          return (
            <div id='wrapper' style={{margin: descMar}}>
              <div id='first' style={{width: '60%', overflow: 'scroll', height: descHeight}}>
                <p style={{textIndent: indent, textAlign: 'left'}}>
                  This desktop, Windows machine was custom built by me in May 2020. It is the first machine that I have built by 
                  myself. I selected interal parts optimized for gaming as well as RGB components for aesthetics. The current 
                  part specification list is as follows:
                </p>
                <ul style={{textAlign: 'left'}}>
                  <li><font style={{fontWeight: 'bold'}}>Processor:</font> AMD Ryzen 7 3800X 8-Core Processor (3.89 GHz)</li>
                  <li><font style={{fontWeight: 'bold'}}>RAM:</font> Corsair Vengeance LPX 32GB (4 X 8GB) DDR4-3600</li>
                  <li><font style={{fontWeight: 'bold'}}>Motherboard:</font> Gigabyte X570 AORUS PRO WIFI ATX AM4 Motherboard</li>
                  <li><font style={{fontWeight: 'bold'}}>CPU Cooler:</font>	Corsair H115i RGB PLATINUM 97 CFM Liquid CPU Cooler</li>
                  <li><font style={{fontWeight: 'bold'}}>Storage:</font>
                    <ul style={{marginTop: '-.05em'}}>
                      <li>Crucial MX500 500 GB 2.5" Solid State Drive</li>
                      <li>Intel 665p Series M.2 2280 1TB PCIe NVMe 3.0 Solid State Drive</li>
                      <li>Seagate Barracuda Compute 2TB 3.5" 7200 RPM Internal Hard Drive</li>
                      <li>Toshiba X300 4TB Performance & Gaming 3.5-Inch Internal Hard Drive</li>
                    </ul>
                  </li>
                  <li><font style={{fontWeight: 'bold'}}>Video Card:</font> EVGA XC Gaming Nvidia GeForce RTX 2070 8GB GDDR6 Video Card</li>
                  <li><font style={{fontWeight: 'bold'}}>PSU:</font> ASUS ROG Strix 750 Fully Modular 80 Plus Gold 750W ATX Power Supply</li>
                  <li><font style={{fontWeight: 'bold'}}>Case:</font> Corsair 5000D Airflow Tempered Glass Mid-Tower ATX Case</li>
                  <li><font style={{fontWeight: 'bold'}}>Monitors:</font>
                    <ul style={{marginTop: '-.05em'}}>
                      <li>Dell S2716DG LED with G-Sync 27" Gaming Computer Monitor</li>
                        (2560 x 1440 | 1ms Response Time | 144 Hz Refresh Rate)
                      <li>Acer SB220Q bi 21.5 Inches Full HD IPS Ultra-Thin Zero Frame Monitor</li>
                        (1920 x 1080 | 4ms Response Time | 75 Hz Refresh Rate)
                    </ul>
                  </li>
                  <li><font style={{fontWeight: 'bold'}}>Peripherals:</font>
                    <ul style={{marginTop: '-.05em'}}>
                      <li>Tecware Phantom 104 RGB Wired Gaming Keyboard</li>
                      <li>Razer Deathadder V2 Wired Optical Gaming Mouse</li>
                      <li>HP External Portable Slim Design CD/DVD RW Write/Read Drive</li>
                      <li>Kingwin USB HUB Adapter w/ Memory Card Reader Writer & USB 3.0 HUB Combo</li>
                      <li>Techole HDMI Switch 4K HDMI Splitter</li>
                      <li>Western Digital 8TB My Book Desktop External Hard Drive</li>
                      <li>Logitech Z200 Stereo Speakers</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div id='second' style={{width: '39%'}}>
                <Slideshow />
              </div>
            </div>
          )
        }
        let Old = () => {
          return (
            <div id='wrapper' style={{margin: descMar}}>
              <h1 style={{padding: '3em 0 1em 0'}}>Original Version</h1>
              <div id='first' style={{width: '39%'}}>
                <Slideshow2 />
              </div>
              <div id='second' style={{width: '60%', overflow: over, height: descHeight}}>
                <p style={{textIndent: indent, textAlign: 'left', paddingLeft: '.5em'}}>
                  The original configuration of this machine was slightly different from what it is now. I have swapped out
                  as well as added various components to improve the performance and aesthetics of my machine. The components
                  that existed in the original configuration are as follows:
                </p>
                <ul style={{textAlign: 'left'}}>
                  <li><font style={{fontWeight: 'bold'}}>RAM:</font> Teamgroup T-Force Delta RGB 16GB (2 X 8GB) DDR4-3000</li>
                  <li><font style={{fontWeight: 'bold'}}>Storage:</font>
                    <ul style={{marginTop: '-.05em'}}>
                      <li>Crucial MX500 500 GB 2.5" Solid State Drive</li>
                      <li>Seagate Barracuda Compute 2TB 3.5" 7200 RPM Internal Hard Drive</li>
                    </ul>
                  </li>
                  <li><font style={{fontWeight: 'bold'}}>PSU:</font> ASUS ROG Strix 550 Fully Modular 80 Plus Gold 550W ATX Power Supply</li>
                  <li><font style={{fontWeight: 'bold'}}>Case:</font> Fractal Design Meshify C ATX Mid Tower Case</li>
                  <li><font style={{fontWeight: 'bold'}}>Monitor:</font> Acer SB220Q bi 21.5 Inches Full HD IPS Ultra-Thin Zero Frame Monitor</li>
                    (1920 x 1080 | 4ms Response Time | 75 Hz Refresh Rate)
                  <li><font style={{fontWeight: 'bold'}}>Peripherals:</font>
                    <ul style={{marginTop: '-.05em'}}>
                      <li>Tecware Phantom 104 RGB Wired Gaming Keyboard</li>
                      <li>Old Wired Optical Mouse (found in parents' house)</li>
                      <li>HP External Portable Slim Design CD/DVD RW Write/Read Drive</li>
                      <li>Logitech Z200 Stereo Speakers</li>
                    </ul>
                  </li>
                </ul>
                <p style={{textIndent: indent, textAlign: 'left', paddingLeft: '.5em'}}>
                  All other components of the system are the same as the previous list. This system is a ongoing project that has
                  seen multiple variations. For example, the system originally had a 550W PSU, but after using the machine for 
                  about two weeks, it became evident that I needed more power. I then swapped the 550W PSU for a 750W PSU. Additionally,
                  for a while I only had one monitor, but added a second in December of 2020. The system was otherwise the same until 
                  about May of 2021, when I swapped the Fractal Design case with the Corsair case, and so on and so forth. The original 
                  machine and added accessories cost me in total abut $2000.
                </p>
              </div>
            </div>
          );
        }

        if (this.state.small) {
          height = 225;
          positionY = -450;
          top = 0;
          backSize = '550px 413px';
          margin = '2em 3em 0';
          descMar = '1em 3em 0';
          indent = '20px'

          Desc = () => {
            return (
              <div style={{margin: descMar}}>
                <div>
                  <p style={{textIndent: indent, textAlign: 'left'}}>
                    This desktop, Windows machine was custom built by me in May 2020. It is the first machine that I have built by 
                    myself. I selected interal parts optimized for gaming as well as RGB components for aesthetics. The current 
                    part specification list is as follows:
                  </p>
                  <ul style={{textAlign: 'left', marginLeft: 10}}>
                    <li><font style={{fontWeight: 'bold'}}>Processor:</font> AMD Ryzen 7 3800X 8-Core Processor (3.89 GHz)</li>
                    <li><font style={{fontWeight: 'bold'}}>RAM:</font> Corsair Vengeance LPX 32GB (4 X 8GB) DDR4-3600</li>
                    <li><font style={{fontWeight: 'bold'}}>Motherboard:</font> Gigabyte X570 AORUS PRO WIFI ATX AM4 Motherboard</li>
                    <li><font style={{fontWeight: 'bold'}}>CPU Cooler:</font>	Corsair H115i RGB PLATINUM 97 CFM Liquid CPU Cooler</li>
                    <li><font style={{fontWeight: 'bold'}}>Storage:</font>
                      <ul style={{marginTop: '-.05em', marginLeft: 20}}>
                        <li>Crucial MX500 500 GB 2.5" Solid State Drive</li>
                        <li>Intel 665p Series M.2 2280 1TB PCIe NVMe 3.0 Solid State Drive</li>
                        <li>Seagate Barracuda Compute 2TB 3.5" 7200 RPM Internal Hard Drive</li>
                        <li>Toshiba X300 4TB Performance & Gaming 3.5-Inch Internal Hard Drive</li>
                      </ul>
                    </li>
                    <li><font style={{fontWeight: 'bold'}}>Video Card:</font> EVGA XC Gaming Nvidia GeForce RTX 2070 8GB GDDR6 Video Card</li>
                    <li><font style={{fontWeight: 'bold'}}>PSU:</font> ASUS ROG Strix 750 Fully Modular 80 Plus Gold 750W ATX Power Supply</li>
                    <li><font style={{fontWeight: 'bold'}}>Case:</font> Corsair 5000D Airflow Tempered Glass Mid-Tower ATX Case</li>
                    <li><font style={{fontWeight: 'bold'}}>Monitors:</font>
                      <ul style={{marginTop: '-.05em', marginLeft: 20}}>
                        <li>Dell S2716DG LED with G-Sync 27" Gaming Computer Monitor</li>
                          (2560 x 1440 | 1ms Response Time | 144 Hz Refresh Rate)
                        <li>Acer SB220Q bi 21.5 Inches Full HD IPS Ultra-Thin Zero Frame Monitor</li>
                          (1920 x 1080 | 4ms Response Time | 75 Hz Refresh Rate)
                      </ul>
                    </li>
                    <li><font style={{fontWeight: 'bold'}}>Peripherals:</font>
                      <ul style={{marginTop: '-.05em', marginLeft: 20}}>
                        <li>Tecware Phantom 104 RGB Wired Gaming Keyboard</li>
                        <li>Razer Deathadder V2 Wired Optical Gaming Mouse</li>
                        <li>HP External Portable Slim Design CD/DVD RW Write/Read Drive</li>
                        <li>Kingwin USB HUB Adapter w/ Memory Card Reader Writer & USB 3.0 HUB Combo</li>
                        <li>Techole HDMI Switch 4K HDMI Splitter</li>
                        <li>Western Digital 8TB My Book Desktop External Hard Drive</li>
                        <li>Logitech Z200 Stereo Speakers</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div style={{paddingTop: '2em'}}>
                  <Slideshow />
                </div>
              </div>
            )
          }

          Old = () => {
            return (
              <div style={{margin: descMar}}>
                <h1 style={{padding: '3em 0 1em 0'}}>Original Version</h1>
                <div>
                  <p style={{textIndent: indent, textAlign: 'left'}}>
                    The original configuration of this machine was slightly different from what it is now. I have swapped out
                    as well as added various components to improve the performance and aesthetics of my machine. The components
                    that existed in the original configuration are as follows:
                  </p>
                  <ul style={{textAlign: 'left', marginLeft: 10}}>
                    <li><font style={{fontWeight: 'bold'}}>RAM:</font> Teamgroup T-Force Delta RGB 16GB (2 X 8GB) DDR4-3000</li>
                    <li><font style={{fontWeight: 'bold'}}>Storage:</font>
                      <ul style={{marginTop: '-.05em', marginLeft: 20}}>
                        <li>Crucial MX500 500 GB 2.5" Solid State Drive</li>
                        <li>Seagate Barracuda Compute 2TB 3.5" 7200 RPM Internal Hard Drive</li>
                      </ul>
                    </li>
                    <li><font style={{fontWeight: 'bold'}}>PSU:</font> ASUS ROG Strix 550 Fully Modular 80 Plus Gold 550W ATX Power Supply</li>
                    <li><font style={{fontWeight: 'bold'}}>Case:</font> Fractal Design Meshify C ATX Mid Tower Case</li>
                    <li><font style={{fontWeight: 'bold'}}>Monitor:</font> Acer SB220Q bi 21.5 Inches Full HD IPS Ultra-Thin Zero Frame Monitor</li>
                      (1920 x 1080 | 4ms Response Time | 75 Hz Refresh Rate)
                    <li><font style={{fontWeight: 'bold'}}>Peripherals:</font>
                      <ul style={{marginTop: '-.05em', marginLeft: 20}}>
                        <li>Tecware Phantom 104 RGB Wired Gaming Keyboard</li>
                        <li>Old Wired Optical Mouse (found in parents' house)</li>
                        <li>HP External Portable Slim Design CD/DVD RW Write/Read Drive</li>
                        <li>Logitech Z200 Stereo Speakers</li>
                      </ul>
                    </li>
                  </ul>
                  <p style={{textIndent: indent, textAlign: 'left'}}>
                    All other components of the system are the same as the previous list. This system is a ongoing project that has
                    seen multiple variations. For example, the system originally had a 550W PSU, but after using the machine for 
                    about two weeks, it became evident that I needed more power. I then swapped the 550W PSU for a 750W PSU. Additionally,
                    for a while I only had one monitor, but added a second in December of 2020. The system was otherwise the same until 
                    about May of 2021, when I swapped the Fractal Design case with the Corsair case, and so on and so forth. The original 
                    machine and added accessories cost me in total abut $2000.
                  </p>
                </div>
                <div style={{paddingTop: '2em'}}>
                  <Slideshow2 />
                </div>
              </div>
            );
          }
        }

        if (this.state.tablet) {
          contTop = '3em';

          Desc = () => {
            return (
              <div style={{margin: descMar}}>
                <div>
                  <p style={{textIndent: indent, textAlign: 'left'}}>
                    This desktop, Windows machine was custom built by me in May 2020. It is the first machine that I have built by 
                    myself. I selected interal parts optimized for gaming as well as RGB components for aesthetics. The current 
                    part specification list is as follows:
                  </p>
                  <ul style={{textAlign: 'left'}}>
                    <li><font style={{fontWeight: 'bold'}}>Processor:</font> AMD Ryzen 7 3800X 8-Core Processor (3.89 GHz)</li>
                    <li><font style={{fontWeight: 'bold'}}>RAM:</font> Corsair Vengeance LPX 32GB (4 X 8GB) DDR4-3600</li>
                    <li><font style={{fontWeight: 'bold'}}>Motherboard:</font> Gigabyte X570 AORUS PRO WIFI ATX AM4 Motherboard</li>
                    <li><font style={{fontWeight: 'bold'}}>CPU Cooler:</font>	Corsair H115i RGB PLATINUM 97 CFM Liquid CPU Cooler</li>
                    <li><font style={{fontWeight: 'bold'}}>Storage:</font>
                      <ul style={{marginTop: '-.05em'}}>
                        <li>Crucial MX500 500 GB 2.5" Solid State Drive</li>
                        <li>Intel 665p Series M.2 2280 1TB PCIe NVMe 3.0 Solid State Drive</li>
                        <li>Seagate Barracuda Compute 2TB 3.5" 7200 RPM Internal Hard Drive</li>
                        <li>Toshiba X300 4TB Performance & Gaming 3.5-Inch Internal Hard Drive</li>
                      </ul>
                    </li>
                    <li><font style={{fontWeight: 'bold'}}>Video Card:</font> EVGA XC Gaming Nvidia GeForce RTX 2070 8GB GDDR6 Video Card</li>
                    <li><font style={{fontWeight: 'bold'}}>PSU:</font> ASUS ROG Strix 750 Fully Modular 80 Plus Gold 750W ATX Power Supply</li>
                    <li><font style={{fontWeight: 'bold'}}>Case:</font> Corsair 5000D Airflow Tempered Glass Mid-Tower ATX Case</li>
                    <li><font style={{fontWeight: 'bold'}}>Monitors:</font>
                      <ul style={{marginTop: '-.05em'}}>
                        <li>Dell S2716DG LED with G-Sync 27" Gaming Computer Monitor</li>
                          (2560 x 1440 | 1ms Response Time | 144 Hz Refresh Rate)
                        <li>Acer SB220Q bi 21.5 Inches Full HD IPS Ultra-Thin Zero Frame Monitor</li>
                          (1920 x 1080 | 4ms Response Time | 75 Hz Refresh Rate)
                      </ul>
                    </li>
                    <li><font style={{fontWeight: 'bold'}}>Peripherals:</font>
                      <ul style={{marginTop: '-.05em'}}>
                        <li>Tecware Phantom 104 RGB Wired Gaming Keyboard</li>
                        <li>Razer Deathadder V2 Wired Optical Gaming Mouse</li>
                        <li>HP External Portable Slim Design CD/DVD RW Write/Read Drive</li>
                        <li>Kingwin USB HUB Adapter w/ Memory Card Reader Writer & USB 3.0 HUB Combo</li>
                        <li>Techole HDMI Switch 4K HDMI Splitter</li>
                        <li>Western Digital 8TB My Book Desktop External Hard Drive</li>
                        <li>Logitech Z200 Stereo Speakers</li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div style={{paddingTop: '2em'}}>
                  <Slideshow />
                </div>
              </div>
            )
          }

          Old = () => {
            return (
              <div style={{margin: descMar}}>
                <h1 style={{padding: '3em 0 1em 0'}}>Original Version</h1>
                <div>
                  <p style={{textIndent: indent, textAlign: 'left'}}>
                    The original configuration of this machine was slightly different from what it is now. I have swapped out
                    as well as added various components to improve the performance and aesthetics of my machine. The components
                    that existed in the original configuration are as follows:
                  </p>
                  <ul style={{textAlign: 'left'}}>
                    <li><font style={{fontWeight: 'bold'}}>RAM:</font> Teamgroup T-Force Delta RGB 16GB (2 X 8GB) DDR4-3000</li>
                    <li><font style={{fontWeight: 'bold'}}>Storage:</font>
                      <ul style={{marginTop: '-.05em'}}>
                        <li>Crucial MX500 500 GB 2.5" Solid State Drive</li>
                        <li>Seagate Barracuda Compute 2TB 3.5" 7200 RPM Internal Hard Drive</li>
                      </ul>
                    </li>
                    <li><font style={{fontWeight: 'bold'}}>PSU:</font> ASUS ROG Strix 550 Fully Modular 80 Plus Gold 550W ATX Power Supply</li>
                    <li><font style={{fontWeight: 'bold'}}>Case:</font> Fractal Design Meshify C ATX Mid Tower Case</li>
                    <li><font style={{fontWeight: 'bold'}}>Monitor:</font> Acer SB220Q bi 21.5 Inches Full HD IPS Ultra-Thin Zero Frame Monitor</li>
                      (1920 x 1080 | 4ms Response Time | 75 Hz Refresh Rate)
                    <li><font style={{fontWeight: 'bold'}}>Peripherals:</font>
                      <ul style={{marginTop: '-.05em'}}>
                        <li>Tecware Phantom 104 RGB Wired Gaming Keyboard</li>
                        <li>Old Wired Optical Mouse (found in parents' house)</li>
                        <li>HP External Portable Slim Design CD/DVD RW Write/Read Drive</li>
                        <li>Logitech Z200 Stereo Speakers</li>
                      </ul>
                    </li>
                  </ul>
                  <p style={{textIndent: indent, textAlign: 'left'}}>
                    All other components of the system are the same as the previous list. This system is a ongoing project that has
                    seen multiple variations. For example, the system originally had a 550W PSU, but after using the machine for 
                    about two weeks, it became evident that I needed more power. I then swapped the 550W PSU for a 750W PSU. Additionally,
                    for a while I only had one monitor, but added a second in December of 2020. The system was otherwise the same until 
                    about May of 2021, when I swapped the Fractal Design case with the Corsair case, and so on and so forth. The original 
                    machine and added accessories cost me in total abut $2000.
                  </p>
                </div>
                <div style={{paddingTop: '2em'}}>
                  <Slideshow2 />
                </div>
              </div>
            );
          }
        }

        if (this.state.large) { descHeight = 450; }

        return (
            <main style={{marginTop: top, padding: '0 0 0'}}>
                <section id='top' style={{paddingBottom: padBot, backgroundImage: `url(${seven})`, backgroundRepeat: 'repeat', 
                    backgroundSize: backSize, backgroundPositionX: 'center', backgroundPositionY: positionY, height: height, width: 'auto'}}>
                  <Helmet>
                      <title>Alex Bailey Resume | Desktop</title>
                  </Helmet>
                  <div class="container" style={{paddingTop: contTop}}>
                    <div class="box">
                      <h1 className="Title" style={{paddingTop: '0.5em'}}>Hardware</h1>
                      <h2 className="Subtitle" style={{marginTop: -5, fontSize: 23, color: '#333333'}}>Personal Machines</h2>
                    </div>
                  </div>
                </section>

                <hr style={{margin: margin}} />
                <h1 className="Title" style={{paddingTop: '0.5em', marginTop: 0}}>Desktop</h1>
                <h2 className="Subtitle" style={this.state.small ? {fontSize: 20, color: '#333333'} : {color: '#333333'}}>Custom Built Windows PC</h2>
                <hr style={{margin: margin}} />

                <Desc />

                <Old />

                <p style={{paddingTop: '3em', margin: margin}}>
                  This system will be continually updated and is primarily used for gaming. Check back 
                  into this page periodically to see how the system has changed!
                </p>
                <div style={{paddingBottom: '5em'}}>
                  <Link to="/home">
                    <GeneralButton>Home</GeneralButton>
                  </Link>
                </div>

                <ScrollButton />
            </main>
        )
    }
}

export const Desktop = () => {
    return (
        <DesktopPage />
    )
}