import React, { ChangeEvent, FormEvent, useRef, useState } from "react";
import axios from "axios";
import ReCAPTCHA from 'react-google-recaptcha';
import { Alert, Box, Card, CardActions, CardContent, CardHeader, TextField, InputAdornment } from '@mui/material';
import * as RiIcons from "react-icons/ri";
import * as IoIcons from "react-icons/io";
import * as Io5Icons from "react-icons/io5";
import * as BsIcons from "react-icons/bs";
import { FormSubmitButton } from "../Components/Buttons";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Helmet } from 'react-helmet';
import "../Styles/ContactMe.css";

const screens = {
  small: window.matchMedia("all and (max-device-width: 640px)").matches,
  medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
  large: window.matchMedia("all and (min-device-width: 1601px)").matches,
  tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches
};

function getWidth() {
  let width;
  if (screens.small) {width = 250}
  if (screens.medium) {width = 400}
  if (screens.large) { width = 500}
  if (screens.tablet) {width = 350}
  return width;
}

const theme = createTheme({
  typography: {
    fontFamily: [
      "Avenir"
    ].join()
  }
});

type FormState = {
  email: string;
  name: string;
  company: string;
  message: string;
  date: string;
};

type ServiceMessage = {
  success: boolean;
  text: string;
};

function ContactMeForm() {
  const formId = "YJwCXBmr";
  const formSparkUrl = `https://submit-form.com/${formId}`;
  const recaptchaKey = '6LeoZU8jAAAAAIelBJOpTfi3g0MPcdVLvVBkLIPz';
  const recaptchaRef = useRef<any>();

  const initialFormState = {
    email: "",
    name: "",
    company: "",
    message: "",
    date: "",
  };

  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [message, setMessage] = useState<ServiceMessage>();
  const [recaptchaToken, setReCaptchaToken] = useState<string>();

  const submitForm = async (event: FormEvent) => {
    event.preventDefault();
    setSubmitting(true);
    await postSubmission();
    setSubmitting(false);
  };

  const postSubmission = async () => {
    const payload = {
      ...formState,
      "g-recaptcha-response": recaptchaToken,
    };

    try {
      const result = await axios.post(formSparkUrl, payload);
      console.log(result);
      setMessage({
        success: true,
        text: "Mesage sent successfully! I'll get back to you ASAP!",
      });
      setFormState(initialFormState);
      recaptchaRef.current.reset();
    } catch (error) {
      console.log(error);
      setMessage({
        success: false,
        text: "Sorry! There was an error. Please refresh the page & try again. ",
      });
    }
  };

  const updateFormControl = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    updatedFormState['date'] = getDate();
    setFormState(updatedFormState);
  };

  function getDate() {
    const now = new Date();
    let hours = now.getHours();
    let mins = now.getMinutes().toString();
    let sec = now.getSeconds().toString();
    let meridiem = " AM";
    if (hours > 12) { meridiem = " PM"; hours = hours - 12; }
    if (parseInt(mins) < 10) { mins = "0" + mins; }
    if (parseInt(sec) < 10) { sec = "0" + sec; }
    let dateTime = (now.getMonth()+1) + "/"
      + now.getDate() + "/" 
      + now.getFullYear() + " @ "  
      + hours + ":"  
      + mins + ":"
      + sec
      + meridiem;
    
    return dateTime;
  };

  const updateRecaptchaToken = (token: string | null) => {
    setReCaptchaToken(token as string);
  };

  const title = <><RiIcons.RiSendPlaneFill /> Contact Me</> ;

  const [isSelected1, setIsSelected1] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);
  const [isSelected3, setIsSelected3] = useState(false);

  const emailAdornment = isSelected1
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <IoIcons.IoIosMail style={{fontSize: "110%"}}/>
          </InputAdornment>
        )
      }
    : {};

    const nameAdornment = isSelected2
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <BsIcons.BsPersonCircle />
          </InputAdornment>
        )
      }
    : {};

    const companyAdornment = isSelected3
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <Io5Icons.IoBriefcase />
          </InputAdornment>
        )
      }
    : {};

  return (
    <main>
        <div id='top' style={{justifyContent: 'center', paddingBottom: 40}}>
            <Helmet>
              <title>Alex Bailey Resume | Contact Me</title>
            </Helmet>
            <Box sx={{ display: 'inline-block', margin: '0 1rem'}}>
              <ThemeProvider theme={theme}>
                <Card sx={{ minWidth: 150, backgroundColor: "#e3e3e3", fontFamily: 'Oswald' }}>
                  <CardHeader title={title} />

                  {message && (
                      <div>
                          <Alert style={{margin: "0 1rem"}} severity={message.success ? "success" : "error"}>{message.text}</Alert>
                      </div>
                  )}
                  
                  <form onSubmit={submitForm}>
                    <CardContent> 

                      {/* Email */}
                      <div className="padded">
                        <TextField 
                          required
                          id="email" 
                          label="Email"
                          variant="outlined"
                          type="email"
                          onChange={updateFormControl}
                          value={formState?.email} 
                          sx={{width: getWidth()}}
                          InputProps={emailAdornment}
                          onFocus={e => setIsSelected1(true)}
                          onBlur={formState.email ? undefined : e => setIsSelected1(false)}
                          onInput={e => setIsSelected1(true)}
                        />
                      </div>

                      {/* Name */}
                      <div className="padded">
                        <TextField 
                          required
                          id="name" 
                          label="Name" 
                          variant="outlined"
                          onChange={updateFormControl}
                          value={formState?.name} 
                          sx={{width: getWidth()}}
                          InputProps={nameAdornment}
                          onFocus={e => setIsSelected2(true)}
                          onBlur={formState.name ? undefined : e => setIsSelected2(false)}
                          onInput={e => setIsSelected2(true)}
                        />
                      </div> 

                      {/* Company */}
                      <div className="padded">
                        <TextField 
                          id="company" 
                          label="Company" 
                          variant="outlined"
                          onChange={updateFormControl}
                          value={formState?.company} 
                          sx={{width: getWidth()}}
                          InputProps={companyAdornment}
                          onFocus={e => setIsSelected3(true)}
                          onBlur={formState.company ? undefined : e => setIsSelected3(false)}
                          onInput={e => setIsSelected3(true)}
                        />
                      </div> 

                      {/* Message */}
                      <div className="padded">
                        <TextField 
                          multiline
                          id="message" 
                          label="Message" 
                          variant="outlined"
                          onChange={updateFormControl}
                          value={formState?.message} 
                          rows={4}
                          sx={{width: getWidth()}}
                        />
                      </div>

                      {/* ReCAPTCHA */}
                      <div className="padded" style={{width: getWidth(), justifyContent: 'center'}}>
                        <ReCAPTCHA
                            style={{transform: 'scale(0.77)', webkitTransform: 'scale(0.77)', transformOrigin: '0 0', webkitTransformOrigin: '0 0'}}
                            ref={recaptchaRef}
                            sitekey={recaptchaKey}
                            onChange={updateRecaptchaToken}
                        />
                      </div>
                    </CardContent>

                    <input type='hidden' id="date" value={formState?.date} />

                    <CardActions sx={{margin: '0 1em', justifyContent: 'center'}}>
                      <FormSubmitButton disabled={submitting} style={{marginTop: "-30px"}}>
                          {submitting ? "Submitting..." : "Submit"}
                      </FormSubmitButton>
                    </CardActions>
                  </form>         
                </Card>
              </ThemeProvider>
            </Box>
        </div>
    </main>
  );
}

export default ContactMeForm;