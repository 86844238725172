import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { GeneralButton } from '../Components/Buttons';
import { Helmet } from 'react-helmet';
import image from '../Images/404.png';

class Image extends Component {
  constructor(props) {
      super(props)
      this.state = { 
        small: window.matchMedia("all and (max-device-width: 640px)").matches,
        medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
        large: window.matchMedia("all and (min-device-width: 1601px)").matches 
      };
  }

  componentDidMount() {
    const handler = e => this.setState({small: e.matches});
    const handler2 = e => this.setState({medium: e.matches});
    const handler3 = e => this.setState({large: e.matches});
    window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
    window.matchMedia("all and (min-device-width: 641px) and (max-width: 1600px)").addEventListener('change', handler2);
    window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
  }
  render() {
      let h;
      let w;
      if(this.state.small) {h = '80%'; w = '80%';}
      if(this.state.medium) {h = 398; w = 586;}
      if(this.state.large) {h = 443; w = 651;}
      return (
      <div >
          <img src={image} style={{height: h, width: w}} alt='404.png' />
      </div>
      );
  }
}

export const NotFoundPage = () => {
    return (
      <main>
        <Helmet>
            <title>Page Not Found!</title>
        </Helmet>

        <Image />

        <h1>Error 404: Page Not Found!</h1>
        <p>Looks like you've requested an incorrect page or the page no longer exists.</p>
        <Link to="/home">
            <GeneralButton>Home</GeneralButton>
        </Link>

      </main>
      
    );
  };