
import React, {Component, Fragment} from 'react';
import '../Styles/Footer.css';
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { SiInstagram, SiFacebook, SiLinkedin, SiFirebase, SiReact } from "react-icons/si";

class Foot extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
            large: window.matchMedia("all and (min-device-width: 1601px)").matches,
          };
        }
      
        componentDidMount() {
          const handler = e => this.setState({small: e.matches});
          const handler2 = e => this.setState({medium: e.matches});
          const handler3 = e => this.setState({large: e.matches});
          window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
          window.matchMedia("all and (min-device-width: 641px) and (max-width: 1600px)").addEventListener('change', handler2);
          window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
        }

    render() {
        const currentYear = new Date().getFullYear();
        var pad;
        var font = '100%';
        if(this.state.small) {pad = '25px'; font = '85%'}
        if(this.state.medium) {pad = '7px';}
        if(this.state.large) {pad = '5px';}
        let Statement = () => {
            return (
                <>|| &nbsp; Copyright {<AiOutlineCopyrightCircle />} {currentYear} Alex Bailey</>
            )
        }
        return (
            <Fragment>
                <footer style={{paddingBottom: pad, fontSize: font}}>
                    <p> Built Using: &nbsp; <a href='https://reactjs.org/' className='footer'>{<SiReact />}</a>
                    &nbsp;+ <a href='https://firebase.google.com/' className='footer'>{<SiFirebase />}</a> &nbsp; || &nbsp; &nbsp;
                        <a 
                            href='https://www.instagram.com/bailey_alex99/' className='footer'>{<SiInstagram />}
                        </a> &nbsp; 
                        <a 
                            href='https://www.facebook.com/bailey.alex99/' className='footer'>{<SiFacebook />}
                        </a> &nbsp;
                        <a
                            href='https://www.linkedin.com/in/alex-bailey-07713923b/' className='footer'>{<SiLinkedin />}
                        </a> &nbsp; &nbsp;
                        {this.state.medium && <Statement />}
                        {this.state.large && <Statement />}
                    </p>
                    {this.state.small && <p style={{marginTop: '-5px'}}>Copyright {<AiOutlineCopyrightCircle />} {currentYear} Alex Bailey</p> }
                </footer>
            </Fragment>
        )
    }
};

export const Footer = () => {
    return (
    <Foot />
    );
};