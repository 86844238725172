import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { AdminData, HomeData, SidebarData } from './SidebarData';
import SubMenu from './SubMenu';
import { IconContext } from 'react-icons/lib';
import logo from '../Images/MainLogoLightGrey.png';
import '../Styles/Header.css';

const Nav = styled.div`
  background: ${({ visable }) => (visable ? '' : '#333333')};
  transition: all .5s ease;
  webkit-transition: all .25s ease;
  moz-transition: all .25s ease;
  height: 80px;
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  z-index: 10;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: #333333;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? '0' : '-100%')};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
  overflow: auto;
`;

const Sidebar = () => {
  let screens = ({
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").matches,
    medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").matches,
    large: window.matchMedia("all and (min-device-width: 1601px)").matches
  });

  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);

  const [visable, setVisible] = useState(false);
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 75){
      setVisible(true)
    } 
    else if (scrolled <= 75){
      setVisible(false)
    }
  };

  window.addEventListener('scroll', toggleVisible);

  const admin = false;

  let l = '';
  let mar = 0;
  let h = '80px';
  let size = '';
  let bar = '';

  if (screens.small) { 
    l = -20;
    mar = '80px'; 
    size = '130%'; 
    h = '60px';
    bar = '95%'
  }

  return (
    <>
      <IconContext.Provider value={{ color: '#F1F2F2' }}>
        <Nav style={{height: h}} visable={visable}>
          <NavIcon to='#'>
            <FaIcons.FaBars onClick={showSidebar} style={visable? {
              color: '#0C4773', 
              transition: 'all .5s ease', 
              WebkitTransition: 'all .25s ease',
              MozTransition: 'all .25s ease',
              marginLeft: l, 
              fontSize: bar} : {marginLeft: l, fontSize: bar}} />
          </NavIcon>
          {!visable && <h1 className='title' style={{fontSize: size}}> Alex Bailey Resume Site</h1>}
        </Nav>
      </IconContext.Provider>
      <IconContext.Provider value={{ color: '#F1F2F2' }}>
        <SidebarNav sidebar={sidebar}>
          <SidebarWrap onMouseLeave={showSidebar} style={{marginBottom: mar}}>
            <NavIcon className='slideout' to='#'>
              {(screens.small || screens.tablet) && <AiIcons.AiOutlineClose onClick={showSidebar} />}
              {(screens.medium || screens.large) && <img src={logo} alt='MainLogoLightGrey.png' style={{height: 85, width: 85, marginLeft: '1.5em'}}/>}
            </NavIcon>
            {HomeData.map((item, index) => {
              return <SubMenu item={item} key={index}/>;
            })}
            {admin ? AdminData.map((item, index) => {
              return <SubMenu item={item} key={index}/>;
            }) : ''}
            {SidebarData.map((item, index) => {
              return <SubMenu item={item} key={index} />;
            })}
          </SidebarWrap>
        </SidebarNav>
      </IconContext.Provider>
    </>
  );
};

export default Sidebar;