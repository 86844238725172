
import React, {Component} from 'react';
import { GetAuth } from '../Components/FirebaseConfig/Index';
import { GeneralButton } from '../Components/Buttons';
import { Link } from 'react-router-dom';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { Helmet } from 'react-helmet';

const auth = GetAuth();

class DashPage extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
            large: window.matchMedia("all and (min-device-width: 1601px)").matches,
            user: '',
            submitting: false,
            loggedIn: false
          };
        }
      
        componentDidMount() {
          const handler = e => this.setState({small: e.matches});
          const handler2 = e => this.setState({medium: e.matches});
          const handler3 = e => this.setState({large: e.matches});
          window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
          window.matchMedia("all and (min-device-width: 641px) and (max-width: 1600px)").addEventListener('change', handler2);
          window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);

          onAuthStateChanged(auth, (currentUser) => {
            this.setState({user: currentUser});
          });

          if (this.state.user) { this.setState({loggedIn: true}) }
        }

    render() {
        const logout = async () => {
            this.setState({submitting: true})
            await signOut(auth);
            this.setState({submitting: false})
        };
        return (
            <main>
                <div>
                    <Helmet>
                        <title>Alex Bailey Resume | Dashboard</title>
                    </Helmet>
                    <h1>User Dashboard</h1>
                    {this.state.user && <h4> Logged In To: {this.state.user.email}</h4>}
                    {this.state.user && <GeneralButton onClick={logout}>{this.state.submitting ? "Logging Out..." : "Log Out"}</GeneralButton>}
                    {!this.state.user && <h4>No User Logged In</h4>}
                    {!this.state.user && <Link to='/login'><GeneralButton>Log In</GeneralButton></Link>}
                    <p>More features coming soon!</p>
                </div>
            </main>
        )
    }
};

export const Dashboard = () => {
    return (
    <DashPage />
    );
  };