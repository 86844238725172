import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as BsIcons from 'react-icons/bs';
import * as RiIcons from 'react-icons/ri';
import * as GiIcons from 'react-icons/gi';
import * as AiIcons from 'react-icons/ai';
import * as HiIcons from 'react-icons/hi';
import * as IoIcons from 'react-icons/io5';
import * as ImIcons from 'react-icons/im';
import * as MdIcons from 'react-icons/md';

export const HomeData = [
  {
    title: 'Home',
    path: '/home',
    icon: <AiIcons.AiFillHome />
  },
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: <MdIcons.MdOutlineSpaceDashboard />
  }
];

export const SidebarData = [
  {
    title: 'Hardware',
    icon: <IoIcons.IoHardwareChip />,
    path: '#',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Desktop',
        path: 'hardware/desktop',
        icon: <HiIcons.HiOutlineDesktopComputer />
      },
      {
        title: 'Laptop',
        path: 'hardware/laptop',
        icon: <BsIcons.BsLaptop />
      }
    ]
  },
  {
    title: 'Resume',
    icon: <ImIcons.ImProfile />,
    path: '#',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'About Me',
        path: '/resume/about',
        icon: <BsIcons.BsInfoCircleFill />
      },
      {
        title: 'Resume',
        path: '/resume/view_resume',
        icon: <ImIcons.ImProfile />
      }
    ]
  },
  {
    title: 'Projects',
    icon: <IoIcons.IoBuildSharp />,
    path: '#',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'GitHub',
        path: '/projects/git',
        icon: <BsIcons.BsGithub />,
      },
      {
        title: 'Soteria CAT',
        path: '/projects/soteria_cat',
        icon: <BsIcons.BsFillBriefcaseFill />,
      },
      {
        title: 'Spot Finder',
        path: '/projects/spot_finder',
        icon: <AiIcons.AiOutlineCar />,
      },
      {
        title: 'Resume Site',
        path: '/projects/resume_site',
        icon: <FaIcons.FaReact />
      },
      {
        title: 'Chucktown Sound',
        path: '/projects/chucktown_sound',
        icon: <GiIcons.GiMusicalNotes />
      }
    ]
  },
  {
    title: 'Contact Me',
    path: '/contact',
    icon: <AiIcons.AiOutlineMessage />
  }
];

export const AdminData = [
  {
    title: 'Admin',
    icon: <RiIcons.RiAdminLine />,
    path: '#',
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,

    subNav: [
      {
        title: 'Dashboard',
        path: '/admin',
        icon: <MdIcons.MdDashboard />
      },
      {
        title: 'Manage Jobs',
        path: '/admin/manage-jobs',
        icon: <BsIcons.BsBriefcaseFill />
      },
      {
        title: 'Manage Education',
        path: '/admin/manage-ed',
        icon: <IoIcons.IoSchoolSharp />
      }
    ]
  }
];