import React, { Component, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import StartFire from "../Components/FirebaseConfig/Index";
import { ref, get, child } from "firebase/database";
import { UnderConst } from "./UnderConstruction";
import ScrollButton from "../Components/ScrollButton";
import { GeneralButton } from "../Components/Buttons";
import { Helmet } from 'react-helmet';
import band from '../Images/Wando/Band.jpg';
import logo from '../Images/Wando/Logo.jpeg';
import wando from '../Images/Wando/Wando.jpeg';
import wando2 from '../Images/Wando/Wando2.jpeg';
import wando3 from '../Images/Wando/Wando3.jpeg';
import wando4 from '../Images/Wando/Wando4.jpeg';
import cofcLogo from '../Images/CofC/Logo.png';
import cistern from '../Images/CofC/Cistern.jpeg';
import cistern2 from '../Images/CofC/Cistern2.jpg';
import harbor from '../Images/CofC/Harbor.jpeg';
import cougar from '../Images/CofC/Cougar.jpg';
import cs from '../Images/CofC/CS.jpg';
import cts from '../Images/CofC/CTSLogo.png';
import "../Styles/EdDetails.css";

// Database reference
const db = StartFire();

// High School
class HS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").matches,
            large: window.matchMedia("all and (min-device-width: 1601px)").matches,
            Institution: '',
            Type: '',
            Major: '',
            Graduation: '',
            Status: ''
        }
    }

    componentDidMount() {
        const dbRef = ref(db);
        get(child(dbRef, 'Education/E0')).then((snapshot)=>{
          this.setState({
              Institution: snapshot.val().Institution,
              Type: snapshot.val().Type,
              Major: snapshot.val().Major,
              Graduation: snapshot.val().Graduation,
              Status: snapshot.val().Status
            })
        });

        const handler = e => this.setState({small: e.matches});
        const handler2 = e => this.setState({medium: e.matches});
        const handler3 = e => this.setState({large: e.matches});
        const handler4 = e => this.setState({tablet: e.matches});
        window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
        window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").addEventListener('change', handler2);
        window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
        window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").addEventListener('change', handler4);
    }

    render() {
        var margin = '1em 16em';
        let hr = '0 16em'
        var backSize;
        var padBot;
        var repeat;
        let indent = '30px';
        let top = '';
        let pltw = 'https://www.pltw.org/about-us'
        let w = 450;
        let h = 300;
        let w2 = 375;
        let h2 = 250;
        let al1 = 'right';
        let al2 = 'left';

        let Title = () => {
            return (
                <div>
                    <h1 className="Title">{this.state.Institution}</h1>
                    <h2 className="Subtitle">Mount Pleasant, SC</h2>
                </div>
            )
        };

        let About = () => {
            return (
                <>
                    <div id='wrapper' style={{ margin: margin }}>
                        <div id='first'>
                            <img src={logo} alt='Logo.jpeg' style={{padding: '1px 0'}} align='center' />
                        </div>
                        <div id='second'>
                            <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                            Wando High School with an enrollment of approximately 3,000 students is located on a 100 acre campus in Mount Pleasant, 
                            South Carolina. Wando High School is a large, public non-magnet high school serving a diverse popula$on of students in 
                            grades 9 - 12 with a comprehensive academic program offering more than 200 courses. Wando has maintained a reputation of 
                            academic excellence while providing rigorous standards in all areas for college and career readiness.
                            </p>
                        </div>
                    </div>
                    <div>
                        <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                            More than 25% of our students excel in the visual and performing arts. The Athletic Department has been the recipient of 
                            the South Carolina Athletic Administrators Associa$on Carlisle Cup for the last seven out of eight years for Class AAAAA. 
                            This first place award is based on the rankings of the overall athletic program of the Class AAAAA schools. Wando competes 
                            in 21 sports with 37 teams.
                        </p>
                        <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                            Throughout the year, our students raise funds, collect resources, and serve alongside community members at various charities
                            and events in support of those in need. Our students are encouraged to be involved in Wando life and “Walk the Warrior Way”
                            with integrity, compassion, and personal responsibility. -Courtesy of <a href='https://www.ccsdschools.com/Domain/896'>CCSD Schools</a>
                        </p>
                    </div>
                </>
            )
        };

        let Photos = () => {
            return (
                <div id='wrapper' style={{ margin: margin }}>
                    <div id='first'>
                        <a href="https://www.ccsdschools.com/Page/315">
                            <img src={wando3} alt='wando3.jpg' align={al1} style={{ padding: '1px', width: w, height: h}} />
                        </a>
                    </div>
                    <div id='second'>
                        <a href="https://husseygaybell.com/portfolio/wando-high-school-mt-pleasant-sc/">
                            <img src={wando4} alt='wando4.jpg' align={al2} style={{ padding: '1px', width: w, height: h}} />
                        </a>
                    </div>
                </div>
            )
        }

        let Exp = () => {
            return (
                <>
                    <div>
                        <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                            Attending Wando High School was like going to school in a small city. I graduated with over 950 other students. 
                            The total number of students attending Wando during my time there was over 4,000! Of course, I always knew that 
                            most high schools are not as large as Wando. However, I never really grasped exactly how large the student 
                            population really was until I began college and began meeting lots of people from other places. When I would 
                            tell them how many people were in just my graduating class, people would usually respond with something along 
                            the lines of, "Wow! That's how many people attended my school, total!" 
                        </p>
                    </div>
                    <div id='wrapper' style={{ margin: margin }}>
                        <div id='first'>
                            <p className="left" style={{textIndent: indent}}>
                                During my time in high school, I was also a member of the Wando Band. Of course, a gigantic high school 
                                has an equally gigantic band to match. In my senior year at Wando, the band had over 280 students, not 
                                including the colorguard. Within the Wando Band, I partook in the marching band, the concert band, and 
                                the jazz band. Additonally, I was also apart of several brass quintet and a few tuba-euphonium quartets. 
                                I studied privately with <a href="http://www.clarksontuba.com/bio">Dr. Justin Clarkson</a> throughout 
                                my four years at Wando. I was also one of the Tuba Section leaders for the marching band as well as a 
                                member of the band's top ensemble during my junior and senior year. 
                            </p>
                        </div>
                        <div id='second'>
                            <a href="http://wandobands.org/">
                                <img src={band} alt='wandobandlogo.jpg' align='center' style={{ padding: '1px', width: w2, height: h2}} />
                            </a>
                        </div>
                    </div>
                    <div>
                        <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                            "Wando High School has one of the most comprehensive band programs 
                            in South Carolina.  Over two hundred fifty students participate in three concert bands, chamber winds, 
                            percussion ensemble classes, marching band, three jazz bands, and various chamber ensembles.  The Wando 
                            Symphonic Band performed at the 2007 Midwest International Band and Orchestra Clinic and in 2007 received 
                            the Sudler Flag of Honor, administered by the John Philip Sousa Foundation. The band also performed at the 
                            1998 and 2002 Bands of America National Concert Band Festivals. Other honors include being named National 
                            Honor Band for the 2005 Grand NAI, the 2004 and 2009 University of South Carolina Band Clinics, and the 2006 
                            and 2011 National Concert Band and Orchestra Invitational through the Disney Honors program. The marching 
                            band regularly performs at the Bands of America Super Regional competition in Atlanta, and in 2009, 2011, 
                            2013, 2014, 2015, and 2016, & 2017 each time reaching the final 12 out of 30-40 bands. Every member of the 
                            band performs solo or chamber works throughout the year.  Many students study privately with various 
                            professionals in the Charleston area including members of the Charleston Symphony.  Their efforts are evident 
                            in their selection to the All-County, Region, and All-State Bands.  Wando High School has put the most 
                            students in the South Carolina All-State Band for the past several years. In addition, Wando also placed the 
                            highest number of students in all state jazz band as well as Honor Band of America the past several years. " -
                            <a href="http://wandobands.org/about-wando-bands/">
                                Wando Bands
                            </a>
                        </p>
                    </div>
                    <div>
                        <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                            Being a member of the Wando Band was an incredible experience. I met some lifelong friends and learned skills t
                            hat I will be able to utilise in both life and my career. 
                        </p>
                    </div>
                    <div id='wrapper' style={{ margin: margin }}>
                        <div id='first' style={{ marginTop: '1em' }}>
                            <a href="https://www.counton2.com/wp-content/uploads/sites/7/2018/04/wando-high-school_37243476_ver1.0.jpg">
                                <img src={wando} alt='wando.jpg' align='center' style={{ padding: '1px', width: w2, height: h2}} />
                            </a>
                        </div>
                        <div id='second'>
                            <p className="left" style={{textIndent: indent}}>
                            Overall, my experiences at Wando High School are very positive. During my time at Wando, I took several courses 
                            classified under the Science, Technology, Engineering, and Mathematics (STEM) category. All of Wando's STEM 
                            courses were unified under the Project Lead the Way curriculum (<a href={pltw}>PLTW</a>). During this time, I 
                            thought that I would be attending college for mechanical engineering, so I chose to follow the Engineering track. 
                            The courses I took included two Engineering Design course as well as an Aerospace Engineering course. Wando's 
                            computer science course are also very robust and follow the PLTW curriculum. However, I did not choose to pursue 
                            anything in computer science until after my graduation from Wando in 2018.
                            </p>
                        </div>
                    </div>
                </>
                
            )
        }

        if (this.state.small) {
            backSize = '640px 300px';
            padBot = '100px'; 
            repeat = 'no-repeat';
            top = '60px';
            indent = '10px';
            margin = '1em 2em';
            hr = '0 2em'

            Title = () => {
                return (
                    <div>
                        <h1 className="Title" style={{fontSize: '200%', paddingTop: '10em'}}>{this.state.Institution}</h1>
                        <h2 className="Subtitle" style={{color: 'black'}}>Mount Pleasant, SC</h2>
                    </div>
                )
            }

            About = () => {
                return (
                    <div style={{ margin: margin }}>

                        <img src={logo} alt='Logo.jpeg' style={{padding: '1px 0'}} align='center' />

                        <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                        Wando High School with an enrollment of approximately 3,000 students is located on a 100 acre campus in Mount Pleasant, 
                        South Carolina. Wando High School is a large, public non-magnet high school serving a diverse popula$on of students in 
                        grades 9 - 12 with a comprehensive academic program offering more than 200 courses. Wando has maintained a reputation of 
                        academic excellence while providing rigorous standards in all areas for college and career readiness.
                        </p>

                        <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                            More than 25% of our students excel in the visual and performing arts. The Athletic Department has been the recipient of 
                            the South Carolina Athletic Administrators Associa$on Carlisle Cup for the last seven out of eight years for Class AAAAA. 
                            This first place award is based on the rankings of the overall athletic program of the Class AAAAA schools. Wando competes 
                            in 21 sports with 37 teams.
                        </p>

                        <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                            Throughout the year, our students raise funds, collect resources, and serve alongside community members at various charities
                            and events in support of those in need. Our students are encouraged to be involved in Wando life and “Walk the Warrior Way”
                            with integrity, compassion, and personal responsibility. -Courtesy of <a href='https://www.ccsdschools.com/Domain/896'>CCSD Schools</a>
                        </p>
                    </div>
                )
            };

            Photos = () => {
                return (
                    <div style={{ margin: margin }}>
                        <a href="https://www.ccsdschools.com/Page/315">
                            <img src={wando3} alt='wando3.jpg' align='center' style={{ width: 250, height: 167}} />
                        </a>
                        <a href="https://husseygaybell.com/portfolio/wando-high-school-mt-pleasant-sc/">
                            <img src={wando4} alt='wando4.jpg' align='center' style={{ width: 250, height: 167}} />
                        </a>
                    </div>
                )
            }

            Exp = () => {
                return (
                    <div style={{ margin: margin }}>
                        <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                            Attending Wando High School was like going to school in a small city. I graduated with over 950 other students. 
                            The total number of students attending Wando during my time there was over 4,000! Of course, I always knew that 
                            most high schools are not as large as Wando. However, I never really grasped exactly how large the student 
                            population really was until I began college and began meeting lots of people from other places. When I would 
                            tell them how many people were in just my graduating class, people would usually respond with something along 
                            the lines of, "Wow! That's how many people attended my school, total!" 
                        </p>
                        <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                            During my time in high school, I was also a member of the Wando Band. Of course, a gigantic high school 
                            has an equally gigantic band to match. In my senior year at Wando, the band had over 280 students, not 
                            including the colorguard. Within the Wando Band, I partook in the marching band, the concert band, and 
                            the jazz band. Additonally, I was also apart of several brass quintet and a few tuba-euphonium quartets. 
                            I studied privately with <a href="http://www.clarksontuba.com/bio">Dr. Justin Clarkson</a> throughout 
                            my four years at Wando. I was also one of the Tuba Section leaders for the marching band as well as a 
                            member of the band's top ensemble during my junior and senior year. 
                        </p>
                        <a href="http://wandobands.org/">
                            <img src={band} alt='Band.jpg' align='center' style={{ padding: '1px', width: 250, height: 167}} />
                        </a>
                        <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                            "Wando High School has one of the most comprehensive band programs 
                            in South Carolina.  Over two hundred fifty students participate in three concert bands, chamber winds, 
                            percussion ensemble classes, marching band, three jazz bands, and various chamber ensembles.  The Wando 
                            Symphonic Band performed at the 2007 Midwest International Band and Orchestra Clinic and in 2007 received 
                            the Sudler Flag of Honor, administered by the John Philip Sousa Foundation. The band also performed at the 
                            1998 and 2002 Bands of America National Concert Band Festivals. Other honors include being named National 
                            Honor Band for the 2005 Grand NAI, the 2004 and 2009 University of South Carolina Band Clinics, and the 2006 
                            and 2011 National Concert Band and Orchestra Invitational through the Disney Honors program. The marching 
                            band regularly performs at the Bands of America Super Regional competition in Atlanta, and in 2009, 2011, 
                            2013, 2014, 2015, and 2016, & 2017 each time reaching the final 12 out of 30-40 bands. Every member of the 
                            band performs solo or chamber works throughout the year.  Many students study privately with various 
                            professionals in the Charleston area including members of the Charleston Symphony.  Their efforts are evident 
                            in their selection to the All-County, Region, and All-State Bands.  Wando High School has put the most 
                            students in the South Carolina All-State Band for the past several years. In addition, Wando also placed the 
                            highest number of students in all state jazz band as well as Honor Band of America the past several years. " -
                            <a href="http://wandobands.org/about-wando-bands/">
                                Wando Bands
                            </a>
                        </p>
                        <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                            Being a member of the Wando Band was an incredible experience. I met some lifelong friends and learned skills t
                            hat I will be able to utilise in both life and my career. 
                        </p>
                        <a href="https://www.counton2.com/wp-content/uploads/sites/7/2018/04/wando-high-school_37243476_ver1.0.jpg">
                            <img src={wando} alt='wando.jpg' align='center' style={{ padding: '1px', width: 250, height: 167}} />
                        </a>
                        <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                        Overall, my experiences at Wando High School are very positive. During my time at Wando, I took several courses 
                        classified under the Science, Technology, Engineering, and Mathematics (STEM) category. All of Wando's STEM 
                        courses were unified under the Project Lead the Way curriculum (<a href={pltw}>PLTW</a>). During this time, I 
                        thought that I would be attending college for mechanical engineering, so I chose to follow the Engineering track. 
                        The courses I took included two Engineering Design course as well as an Aerospace Engineering course. Wando's 
                        computer science course are also very robust and follow the PLTW curriculum. However, I did not choose to pursue 
                        anything in computer science until after my graduation from Wando in 2018.
                        </p>
                    </div>
                    
                )
            }
        }

        if (this.state.medium) {
            padBot = '75px';

            Title = () => {
                return (
                    <div>
                        <h1 className="Title">{this.state.Institution}</h1>
                        <h2 className="Subtitle" style={{color: 'black'}}>Mount Pleasant, SC</h2>
                    </div>
                )
            };
        }

        if (this.state.tablet) {
            padBot = '75px';
            margin = '1em 3em';
            al1 = 'center';
            al2 = 'center';
            hr = margin;
            w = 300;
            h = 200;
            w2 = 290;
            h2 = 194;

            Title = () => {
                return (
                    <div>
                        <h1 className="Title">{this.state.Institution}</h1>
                        <h2 className="Subtitle" style={{color: 'black'}}>Mount Pleasant, SC</h2>
                    </div>
                )
            };

            About = () => {
                return (
                    <>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first'>
                                <img src={logo} alt='Logo.jpeg' style={{padding: '1px 0', width: 200, height: 129}} align='center' />
                            </div>
                            <div id='second'>
                                <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                                Wando High School with an enrollment of approximately 3,000 students is located on a 100 acre campus in Mount Pleasant, 
                                South Carolina. Wando High School is a large, public non-magnet high school serving a diverse popula$on of students in 
                                grades 
                                </p>
                            </div>
                            <p className="left" style={{marginBottom: 0}}>
                                nine to twelve with a comprehensive academic program offering more than 200 courses. Wando has maintained a reputation of 
                                academic excellence while providing rigorous standards in all areas for college and career readiness.
                            </p>
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                More than 25% of our students excel in the visual and performing arts. The Athletic Department has been the recipient of 
                                the South Carolina Athletic Administrators Associa$on Carlisle Cup for the last seven out of eight years for Class AAAAA. 
                                This first place award is based on the rankings of the overall athletic program of the Class AAAAA schools. Wando competes 
                                in 21 sports with 37 teams.
                            </p>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                Throughout the year, our students raise funds, collect resources, and serve alongside community members at various charities
                                and events in support of those in need. Our students are encouraged to be involved in Wando life and “Walk the Warrior Way”
                                with integrity, compassion, and personal responsibility. -Courtesy of <a href='https://www.ccsdschools.com/Domain/896'>CCSD Schools</a>
                            </p>
                        </div>
                    </>
                )
            };

            Exp = () => {
                return (
                    <>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                Attending Wando High School was like going to school in a small city. I graduated with over 950 other students. 
                                The total number of students attending Wando during my time there was over 4,000! Of course, I always knew that 
                                most high schools are not as large as Wando. However, I never really grasped exactly how large the student 
                                population really was until I began college and began meeting lots of people from other places. When I would 
                                tell them how many people were in just my graduating class, people would usually respond with something along 
                                the lines of, "Wow! That's how many people attended my school, total!" 
                            </p>
                        </div>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first' style={{ marginBottom: '-1em' }}>
                                <p className="left" style={{textIndent: indent}}>
                                    During my time in high school, I was also a member of the Wando Band. Of course, a gigantic high school 
                                    has an equally gigantic band to match. In my senior year at Wando, the band had over 280 students, not 
                                    including the colorguard. Within the Wando Band, I partook in the marching band, the concert band, and 
                                    the jazz band. Additonally, I was also apart of several brass quintet and a few tuba-euphonium quartets. I 
                                </p>
                            </div>
                            <div id='second'>
                                <a href="http://wandobands.org/">
                                    <img src={band} alt='Band.jpg' align='center' style={{ padding: '1px', width: w2, height: h2}} />
                                </a>
                            </div>
                            <br />
                            <p className="left" style={{ marginTop: 0 }}>
                                studied privately with <a href="http://www.clarksontuba.com/bio">Dr. Justin Clarkson</a> throughout
                                my four years at Wando. I was also one of the Tuba Section leaders for the marching band as well as a 
                                member of the band's top ensemble during my junior and senior year. 
                            </p>
                        </div>

                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                "Wando High School has one of the most comprehensive band programs 
                                in South Carolina.  Over two hundred fifty students participate in three concert bands, chamber winds, 
                                percussion ensemble classes, marching band, three jazz bands, and various chamber ensembles.  The Wando 
                                Symphonic Band performed at the 2007 Midwest International Band and Orchestra Clinic and in 2007 received 
                                the Sudler Flag of Honor, administered by the John Philip Sousa Foundation. The band also performed at the 
                                1998 and 2002 Bands of America National Concert Band Festivals. Other honors include being named National 
                                Honor Band for the 2005 Grand NAI, the 2004 and 2009 University of South Carolina Band Clinics, and the 2006 
                                and 2011 National Concert Band and Orchestra Invitational through the Disney Honors program. The marching 
                                band regularly performs at the Bands of America Super Regional competition in Atlanta, and in 2009, 2011, 
                                2013, 2014, 2015, and 2016, & 2017 each time reaching the final 12 out of 30-40 bands. Every member of the 
                                band performs solo or chamber works throughout the year.  Many students study privately with various 
                                professionals in the Charleston area including members of the Charleston Symphony.  Their efforts are evident 
                                in their selection to the All-County, Region, and All-State Bands.  Wando High School has put the most 
                                students in the South Carolina All-State Band for the past several years. In addition, Wando also placed the 
                                highest number of students in all state jazz band as well as Honor Band of America the past several years. " -
                                <a href="http://wandobands.org/about-wando-bands/">
                                    Wando Bands
                                </a>
                            </p>
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                Being a member of the Wando Band was an incredible experience. I met some lifelong friends and learned skills t
                                hat I will be able to utilise in both life and my career. 
                            </p>
                        </div>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first' style={{ marginTop: '1em' }}>
                                <a href="https://www.counton2.com/wp-content/uploads/sites/7/2018/04/wando-high-school_37243476_ver1.0.jpg">
                                    <img src={wando} alt='wando.jpg' align='center' style={{ padding: '1px', width: w2, height: h2}} />
                                </a>
                            </div>
                            <div id='second' style={{ marginBottom: '-1em' }}>
                                <p className="left" style={{textIndent: indent}}>
                                Overall, my experiences at Wando High School are very positive. During my time at Wando, I took several courses 
                                classified under the Science, Technology, Engineering, and Mathematics (STEM) category. All of Wando's STEM 
                                courses were unified under the Project Lead the Way curriculum (<a href={pltw}>PLTW</a>). During this time, I 
                                thought that I would be attending college for mechanical engineering, so I chose to follow the Engineering track. 
                                The courses I took included two 
                                </p>
                            </div>
                            <p className="left" style={{ marginTop: 0 }}>
                                Engineering Design course as well as an Aerospace Engineering course. Wando's computer science course are also 
                                very robust and follow the PLTW curriculum. However, I did not choose to pursue anything in computer science until 
                                after my graduation from Wando in 2018.
                            </p>
                        </div>
                    </>
                    
                )
            }
        }

        if (this.state.large) { 
            padBot = '100px'; 
            margin = '40px 400px';
            hr = '40px 400px';
            w = 600;
            h = 400;
            w2 = 500;
            h2 = 333;
            al1 = 'center';
            al2 = 'center';

            About = () => {
                return (
                    <>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first'>
                                <img src={logo} alt='Logo.jpeg' style={{padding: '1px 0'}} align='center' />
                            </div>
                            <div id='second'>
                                <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                                Wando High School with an enrollment of approximately 3,000 students is located on a 100 acre campus in Mount Pleasant, 
                                South Carolina. Wando High School is a large, public non-magnet high school serving a diverse popula$on of students in 
                                grades 9 - 12 with a comprehensive academic program offering more than 200 courses. Wando has maintained a reputation of 
                                academic excellence while providing rigorous standards in all areas for college and career readiness.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                More than 25% of our students excel in the visual and performing arts. The Athletic Department has been the recipient of 
                                the South Carolina Athletic Administrators Associa$on Carlisle Cup for the last seven out of eight years for Class AAAAA. 
                                This first place award is based on the rankings of the overall athletic program of the Class AAAAA schools. Wando competes 
                                in 21 sports with 37 teams.
                            </p>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                Throughout the year, our students raise funds, collect resources, and serve alongside community members at various charities
                                and events in support of those in need. Our students are encouraged to be involved in Wando life and “Walk the Warrior Way”
                                with integrity, compassion, and personal responsibility. -Courtesy of <a href='https://www.ccsdschools.com/Domain/896'>CCSD Schools</a>
                            </p>
                        </div>
                    </>
                )
            };

            Exp = () => {
                return (
                    <>
                        <div style={{marginBottom: -20}}>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                Attending Wando High School was like going to school in a small city. I graduated with over 950 other students. 
                                The total number of students attending Wando during my time there was over 4,000! Of course, I always knew that 
                                most high schools are not as large as Wando. However, I never really grasped exactly how large the student 
                                population really was until I began college and began meeting lots of people from other places. When I would 
                                tell them how many people were in just my graduating class, people would usually respond with something along 
                                the lines of, "Wow! That's how many people attended my school, total!" 
                            </p>
                        </div>
                        <div id='wrapper' style={{ margin: margin, marginBottom: '1em' }}>
                            <div id='first' style={{ marginBottom: '-1em' }}>
                                <p className="left" style={{textIndent: indent }}>
                                    During my time in high school, I was also a member of the Wando Band. Of course, a gigantic high school 
                                    has an equally gigantic band to match. In my senior year at Wando, the band had over 280 students, not 
                                    including the colorguard. Within the Wando Band, I partook in the marching band, the concert band, and 
                                    the jazz band. Additonally, I was also apart of several brass quintet and a few tuba-euphonium quartets. 
                                    I studied privately with <a href="http://www.clarksontuba.com/bio">Dr. Justin Clarkson</a> throughout my 
                                    four years at Wando. I was also one of the Tuba Section leaders for the marching band as well as a member 
                                    of the band's top ensemble during my junior and senior year. 
                                </p>
                                <p className="left" style={{textIndent: indent}}>
                                    "Wando High School has one of the most comprehensive band programs 
                                    in South Carolina.  Over two hundred fifty students participate in three concert bands, chamber winds, 
                                    percussion ensemble classes, marching band, three jazz bands, and various chamber ensembles.  The Wando 
                                    Symphonic Band performed at the 2007 Midwest International Band and Orchestra Clinic and in 2007 received 
                                    the Sudler Flag of Honor, administered by the John Philip Sousa Foundation. The band also performed at the 
                                    1998 and 2002 Bands of America National Concert Band Festivals. Other honors include being 
                                </p>
                            </div>
                            <div id='second'>
                                <a href="http://wandobands.org/">
                                    <img src={band} alt='Band.jpg' align='center' style={{ padding: '1px', width: 498, height: 335}} />
                                </a>
                            </div>
                            <br />
                            <p className="left" style={{ marginTop: 0 }}>
                                named National Honor Band for the 2005 Grand NAI, the 2004 and 2009 University of South Carolina Band Clinics, 
                                and the 2006 and 2011 National Concert Band and Orchestra Invitational through the Disney Honors program. The 
                                marching band regularly performs at the Bands of America Super Regional competition in Atlanta, and in 2009, 
                                2011, 2013, 2014, 2015, and 2016, & 2017 each time reaching the final 12 out of 30-40 bands. Every member of 
                                the band performs solo or chamber works throughout the year.  Many students study privately with various 
                                professionals in the Charleston area including members of the Charleston Symphony.  Their efforts are evident 
                                in their selection to the All-County, Region, and All-State Bands.  Wando High School has put the most 
                                students in the South Carolina All-State Band for the past several years. In addition, Wando also placed the 
                                highest number of students in all state jazz band as well as Honor Band of America the past several years. " -
                                <a href="http://wandobands.org/about-wando-bands/">
                                    Wando Bands
                                </a>
                            </p>
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                Being a member of the Wando Band was an incredible experience. I met some lifelong friends and learned skills 
                                that I will be able to utilise in both life and my career. 
                            </p>
                        </div>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first' style={{ marginTop: '1em' }}>
                                <a href="https://www.counton2.com/wp-content/uploads/sites/7/2018/04/wando-high-school_37243476_ver1.0.jpg">
                                    <img src={wando} alt='wando.jpg' align='center' style={{ padding: '1px', width: w2, height: h2}} />
                                </a>
                            </div>
                            <div id='second' style={{ marginBottom: '-1em' }}>
                                <p className="left" style={{textIndent: indent}}>
                                Overall, my experiences at Wando High School are very positive. During my time at Wando, I took several courses 
                                classified under the Science, Technology, Engineering, and Mathematics (STEM) category. All of Wando's STEM 
                                courses were unified under the Project Lead the Way curriculum (<a href={pltw}>PLTW</a>). During this time, I 
                                thought that I would be attending college for mechanical engineering, so I chose to follow the Engineering track. 
                                The courses I took included two 
                                </p>
                            </div>
                            <p className="left" style={{ marginTop: 0 }}>
                                Engineering Design course as well as an Aerospace Engineering course. Wando's computer science course are also 
                                very robust and follow the PLTW curriculum. However, I did not choose to pursue anything in computer science until 
                                after my graduation from Wando in 2018.
                                
                            </p>

                            <p style={{paddingTop: '40px'}}>Images Courtesy of <a href="https://www.facebook.com/wandohighschool/">Facebook</a></p>
                            <Link to='/resume/view_resume'>
                                <GeneralButton>Back</GeneralButton>
                            </Link>
                            <Link to='/home'>
                                <GeneralButton>Home</GeneralButton>
                            </Link>
                        </div>
                    </>
                    
                )
            }
        }

        return (
            <main style={{ marginTop: top, backgroundImage: `url(${wando2})`, backgroundRepeat: repeat, backgroundPosition: 'center', 
                backgroundSize: backSize, height: 300, width: 'auto'}}>
                <div id='top' style={{overflow: 'auto', paddingBottom: padBot}}>
                    <Helmet>
                        <title>Alex Bailey Resume | {this.state.Institution}</title>
                    </Helmet>
                    <Title />

                    <hr style={{margin: hr, marginBottom: '10px'}} />
                    <h3>About Wando</h3>
                    <hr style={{margin: hr, marginBottom: '10px'}} />
                    <About />
                    <Photos />

                    <br />

                    <hr style={{margin: hr, marginBottom: '10px'}} />
                    <h3>My Experiences</h3>
                    <hr style={{margin: hr, marginBottom: '10px'}} />
                    <Exp />

                    <br />

                    {!this.state.large && <p>Images Courtesy of <a href="https://www.facebook.com/wandohighschool/">Facebook</a></p>}
                    {!this.state.large && <Link to='/resume/view_resume'>
                        <GeneralButton>Back</GeneralButton>
                    </Link>}
                    {!this.state.large && <Link to='/home'>
                        <GeneralButton>Home</GeneralButton>
                    </Link>}

                    <ScrollButton />

                </div>
            </main>
        )
    }
};

// College
class CofC extends Component {
    constructor(props) {
        super(props);
        this.state = {
            small: window.matchMedia("all and (max-device-width: 640px)").matches,
            tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").matches,
            medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").matches,
            large: window.matchMedia("all and (min-device-width: 1601px)").matches,
            Institution: '',
            Type: '',
            Major: '',
            Graduation: '',
            Status: ''
        }
    }

    componentDidMount() {
        const dbRef = ref(db);
        get(child(dbRef, 'Education/E1')).then((snapshot)=>{
          this.setState({
              Institution: snapshot.val().Institution,
              Type: snapshot.val().Type,
              Major: snapshot.val().Major,
              Graduation: snapshot.val().Graduation,
              Status: snapshot.val().Status
            })
        });

        const handler = e => this.setState({small: e.matches});
        const handler2 = e => this.setState({medium: e.matches});
        const handler3 = e => this.setState({large: e.matches});
        const handler4 = e => this.setState({tablet: e.matches});
        window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
        window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").addEventListener('change', handler2);
        window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
        window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").addEventListener('change', handler4);
    }

    render() {
        var margin = '1em 16em';
        let hr = '0 16em'
        var backSize;
        var padBot;
        var repeat;
        let indent = '30px';
        let top = '';
        let facilities = 'https://cofc.edu/about/ataglance/outstandingfacilities.php';
        let programs = 'https://cofc.edu/academics/';
        let logoW = 150;
        let logoH = 175;
        let w = 450;
        let h = 300;
        let w2 = 400;
        let h2 = 300;
        let cw = 300;
        let ch = 242;
        let sq = 250;
        let al1 = 'right';
        let al2 = 'left';

        let Title = () => {
            return (
                <div>
                    <h1 className="Title">{this.state.Institution}</h1>
                    <h2 className="Subtitle">{this.state.Major}</h2>
                </div>
            )
        };

        let About = () => {
            return (
                <>
                    <div id='wrapper' style={{ margin: margin }}>
                        <div id='first'>
                            <a href='https://cofc.edu/'>
                                <img src={cofcLogo} alt='Logo.png' style={{padding: '1px 0', width: logoW, height: logoH}} align='center' />
                            </a>
                        </div>
                        <div id='second'>
                            <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                            Located in the heart of historic Charleston, South Carolina, the College of Charleston is a nationally recognized, public 
                            liberal arts and sciences university. Founded in 1770, the College is among the nation’s top universities for quality 
                            education, student life and affordability. Its beautiful and historic campus, combined with <a href={facilities}>contemporary facilities</a>,&nbsp;
                            <a href={programs}>cutting-edge programs</a> and accessible faculty attracts students from across the U.S. and around the world.
                            </p>
                        </div>
                    </div>
                    <div>
                        <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                        Over 10,000 undergraduates and approximately 1,000 graduate students at the College enjoy a small-college feel blended with the 
                        advantages and diversity of a mid-sized, urban university. They work closely with a committed faculty, made up of more than 800 
                        distinguished teacher-scholars. And the city of Charleston – world-renowned for its history, architecture, culture and coastal 
                        environment – serves as a living and learning laboratory for experiences in business, science, technology, teaching, the humanities, 
                        languages, government and the arts. -Courtesy of <a href='https://cofc.edu/about/index.php'>College of Charleston</a>
                        </p>
                    </div>
                </>
            )
        };

        let Photos = () => {
            return (
                <div id='wrapper' style={{ margin: margin }}>
                    <div id='first'>
                        <img src={cistern2} alt='Cistern2.jpg' align={al1} style={{ padding: '1px', width: w, height: h}} />
                    </div>
                    <div id='second'>
                        <img src={cougar} alt='Cougar.jpg' align={al2} style={{ padding: '1px', width: w, height: h}} />
                    </div>
                </div>
            )
        }

        let Exp = () => {
            return (
                <>
                    <div>
                        <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                            I began my education at The College of Charleston in August 2018 as an undeclared major. 
                            I had always had an interest in engineering and aviation, so in high school my plan for 
                            college was to major in mechanical or aerospace engineering. However, during my junior 
                            year of high school, I decided that that was not the career path that I wanted to take. 
                            I have also always been interested in all things related to computers. So, in my first 
                            semester at The College, I decided to take the first, intro-level course for computer 
                            science as well as a few gen-eds. During this first computer science course, I learned 
                            Python as well as foundational concepts and algorithms for computer science. It did not 
                            take long for me to decide that I enjoyed programming and computer science enough to 
                            declare it as my major.
                        </p>
                    </div>
                    <div id='wrapper' style={{ margin: margin }}>
                        <div id='first'>
                            <p className="left" style={{textIndent: indent}}>
                            During my time as a Computer Science major, I learned many useful skills, algorithms, and 
                            languages that I still utilize today. As a freshman, I began my journey with simple concepts 
                            and algorithms as well as the basics of Python. From there I expanded my language knowledge 
                            to Java, HTML, CSS, PHP, C, and JavaScript. Additionally, I learned crucial concepts needed 
                            to create and understand a relational database as well as SQL and MariaDB. Also included in my 
                            required coursework was a course on assembly language and hardware organization. I learned the 
                            machine language MIPS as well as expanded my understanding of C and binary. The method we spent 
                            the most time on in this course was translating C to MIPS and then MIPS to binary. We also 
                            learned about logic gates and other components of hardware that allow a computer to function. 
                            This was not an easy course.
                            </p>
                        </div>
                        <div id='second'>
                            <img src={harbor} alt='Harbor.jpg' align='center' style={{ padding: '1px', width: w2, height: h2}} />
                        </div>
                    </div>
                    <div>
                        <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                            Initially, when I declared my major, I was pursuing a Bachelor of Science in Computer Science. However, 
                            due to unforeseen circumstances brought about by the COVID-19 Pandemic, I fell slightly behind on my 
                            required courses. After a large amount of thought as well as consultations with advisors and industry 
                            professionals, I decided to change my Computer Science degree from a Bachelor of Science to a Bachelor 
                            of Arts as well as take an additional semester. This way, I had more time to make professional connections 
                            as well as take my time to <font style={{fontStyle: "italic"}}>really</font> &nbsp;learn the material I would be 
                            given instead of rushing to finish everything within the typical four year time frame. Ultimately, this was 
                            the best decision for my academic career. 
                        </p>
                    </div>
                    <div id='wrapper' style={{ margin: margin }}>
                        <div id='first'>
                            <a href='http://www.chucktownsound.com/'>
                                <img src={cts} alt='CTSLogo.png' align='center' style={{ padding: '1px', width: cw, height: ch}} />
                            </a>

                        </div>
                        <div id='second'>
                            <p className="left" style={{textIndent: indent}}>
                                In addition to my computer science classes, I was also very involved with the College of Charleston Music 
                                Department. Throughout all nine of my semesters, I was a member of the CofC Orchestra, Wind Ensemble, and 
                                the College’s Athletic Band: Chucktown Sound. My primary instrument is the tuba. However, as a member of 
                                the orchestra, I swapped between playing the bass trombone and the tuba. For most of my time with the 
                                orchestra, I played bass trombone. This is because when I first joined the orchestra, there was already a 
                                tubist. However, following his graduation, I became the orchestra’s principal tubist. Furthermore, on pieces 
                                that did not have a tuba part, I played bass trombone instead.
                            </p>
                        </div>
                    </div>
                    <div>
                        <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                            As a member of the CofC Wind Ensemble and Chucktown Sound, I played tuba/sousaphone. Additionally, in Chucktown 
                            Sound, I was also the tuba section leader as well as the band’s Social Media Manager and Webmaster. I created 
                            and managed the band’s Twitter, Instagram, and Facebook accounts as well as recruiting marketing. I also built 
                            and maintained the band’s website which was initially designed with Weebly in 2019. In 2022, I redesigned and 
                            rebuilt the website from scratch using React and Firebase. See the <Link to='/projects/chucktown_sound'>Chucktown 
                            Sound Project</Link> page for more information. Throughout my time at the College of Charleston, I also intended 
                            to complete the music minor. However, due to scheduling conflicts in my final semester, I was unable to complete 
                            all of the required courses for the music minor. Despite the fact that I did not complete the minor, I did learn 
                            a large amount of valuable information that I continue to use in my current musical endeavors. 
                        </p>
                    </div>
                    <div id='wrapper' style={{ margin: margin }}>
                        <div id='first' style={{ marginTop: '1em' }}>
                            <a href='https://compsci.cofc.edu/'>
                                <img src={cs} alt='CS.jpg' align='center' style={{ padding: '1px', width: sq, height: sq}} />
                            </a>
                        </div>
                        <div id='second'>
                            <p className="left" style={{textIndent: indent}}>
                                Overall, my time at the College of Charleston was very positive. I made numerous professional and personal 
                                connections while also creating some amazing memories. I’ve developed so many new, useful skills as well as 
                                improved existing skills. I’ve completed a wide variety of projects that I can showcase and be proud of. I 
                                would like to all the people who have helped me troubleshoot my code, taught me new skills, and mentored me 
                                along the way. Overall, I am very thankful for my four-and-a-half year of education at the College of Charleston. 
                            </p>
                        </div>
                    </div>
                </>
                
            )
        }

        if (this.state.small) {
            backSize = '950px 276px';
            padBot = '100px'; 
            repeat = 'no-repeat';
            top = 0;
            indent = '10px';
            margin = '1em 2em';
            hr = '0 2em';
            w = 250;
            h = 188;
            w2 = 250;
            h2 = 188;
            cw = 250;
            ch = 202;
            sq = 230;

            Title = () => {
                return (
                    <div>
                        <h1 className="Title" style={{fontSize: '200%', paddingTop: '10em'}}>{this.state.Institution}</h1>
                        <h2 className="Subtitle" style={{color: 'black'}}>{this.state.Major}</h2>
                    </div>
                )
            }

            About = () => {
                return (
                    <>
                        <div>
                            <a href='https://cofc.edu/'>
                                <img src={cofcLogo} alt='Logo.png' style={{padding: '10px', width: logoW, height: logoH}} align='center' />
                            </a>
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                Located in the heart of historic Charleston, South Carolina, the College of Charleston is a nationally recognized, public 
                                liberal arts and sciences university. Founded in 1770, the College is among the nation’s top universities for quality 
                                education, student life and affordability. Its beautiful and historic campus, combined with <a href={facilities}>contemporary facilities</a>,&nbsp;
                                <a href={programs}>cutting-edge programs</a> and accessible faculty attracts students from across the U.S. and around the world.
                            </p>
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                Over 10,000 undergraduates and approximately 1,000 graduate students at the College enjoy a small-college feel blended with the 
                                advantages and diversity of a mid-sized, urban university. They work closely with a committed faculty, made up of more than 800 
                                distinguished teacher-scholars. And the city of Charleston – world-renowned for its history, architecture, culture and coastal 
                                environment – serves as a living and learning laboratory for experiences in business, science, technology, teaching, the humanities, 
                                languages, government and the arts. -Courtesy of <a href='https://cofc.edu/about/index.php'>College of Charleston</a>
                            </p>
                        </div>
                    </>
                )
            };
    
            Photos = () => {
                return (
                <>
                    <div>
                        <img src={cistern2} alt='Cistern2.jpg' style={{ padding: '1px', width: w, height: h}} />
                    </div>
                    <div>
                        <img src={cougar} alt='Cougar.jpg' style={{ padding: '1px', width: w, height: h}} />
                    </div>
                </>
                )
            }
    
            Exp = () => {
                return (
                    <>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                I began my education at The College of Charleston in August 2018 as an undeclared major. 
                                I had always had an interest in engineering and aviation, so in high school my plan for 
                                college was to major in mechanical or aerospace engineering. However, during my junior 
                                year of high school, I decided that that was not the career path that I wanted to take. 
                                I have also always been interested in all things related to computers. So, in my first 
                                semester at The College, I decided to take the first, intro-level course for computer 
                                science as well as a few gen-eds. During this first computer science course, I learned 
                                Python as well as foundational concepts and algorithms for computer science. It did not 
                                take long for me to decide that I enjoyed programming and computer science enough to 
                                declare it as my major.
                            </p>
                            <p className="left" style={{textIndent: indent, margin: margin}}>
                                During my time as a Computer Science major, I learned many useful skills, algorithms, and 
                                languages that I still utilize today. As a freshman, I began my journey with simple concepts 
                                and algorithms as well as the basics of Python. From there I expanded my language knowledge 
                                to Java, HTML, CSS, PHP, C, and JavaScript. Additionally, I learned crucial concepts needed 
                                to create and understand a relational database as well as SQL and MariaDB. Also included in my 
                                required coursework was a course on assembly language and hardware organization. I learned the 
                                machine language MIPS as well as expanded my understanding of C and binary. The method we spent 
                                the most time on in this course was translating C to MIPS and then MIPS to binary. We also 
                                learned about logic gates and other components of hardware that allow a computer to function. 
                                This was not an easy course.
                            </p>
                        </div>
                        <div style={{margin: margin}}>
                            <img src={harbor} alt='Harbor.jpg' align='center' style={{ padding: '1px', width: w2, height: h2 }} />
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                Initially, when I declared my major, I was pursuing a Bachelor of Science in Computer Science. However, 
                                due to unforeseen circumstances brought about by the COVID-19 Pandemic, I fell slightly behind on my 
                                required courses. After a large amount of thought as well as consultations with advisors and industry 
                                professionals, I decided to change my Computer Science degree from a Bachelor of Science to a Bachelor 
                                of Arts as well as take an additional semester. This way, I had more time to make professional connections 
                                as well as take my time to <font style={{fontStyle: "italic"}}>really</font> &nbsp;learn the material I would be 
                                given instead of rushing to finish everything within the typical four year time frame. Ultimately, this was 
                                the best decision for my academic career. 
                            </p>
                        </div>
                        <div style={{margin: margin}}>
                            <a href='http://www.chucktownsound.com/'>
                                <img src={cts} alt='CTSLogo.png' align='center' style={{ padding: '1px', width: cw, height: ch}} />
                            </a>
                        </div>
                        <div style={{margin: margin}}>
                            <p className="left" style={{textIndent: indent}}>
                                In addition to my computer science classes, I was also very involved with the College of Charleston Music 
                                Department. Throughout all nine of my semesters, I was a member of the CofC Orchestra, Wind Ensemble, and 
                                the College’s Athletic Band: Chucktown Sound. My primary instrument is the tuba. However, as a member of 
                                the orchestra, I swapped between playing the bass trombone and the tuba. For most of my time with the 
                                orchestra, I played bass trombone. This is because when I first joined the orchestra, there was already a 
                                tubist. However, following his graduation, I became the orchestra’s principal tubist. Furthermore, on pieces 
                                that did not have a tuba part, I played bass trombone instead.
                            </p>
                            <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                                As a member of the CofC Wind Ensemble and Chucktown Sound, I played tuba/sousaphone. Additionally, in Chucktown 
                                Sound, I was also the tuba section leader as well as the band’s Social Media Manager and Webmaster. I created 
                                and managed the band’s Twitter, Instagram, and Facebook accounts as well as recruiting marketing. I also built 
                                and maintained the band’s website which was initially designed with Weebly in 2019. In 2022, I redesigned and 
                                rebuilt the website from scratch using React and Firebase. See the <Link to='/projects/chucktown_sound'>Chucktown 
                                Sound Project</Link> page for more information. Throughout my time at the College of Charleston, I also intended 
                                to complete the music minor. However, due to scheduling conflicts in my final semester, I was unable to complete 
                                all of the required courses for the music minor. Despite the fact that I did not complete the minor, I did learn 
                                a large amount of valuable information that I continue to use in my current musical endeavors. 
                            </p>
                        </div>
                        <div style={{ margin: margin, marginTop: '1em' }}>
                            <a href='https://compsci.cofc.edu/'>
                                <img src={cs} alt='CS.jpg' align='center' style={{ padding: '1px', width: sq, height: sq}} />
                            </a>
                        </div>
                        <div>
                            <p className="left" style={{margin: margin, textIndent: indent}}>
                                Overall, my time at the College of Charleston was very positive. I made numerous professional and personal 
                                connections while also creating some amazing memories. I’ve developed so many new, useful skills as well as 
                                improved existing skills. I’ve completed a wide variety of projects that I can showcase and be proud of. I 
                                would like to all the people who have helped me troubleshoot my code, taught me new skills, and mentored me 
                                along the way. Overall, I am very thankful for my four-and-a-half year of education at the College of Charleston. 
                            </p>
                        </div>
                    </>                  
                )
            }
        }

        if (this.state.medium) {
            padBot = '75px';

            Title = () => {
                return (
                    <div>
                        <h1 className="Title">{this.state.Institution}</h1>
                        <h2 className="Subtitle" style={{color: 'black'}}>{this.state.Major}</h2>
                    </div>
                )
            };
        }

        if (this.state.tablet) {
            padBot = '75px';
            margin = '1em 3em';
            al1 = 'center';
            al2 = 'center';
            hr = margin;
            w = 300;
            h = 200;
            w2 = 290;
            h2 = 194;
            cw = 275;
            ch = 222;
            logoW = 200;
            logoH = 233;

            Title = () => {
                return (
                    <div>
                        <h1 className="Title">{this.state.Institution}</h1>
                        <h2 className="Subtitle" style={{color: 'black'}}>{this.state.Major}</h2>
                    </div>
                )
            };

            About = () => {
                return (
                    <>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first'>
                                <a href='https://cofc.edu/'>
                                    <img src={cofcLogo} alt='Logo.png' style={{padding: '1px 0', width: logoW, height: logoH}} align='center' />
                                </a>
                            </div>
                            <div id='second'>
                                <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                                    Located in the heart of historic Charleston, South Carolina, the College of Charleston is a nationally recognized, public 
                                    liberal arts and sciences university. Founded in 1770, the College is among the nation’s top universities for quality 
                                    education, student life and affordability. Its beautiful and historic campus, combined with <a href={facilities}>contemporary facilities</a>,&nbsp;
                                    <a href={programs}>cutting-edge programs</a> and accessible faculty attracts students from across the U.S. and around the world.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                            Over 10,000 undergraduates and approximately 1,000 graduate students at the College enjoy a small-college feel blended with the 
                            advantages and diversity of a mid-sized, urban university. They work closely with a committed faculty, made up of more than 800 
                            distinguished teacher-scholars. And the city of Charleston – world-renowned for its history, architecture, culture and coastal 
                            environment – serves as a living and learning laboratory for experiences in business, science, technology, teaching, the humanities, 
                            languages, government and the arts. -Courtesy of <a href='https://cofc.edu/about/index.php'>College of Charleston</a>
                            </p>
                        </div>
                    </>
                )
            };
    
            Exp = () => {
                return (
                    <>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                I began my education at The College of Charleston in August 2018 as an undeclared major. 
                                I had always had an interest in engineering and aviation, so in high school my plan for 
                                college was to major in mechanical or aerospace engineering. However, during my junior 
                                year of high school, I decided that that was not the career path that I wanted to take. 
                                I have also always been interested in all things related to computers. So, in my first 
                                semester at The College, I decided to take the first, intro-level course for computer 
                                science as well as a few gen-eds. During this first computer science course, I learned 
                                Python as well as foundational concepts and algorithms for computer science. It did not 
                                take long for me to decide that I enjoyed programming and computer science enough to 
                                declare it as my major.
                            </p>
                        </div>
                        <div id='wrapper' style={{ margin: margin, marginBottom: 0 }}>
                            <div id='first'>
                                <p className="left" style={{textIndent: indent}}>
                                During my time as a Computer Science major, I learned many useful skills, algorithms, and 
                                languages that I still utilize today. As a freshman, I began my journey with simple concepts 
                                and algorithms as well as the basics of Python. From there I expanded my language knowledge 
                                to Java, HTML, CSS, PHP, C, and JavaScript. Additionally, I learned crucial concepts needed 
                                to create and understand a relational database 
                                </p>
                            </div>
                            <div id='second'>
                                <img src={harbor} alt='Harbor.jpg' align='center' style={{ padding: '1px', width: w2, height: h2}} />
                            </div>
                        </div>
                        <div style={{margin: '0em 3em'}}>
                            <p className="left" style={{marginTop: "-1em"}}>
                                as well as SQL and MariaDB. Also included in my required coursework was a course on assembly language 
                                and hardware organization. I learned the machine language MIPS as well as expanded my understanding of 
                                C and binary. The method we spent the most time on in this course was translating C to MIPS and then 
                                MIPS to binary. We also learned about logic gates and other components of hardware that allow a computer 
                                to function. This was not an easy course.
                            </p>
                            <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                                Initially, when I declared my major, I was pursuing a Bachelor of Science in Computer Science. However, 
                                due to unforeseen circumstances brought about by the COVID-19 Pandemic, I fell slightly behind on my 
                                required courses. After a large amount of thought as well as consultations with advisors and industry 
                                professionals, I decided to change my Computer Science degree from a Bachelor of Science to a Bachelor 
                                of Arts as well as take an additional semester. This way, I had more time to make professional connections 
                                as well as take my time to <font style={{fontStyle: "italic"}}>really</font> &nbsp;learn the material I would be 
                                given instead of rushing to finish everything within the typical four year time frame. Ultimately, this was 
                                the best decision for my academic career. 
                            </p>
                        </div>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first'>
                                <a href='http://www.chucktownsound.com/'>
                                    <img src={cts} alt='CTSLogo.png' align='center' style={{ padding: '1px', width: cw, height: ch}} />
                                </a>
    
                            </div>
                            <div id='second'>
                                <p className="left" style={{textIndent: indent}}>
                                    In addition to my computer science classes, I was also very involved with the College of Charleston Music 
                                    Department. Throughout all nine of my semesters, I was a member of the CofC Orchestra, Wind Ensemble, and 
                                    the College’s Athletic Band: Chucktown Sound. My primary instrument is the tuba. However, as a member of 
                                    the orchestra, I swapped between playing the bass trombone and the tuba. For most of my time with the 
                                    orchestra, I played bass trombone. This is because
                                </p>
                            </div>
                        </div>
                        <div style={{margin: margin}}>
                            <p className="left" style={{marginBottom: 0, marginTop: "-1.75em"}}>
                                when I first joined the orchestra, there was already a tubist. However, following his graduation, I became 
                                the orchestra’s principal tubist. Furthermore, on pieces that did not have a tuba part, I played bass 
                                trombone instead.
                            </p>
                            <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                                As a member of the CofC Wind Ensemble and Chucktown Sound, I played tuba/sousaphone. Additionally, in Chucktown 
                                Sound, I was also the tuba section leader as well as the band’s Social Media Manager and Webmaster. I created 
                                and managed the band’s Twitter, Instagram, and Facebook accounts as well as recruiting marketing. I also built 
                                and maintained the band’s website which was initially designed with Weebly in 2019. In 2022, I redesigned and 
                                rebuilt the website from scratch using React and Firebase. See the <Link to='/projects/chucktown_sound'>Chucktown 
                                Sound Project</Link> page for more information. Throughout my time at the College of Charleston, I also intended 
                                to complete the music minor. However, due to scheduling conflicts in my final semester, I was unable to complete 
                                all of the required courses for the music minor. Despite the fact that I did not complete the minor, I did learn 
                                a large amount of valuable information that I continue to use in my current musical endeavors. 
                            </p>
                        </div>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first' style={{ marginTop: '1em' }}>
                                <a href='https://compsci.cofc.edu/'>
                                    <img src={cs} alt='CS.jpg' align='center' style={{ padding: '1px', width: sq, height: sq}} />
                                </a>
                            </div>
                            <div id='second'>
                                <p className="left" style={{textIndent: indent}}>
                                    Overall, my time at the College of Charleston was very positive. I made numerous professional and personal 
                                    connections while also creating some amazing memories. I’ve developed so many new, useful skills as well as 
                                    improved existing skills. I’ve completed a wide variety of projects that I can showcase and be proud of. I 
                                    would like to all the people who have helped me troubleshoot my code, taught me new skills, and mentored me 
                                    along the way. Overall, I am very thankful for my four-and-a-half year of education at the College of Charleston. 
                                </p>
                            </div>
                        </div>
                    </>
                    
                )
            }
        }

        if (this.state.large) { 
            padBot = '100px'; 
            margin = '40px 400px';
            hr = '40px 400px';
            w = 600;
            h = 400;
            w2 = 500;
            h2 = 333;
            al1 = 'center';
            al2 = 'center';

            About = () => {
                return (
                    <>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first'>
                                <a href='https://cofc.edu/'>
                                    <img src={cofcLogo} alt='Logo.png' style={{padding: '1px 0', width: logoW, height: logoH}} align='center' />
                                </a>
                            </div>
                            <div id='second'>
                                <p className="left" style={{textIndent: indent, marginBottom: 0}}>
                                Located in the heart of historic Charleston, South Carolina, the College of Charleston is a nationally recognized, public 
                                liberal arts and sciences university. Founded in 1770, the College is among the nation’s top universities for quality 
                                education, student life and affordability. Its beautiful and historic campus, combined with <a href={facilities}>contemporary facilities</a>,&nbsp;
                                <a href={programs}>cutting-edge programs</a> and accessible faculty attracts students from across the U.S. and around the world.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                            Over 10,000 undergraduates and approximately 1,000 graduate students at the College enjoy a small-college feel blended with the 
                            advantages and diversity of a mid-sized, urban university. They work closely with a committed faculty, made up of more than 800 
                            distinguished teacher-scholars. And the city of Charleston – world-renowned for its history, architecture, culture and coastal 
                            environment – serves as a living and learning laboratory for experiences in business, science, technology, teaching, the humanities, 
                            languages, government and the arts. -Courtesy of <a href='https://cofc.edu/about/index.php'>College of Charleston</a>
                            </p>
                        </div>
                    </>
                )
            };

            Exp = () => {
                return (
                    <>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                I began my education at The College of Charleston in August 2018 as an undeclared major. 
                                I had always had an interest in engineering and aviation, so in high school my plan for 
                                college was to major in mechanical or aerospace engineering. However, during my junior 
                                year of high school, I decided that that was not the career path that I wanted to take. 
                                I have also always been interested in all things related to computers. So, in my first 
                                semester at The College, I decided to take the first, intro-level course for computer 
                                science as well as a few gen-eds. During this first computer science course, I learned 
                                Python as well as foundational concepts and algorithms for computer science. It did not 
                                take long for me to decide that I enjoyed programming and computer science enough to 
                                declare it as my major.
                            </p>
                        </div>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first'>
                                <p className="left" style={{textIndent: indent}}>
                                During my time as a Computer Science major, I learned many useful skills, algorithms, and 
                                languages that I still utilize today. As a freshman, I began my journey with simple concepts 
                                and algorithms as well as the basics of Python. From there I expanded my language knowledge 
                                to Java, HTML, CSS, PHP, C, and JavaScript. Additionally, I learned crucial concepts needed 
                                to create and understand a relational database as well as SQL and MariaDB. Also included in my 
                                required coursework was a course on assembly language and hardware organization. I learned the 
                                machine language MIPS as well as expanded my understanding of C and binary. The method we spent 
                                the most time on in this course was translating C to MIPS and then MIPS to binary. We also 
                                learned about logic gates and other components of hardware that allow a computer to function. 
                                This was not an easy course.
                                </p>
                            </div>
                            <div id='second'>
                                <img src={harbor} alt='Harbor.jpg' align='center' style={{ padding: '1px', width: w2, height: h2}} />
                            </div>
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                Initially, when I declared my major, I was pursuing a Bachelor of Science in Computer Science. However, 
                                due to unforeseen circumstances brought about by the COVID-19 Pandemic, I fell slightly behind on my 
                                required courses. After a large amount of thought as well as consultations with advisors and industry 
                                professionals, I decided to change my Computer Science degree from a Bachelor of Science to a Bachelor 
                                of Arts as well as take an additional semester. This way, I had more time to make professional connections 
                                as well as take my time to <font style={{fontStyle: "italic"}}>really</font> &nbsp;learn the material I would be 
                                given instead of rushing to finish everything within the typical four year time frame. Ultimately, this was 
                                the best decision for my academic career. 
                            </p>
                        </div>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first'>
                                <a href='http://www.chucktownsound.com/'>
                                    <img src={cts} alt='CTSLogo.png' align='center' style={{ padding: '1px', width: cw, height: ch}} />
                                </a>
    
                            </div>
                            <div id='second'>
                                <p className="left" style={{textIndent: indent}}>
                                    In addition to my computer science classes, I was also very involved with the College of Charleston Music 
                                    Department. Throughout all nine of my semesters, I was a member of the CofC Orchestra, Wind Ensemble, and 
                                    the College’s Athletic Band: Chucktown Sound. My primary instrument is the tuba. However, as a member of 
                                    the orchestra, I swapped between playing the bass trombone and the tuba. For most of my time with the 
                                    orchestra, I played bass trombone. This is because when I first joined the orchestra, there was already a 
                                    tubist. However, following his graduation, I became the orchestra’s principal tubist. Furthermore, on pieces 
                                    that did not have a tuba part, I played bass trombone instead.
                                </p>
                            </div>
                        </div>
                        <div>
                            <p className="left" style={{textIndent: indent, marginBottom: 0, margin: margin}}>
                                As a member of the CofC Wind Ensemble and Chucktown Sound, I played tuba/sousaphone. Additionally, in Chucktown 
                                Sound, I was also the tuba section leader as well as the band’s Social Media Manager and Webmaster. I created 
                                and managed the band’s Twitter, Instagram, and Facebook accounts as well as recruiting marketing. I also built 
                                and maintained the band’s website which was initially designed with Weebly in 2019. In 2022, I redesigned and 
                                rebuilt the website from scratch using React and Firebase. See the <Link to='/projects/chucktown_sound'>Chucktown 
                                Sound Project</Link> page for more information. Throughout my time at the College of Charleston, I also intended 
                                to complete the music minor. However, due to scheduling conflicts in my final semester, I was unable to complete 
                                all of the required courses for the music minor. Despite the fact that I did not complete the minor, I did learn 
                                a large amount of valuable information that I continue to use in my current musical endeavors. 
                            </p>
                        </div>
                        <div id='wrapper' style={{ margin: margin }}>
                            <div id='first' style={{ marginTop: '1em' }}>
                                <a href='https://compsci.cofc.edu/'>
                                    <img src={cs} alt='CS.jpg' align='center' style={{ padding: '1px', width: sq, height: sq}} />
                                </a>
                            </div>
                            <div id='second'>
                                <p className="left" style={{textIndent: indent}}>
                                    Overall, my time at the College of Charleston was very positive. I made numerous professional and personal 
                                    connections while also creating some amazing memories. I’ve developed so many new, useful skills as well as 
                                    improved existing skills. I’ve completed a wide variety of projects that I can showcase and be proud of. I 
                                    would like to all the people who have helped me troubleshoot my code, taught me new skills, and mentored me 
                                    along the way. Overall, I am very thankful for my four-and-a-half year of education at the College of Charleston. 
                                </p>
                            </div>
                        </div>
                    </>
                    
                )
            }
        }

        return (
            <main style={{ marginTop: top, backgroundImage: `url(${cistern})`, backgroundRepeat: repeat, backgroundPosition: 'center', 
                backgroundSize: backSize, height: 300, width: 'auto'}}>
                <div id='top' style={{overflow: 'auto', paddingBottom: padBot}}>
                    <Title />

                    <hr style={{margin: hr, marginBottom: '10px'}} />
                    <h3>About College of Charleston</h3>
                    <hr style={{margin: hr, marginBottom: '10px'}} />
                    <About />
                    <Photos />

                    <br />

                    <hr style={{margin: hr, marginBottom: '10px'}} />
                    <h3>My Experiences</h3>
                    <hr style={{margin: hr, marginBottom: '10px'}} />
                    <Exp />

                    <br />

                    {!this.state.large && <Link to='/resume/view_resume'>
                        <GeneralButton>Back</GeneralButton>
                    </Link>}
                    {!this.state.large && <Link to='/home'>
                        <GeneralButton>Home</GeneralButton>
                    </Link>}

                    <ScrollButton />

                </div>
            </main>
        )
    }
};


// Determines Page
function page(id) {

    if (id === "E0") { return HS }

    else if (id === "E1") { return CofC }

    else { return UnderConst }
}

// Export Page
export const EDetails = () => {
    const { id } = useParams();
    let Page = page(id);
    useEffect(() => {window.scrollTo(0, 0)}, []);
    return (
        <Page />
    )
}