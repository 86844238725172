import React, { Component } from 'react';
import '../Styles/UnderConstruction.css'
import { Link } from 'react-router-dom';
import { GeneralButton } from '../Components/Buttons';
import { Helmet } from 'react-helmet';
import logo from '../Images/UnderConstruction.png';

class Image extends Component {
    constructor(props) {
        super(props)
        this.state = { 
          small: window.matchMedia("screen and (max-device-width: 640px)").matches,
          medium: window.matchMedia("screen and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
          large: window.matchMedia("screen and (min-device-width: 1601px)").matches
        };
    }

    componentDidMount() {
        const handler = e => this.setState({small: e.matches});
        const handler2 = e => this.setState({medium: e.matches});
        const handler3 = e => this.setState({large: e.matches});
        window.matchMedia("screen and (max-device-width: 640px)").addEventListener('change', handler);
        window.matchMedia("screen and (min-device-width: 641px) and (max-device-width: 1600px)").addEventListener('change', handler2);
        window.matchMedia("screen and (min-device-width: 1601px)").addEventListener('change', handler3);
    }

    render() {
        let h;
        let w;
        if(this.state.small) {h = '80%'; w = '80%';}
        if(this.state.medium) {h = 398; w = 586;}
        if(this.state.large) {h = 443; w = 651;}
        return (
        <div >
            <img src={logo} style={{height: h, width: w}} alt='UnderConstruction.png' />
        </div>
        );
    }
}

export const UnderConst = () => {
    return (
      <main>
        <div style={{paddingBottom: 30}}>
            <Helmet>
                <title>Under Construction</title>
            </Helmet>
            <Image />
            <h1>Sorry!</h1>
            <p>This page is currently under construction. Check back soon!</p>
            <Link to="/home">
                <GeneralButton>Home</GeneralButton>
            </Link>
        </div>

      </main>
      
    );
  };