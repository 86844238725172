import React, { useState, ChangeEvent, Component } from "react";
import { createUserWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
//import ReCAPTCHA from 'react-google-recaptcha';
import { Alert, Box, Card, CardActions, CardContent, CardHeader, TextField, InputAdornment } from '@mui/material';
import { GetAuth } from "../Components/FirebaseConfig/Index";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as Io5Icons from "react-icons/io5";
import * as FaIcons from "react-icons/fa";
import { Link } from "react-router-dom";
import { FormSubmitButton } from "../Components/Buttons";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const auth = GetAuth();
let errMsg = false;

class Dash extends Component {
  render() {
      return (
          <Navigate to='/dashboard' />
      )
  }
}

const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
    large: window.matchMedia("all and (min-device-width: 1601px)").matches,
    tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches
};
  
function getWidth() {
    let width;
    if (screens.small) {width = 250}
    if (screens.medium) {width = 400}
    if (screens.large) { width = 500}
    if (screens.tablet) {width = 350}
    return width;
}

const theme = createTheme({
    typography: {
        fontFamily: [
        "Oswald"
        ].join()
    }
});

type FormState = {
    email: string;
    password: string;
    conf: string;
};

type ServiceMessage = {
    error: boolean;
    text: string;
};

type SuccessMessage = {
  success: boolean;
  text: string;
};
  
function Create() {
  /*
  const recaptchaKey = '6Le1V2IgAAAAADmB6XTL_pDqfja3jm5qGJUrvXRt';
  const recaptchaRef = useRef<any>();
  */
  
  const initialFormState = {
    email: "",
    password: "",
    conf: ""
  };

  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [message, setMessage] = useState<ServiceMessage>();
  const [messageSuc, setMessageSuc] = useState<SuccessMessage>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  /*
  const [recaptchaToken, setReCaptchaToken] = useState<string>();
  */
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");

  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) { setLoggedIn(true); } 
    else { setLoggedIn(false); }
  });

  const register = async () => {
    try {
      setSubmitting(true);
      const newUser = await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      console.log(newUser);
      setSubmitting(false);
      setMessageSuc({
        success: true,
        text: "User '" + registerEmail + "' created successfully."
      });
      setFormState(initialFormState);
      } catch (error) {
          console.log(error.message);
          setSubmitting(false)
          setMessageSuc({
              success: false,
              text: "Sorry! There was an error. Please refresh the page & try again. ",
            });
      }
  };

  const updateFormControlEmail = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    setFormState(updatedFormState);
    setRegisterEmail(value);
  };

  const updateFormControlPassword = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    setFormState(updatedFormState);
    compare(value);
    setRegisterPassword(value);
  };
  
  /*
  const updateRecaptchaToken = (token: string | null) => {
    setReCaptchaToken(token as string);
  };
  */

  function compare(input) {
      if (formState.password !== input) { 
          setMessage({
              error: true,
              text: "Passwords do not match."
          });
          errMsg = true;
      }
      else if (formState.password === input) {
          errMsg = false;
      }
  }

  const title = <><Io5Icons.IoCreateOutline /> Create Account</> ;

  const [isSelected1, setIsSelected1] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);
  const [isSelected3, setIsSelected3] = useState(false);

  const emailAdornment = isSelected1
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <IoIcons.IoIosMail style={{fontSize: "110%"}}/>
          </InputAdornment>
        )
      }
    : {};

    const passAdornment = isSelected2
    ? {
        startAdornment: (
          <InputAdornment position="start">
          <FaIcons.FaKey />
          </InputAdornment>
        )
      }
    : {};

    const confAdornment = isSelected3
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <AiIcons.AiOutlineCheckCircle />
          </InputAdornment>
        )
      }
    : {};

  return (
    <main>
      <div id='top' style={{justifyContent: 'center', paddingBottom: 40}}>
        <Helmet>
          <title>Alex Bailey Resume | Create Account</title>
        </Helmet>
        <Box sx={{ display: 'inline-block', margin: '0 1rem'}}>
          <ThemeProvider theme={theme}>
            <Card sx={{ minWidth: 150, backgroundColor: "#e3e3e3", fontFamily: 'Oswald' }}>
              <CardHeader title={title} />

              {errMsg && (
                  <div>
                      <Alert style={{margin: "0 1rem"}} severity="error">{message.text}</Alert>
                  </div>
              )}

              {messageSuc && (
                  <div>
                      <Alert style={{margin: "0 1rem"}} severity={messageSuc.success ? "success" : "error"}>{messageSuc.text}</Alert>
                  </div>
              )}
              
              <CardContent> 

              {/* Email */}
              <div className="padded">
                  <TextField 
                  required
                  id="email" 
                  label="Email"
                  variant="outlined"
                  type="email"
                  onChange={updateFormControlEmail}
                  value={formState?.email} 
                  sx={{width: getWidth()}}
                  InputProps={emailAdornment}
                  onFocus={e => setIsSelected1(true)}
                  onBlur={formState.email ? undefined : e => setIsSelected1(false)}
                  onInput={e => setIsSelected1(true)}
                  />
              </div>

              {/* Password */}
              <div className="padded">
                  <TextField 
                  required
                  error={errMsg}
                  id="password" 
                  label="Password" 
                  variant="outlined"
                  type="password"
                  onChange={updateFormControlPassword}
                  value={formState?.password} 
                  sx={{width: getWidth()}}
                  InputProps={passAdornment}
                  onFocus={e => setIsSelected2(true)}
                  onBlur={formState.password ? undefined : e => setIsSelected2(false)}
                  onInput={e => setIsSelected2(true)}
                  />
              </div> 

              {/* Confirm */}
              <div className="padded">
                  <TextField 
                  required
                  error={errMsg}
                  id="conf" 
                  label="Confirm Password" 
                  variant="outlined"
                  type="password"
                  onChange={updateFormControlPassword}
                  value={formState?.conf} 
                  sx={{width: getWidth()}}
                  InputProps={confAdornment}
                  onFocus={e => setIsSelected3(true)}
                  onBlur={formState.conf ? undefined : e => setIsSelected3(false)}
                  onInput={e => setIsSelected3(true)}
                  />
              </div> 

              {/* ReCAPTCHA */}
              {/*
              <div className="padded" style={{width: getWidth(), justifyContent: 'center'}}>
                  <ReCAPTCHA
                      style={{transform: 'scale(0.77)', webkitTransform: 'scale(0.77)', transformOrigin: '0 0', webkitTransformOrigin: '0 0'}}
                      ref={recaptchaRef}
                      sitekey={recaptchaKey}
                      onChange={updateRecaptchaToken}
                  />
              </div>
              */}
              </CardContent>

              <CardActions sx={{margin: '1em 1em', justifyContent: 'center'}}>
              <FormSubmitButton onClick={register} disabled={submitting} style={{marginTop: "-30px"}}>
                  {submitting ? "Creating Account..." : "Create"}
              </FormSubmitButton>
              </CardActions> 
              <CardActions sx={{margin: '0 1em', justifyContent: 'right'}}>
                <Link to='/login'>Log In To Existing Account</Link>
              </CardActions>      
            </Card>
          </ThemeProvider>
        </Box>

        {loggedIn && <Dash />}

      </div>
    </main>
  );
}

export default Create;