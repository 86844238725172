import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import StartFire from '../Components/FirebaseConfig/Index';
import { ref, onValue } from 'firebase/database';
import { ViewButton } from '../Components/Buttons';
import { Helmet } from 'react-helmet';
import image from '../Images/Backgrounds/1.jpg';
import logo from '../Images/MainLogoBlue.png';
import ScrollButton from "../Components/ScrollButton";
import '../Styles/Resume.css';

var months = [
  "January", 
  "February", 
  "March",
  "April", 
  "May", 
  "June",
  "July", 
  "August", 
  "September",
  "October", 
  "November", 
  "December"
];

const db = StartFire();

// Display Job Table
class DisplayJobTable extends Component {

  constructor(props) {
      super(props)
      this.state = {
          tableData: []
      }
  }

  componentDidMount() {
    const dbRef = ref(db, 'Jobs');

    onValue(dbRef, (snapshot)=>{
      let records = [];
      snapshot.forEach(childSnapshot => {
        let keyName = childSnapshot.key;
        let data = childSnapshot.val();
        records.push({"key": keyName, "data": data});
      });
      this.setState({tableData: records});
    });
  }

  render() {
    let tb_body = this.state.tableData.map((row, index) => {

      // Convert start date format
      let startDate = row.data.StartDate;
      let start;
      if (startDate === "") { start = ""; }
      else {
        let startYear = startDate.substring(0, 4);
        let startMon = startDate.substring(5,7);
        let startStr = months[Math.floor(startMon) - 1];
        start = startStr + " " + startYear;
      }

      // Convert end date format
      let endDate = row.data.EndDate;
      let end;
      if (endDate === "") { end = ""; }
      else {
        let endYear = endDate.substring(0, 4);
        let endMon = endDate.substring(5,7);
        let endStr = months[Math.floor(endMon) - 1];
        end = endStr + " " + endYear;
      }
      

      return (
        <tr key={index}>
          <td>{row.data.Title}</td>
          <td>{row.data.Company}</td>
          <td>{start ? start : "N/A"}</td>
          <td>{end ? end : "Present"}</td>
          <td>
            <Link to={"/resume/view_resume/jobs/" + row.key}>
              <ViewButton>Details</ViewButton>
            </Link>
          </td>
        </tr>
      )
    })
    return (
      <table>
        <thead>
          <tr>
            <th>Job Title</th>
            <th>Company</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tb_body}
        </tbody>
      </table>
    )
  }
};

// Display Education Table
class DisplayEdTable extends Component {

  constructor(props){
      super(props)
      this.state = {
          tableData: []
      }
  }

  componentDidMount() {
    const dbRef = ref(db, 'Education');

    onValue(dbRef, (snapshot)=>{
      let records = [];
      snapshot.forEach(childSnapshot => {
        let keyName = childSnapshot.key;
        let data = childSnapshot.val();
        records.push({"key": keyName, "data": data});
      });
      this.setState({tableData: records});
    });
  }

  render() {
    let tb_body = this.state.tableData.map((row, index) => {
      return (
        <tr key={index}>
          <td>{row.data.Institution}</td>
          <td>{row.data.Type}</td>
          <td>{row.data.Major ? row.data.Major : "N/A"}</td>
          <td>{row.data.Graduation}</td>
          <td>{row.data.Status ? "Completed" : "In Progress"}</td>
          <td>
            <Link to={"/resume/view_resume/education/" + row.key}>
              <ViewButton>Details</ViewButton>
            </Link>
          </td>
        </tr>
      )
    })
    return (
      <table>
        <thead>
          <tr>
            <th>Instituion</th>
            <th>Type</th>
            <th>Major</th>
            <th>Graduation Year</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tb_body}
        </tbody>
      </table>
    )
  }
};

class ResumePage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      small: window.matchMedia("all and (max-device-width: 640px)").matches,
      tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").matches,
      medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").matches,
      large: window.matchMedia("all and (min-device-width: 1601px)").matches
    }
  }

  componentDidMount() {
    const handler = e => this.setState({small: e.matches});
    const handler2 = e => this.setState({medium: e.matches});
    const handler3 = e => this.setState({large: e.matches});
    const handler4 = e => this.setState({tablet: e.matches});
    window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
    window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").addEventListener('change', handler2);
    window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
    window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").addEventListener('change', handler4);
  }

  render() {
    let margin;
    let size = '100%';
    let height = 400;
    let logoDim = 300;
    let logoTop = -20;
    let top = '';
    let contTop = '3em';
    let size2 = '100%'
    let positionY;
    let backSize;

    if (this.state.small) { 
      margin = '0 1em'; 
      logoDim = 150;
      size = '85%'; 
      size2 = '65%';
      logoTop = -10;
      top = '60px'; 
      height = 200;
      backSize = '700px 500px';
      positionY = '-80px';
    }
    if (this.state.tablet) { margin = '0 3em'; backSize = '1100px 900px'; positionY = '-200px'; }
    if (this.state.medium) { margin = '0 10em'; backSize = '1650px 1500px'; positionY = '-500px'; }
    if (this.state.large) { margin = '0 10em'; backSize = '2600px 1750px'; positionY = '-500px'; height = 550; }

    return (
      <main style={{marginTop: top, padding: '0 0 0'}}>
        <section style={{ backgroundImage: `url(${image})`, backgroundRepeat: 'no-repeat', 
          backgroundSize: backSize, backgroundPositionX: 'center', backgroundPositionY: positionY, height: height, width: 'auto'}}>

          <Helmet>
              <title>Alex Bailey Resume | Resume</title>
          </Helmet>

          <div class="container" style={{paddingTop: contTop}}>
            <div class="box">
              <img src={logo} alt='LogoMainBlue.png' style={{width: logoDim, height: logoDim}} />
              <h3 style={{marginTop: logoTop, fontSize: size2}}>Charleston, SC 29401</h3>
            </div>
          </div>
        </section>

        <section style={{margin: margin}}>
          <p style={{margin: '1em 2em'}}>
            Graduated from College of Charleston in 2022 with a Bachelor's of Arts in Computer Science. 
            Following experiences with companies such as Booz Allen Hamilton and Capgemini as well as being an 
            Eagle Scout, I have developed a strong work ethic, communication, critical-thinking and analysis 
            skills.
          </p>

          <br />
          <br />

          <h1>JOB EXPERIENCE</h1>
          <div style={{overflow: 'auto', padding: '0 1em'}}>
            <DisplayJobTable />
          </div>

          <br />
          <br />
          <br />

          <h1>EDUCATION</h1>
          <div style={{overflow: 'auto', padding: '0 1em'}}>
            <DisplayEdTable />
          </div>

          <br />
          <br />
          <br />

          <h1>Skills</h1>
          <p id='left' style={{margin: margin, fontSize: size}}>
              <ul>
                  <li>Computer Programming</li>
                  <ul style={{marginTop: '-.05em'}}>
                    <li>Proficient in Java, JavaScript, Python, and C</li>
                    <li>Experience in Android App Development</li>
                    <li>Experience in Web Development/Database Server Communication (PHP, React, Go, SQL, MariaDB)</li>
                  </ul>
                  <li style={{marginTop: '.25em'}}>Strong communication/public speaking skills</li>
                  <li style={{marginTop: '.25em'}}>Leadership</li>
                  <li style={{marginTop: '.25em'}}>Strong work ethic</li>
                  <li style={{marginTop: '.25em'}}>Practiced in Scrum Development Process</li>
              </ul>
          </p>

          <h1>Activities</h1>
          <p id='left' style={{margin: margin, fontSize: size}}>
              <details>
                <summary>Member of Boy Scouts of America (2009-2019)</summary>
                <ul>
                  <li style={{marginTop: '-.75em'}}>Eagle Scout (2017)</li>
                  <li>Eagle Scout Service Project: built 4 twin size beds for Charleston Halos (2017)</li>
                  <li>Assistant Scout Master (2019)</li>
                </ul>
              </details>

              <details style={{marginTop: '.25em'}}>
                <summary>Adult Black Belt in American Karate and Taekwondo (2014)</summary>
                <ul>
                  <li style={{marginTop: '-.75em'}}>Low Country Karate</li>
                </ul>
              </details>

              <details style={{marginTop: '.25em'}}>
                <summary>Practiced Musician: Tuba, Bass Guitar, and Bass Trombone</summary>
                <details>
                  <summary>College</summary>
                  <ul style={{marginTop: '-.05em'}}>
                    <li>College of Charleston Athletic Band: Chucktown Sound (2018-Present)</li>
                    <ul style={{marginTop: '-.05em'}}>
                      <li>Tuba Section Leader (2020-Present)</li>
                    </ul>
                    <li style={{marginTop: '.25em'}}>College of Charleston Orchestra (2018-Present)</li>
                    <ul style={{marginTop: '-.05em'}}>
                      <li>Principal Tubist (2020-2021)</li>
                      <li>Bass Trombonist (2018-2019 & 2022-Present)</li>
                    </ul>
                    <li style={{marginTop: '.25em'}}>College of Charleston Wind Ensemble (2018-Present)</li>
                  </ul>
                </details>

                <details style={{marginTop: '.25em'}}>
                  <summary>High School</summary>
                  <ul style={{marginTop: '-.05em'}}>
                    <li>Marching Band (2014-2017)</li>
                    <ul style={{marginTop: '-.05em'}}>
                      <li>Tuba Section Leader (2016 & 2017)</li>
                    </ul>
                    <li style={{marginTop: '.25em'}}>Symphonic Band (2016-2018)</li>
                    <li style={{marginTop: '.25em'}}>Concert Band I (2015-2016)</li>
                    <li style={{marginTop: '.25em'}}>Concert Band II (2014-2015)</li>
                    <li style={{marginTop: '.25em'}}>Wando Jazz Band (2016-2018)</li>
                  </ul>
                </details>
              </details>
          </p>

          <h1>Leadership Experience</h1>
          <p id='left' style={{margin: margin, fontSize: size}}>
            <details>
              <summary>R.I.S.E Leadership Program - Capgemini</summary>
              <ul>
                <li style={{marginTop: '-.75em'}}><font style={{color: '#1d824b', fontWeight: 'bold'}}>Collaborated</font> with 
                  7 team members and consultants to problem-solve a technical statement 
                  <font style={{color: 'black', fontWeight: 'bold'}}> to present a solution to panel of 5 judges.</font></li>
                <li><font style={{color: '#1d824b', fontWeight: 'bold'}}>Demonstrated</font> leadership by 
                  providing business strategy and structure <font style={{color: 'black', fontWeight: 'bold'}}>to execute 
                  mobile app design.</font></li>
                <li><font style={{color: '#1d824b', fontWeight: 'bold'}}>Researched</font> useful information 
                  <font style={{color: 'black', fontWeight: 'bold'}}> to make educated decisions.</font></li>
              </ul>
            </details>

            <details style={{marginTop: '.25em'}}>
              <summary>Tuba Section Leader – Wando High School (2016-2018) & Chucktown Sound (2020-Present)</summary>
              <ul>
                <li style={{marginTop: '-.75em'}}><font style={{color: '#1d824b', fontWeight: 'bold'}}>Instructed</font> other
                  section members<font style={{color: 'black', fontWeight: 'bold'}}> to improve musical skills and technical skills.</font></li>
                <li><font style={{color: '#1d824b', fontWeight: 'bold'}}>Demonstrated</font> leadership by setting the example for 
                  etiquette and technique<font style={{color: 'black', fontWeight: 'bold'}}> to help guide the section.</font></li>
                <li><font style={{color: '#1d824b', fontWeight: 'bold'}}>Innovated</font> new systems <font style={{color: 'black', 
                  fontWeight: 'bold'}}> to aid in learning processes and improve communication.</font></li>
              </ul>
            </details>

            <details style={{marginTop: '.25em'}}>
              <summary>Eagle Scout, Order of the Arrow, Senior Patrol Leader, Patrol Leader, Webmaster – BSA Troop 11</summary>
              <ul>
                <li style={{marginTop: '-.75em'}}><font style={{color: '#1d824b', fontWeight: 'bold'}}>Organized</font> weekly troop meetings 
                  and monthly camping events<font style={{color: 'black', fontWeight: 'bold'}}> to ensure troop activities run smoothly.</font></li>
                <li><font style={{color: '#1d824b', fontWeight: 'bold'}}>Educated</font> other scouts on various topics <font style={{color: 'black', 
                  fontWeight: 'bold'}}> to improve the skills of my fellow scouts.</font></li>
                <li><font style={{color: '#1d824b', fontWeight: 'bold'}}>Assisted </font> my community through service projects such as my Eagle 
                  Scout Project<font style={{color: 'black', fontWeight: 'bold'}}> to improve the lives of those in need.</font></li>
              </ul>
            </details>
          </p>

          <ScrollButton />
          
        </section>
      </main>
    )
  }
}

export const Resume = () => {
  useEffect(() => {window.scrollTo(0, 0)}, []);
  return (
    <ResumePage />
  );
};