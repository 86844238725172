import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import image from '../Images/Backgrounds/2.jpg';
import ScrollButton from "../Components/ScrollButton";
import { styled } from '@mui/material/styles';
import { Tooltip, tooltipClasses } from '@mui/material';
import { Helmet } from 'react-helmet';
import SimpleImageSlider from "react-simple-image-slider";
import portait from '../Images/AboutMe/Portait.jpeg';
import desk from '../Images/AboutMe/Desk.jpeg';
import working from '../Images/AboutMe/Working.jpeg';
import one from '../Images/AboutMe/1.jpg';
import two from '../Images/AboutMe/2.jpg';
import three from '../Images/AboutMe/3.jpeg';
import four from '../Images/AboutMe/4.jpeg';
import five from '../Images/AboutMe/5.jpg';
import six from '../Images/AboutMe/6.jpg';
import seven from '../Images/AboutMe/7.jpeg';
import eight from '../Images/AboutMe/8.jpeg';
import nine from '../Images/AboutMe/9.jpeg';
import ten from '../Images/AboutMe/10.jpeg';
import '../Styles/AboutMe.css';
import { GeneralButton } from '../Components/Buttons';

const city = "Charleston, South Carolina"

const CTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 150,
  },
});

function age() {
  var today = new Date();
  var birthDate = new Date("1999/09/18");
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
};

function music() {
  var today = new Date();
  var date = new Date("2011/08/01");
  var years = today.getFullYear() - date.getFullYear();
  var m = today.getMonth() - date.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    years--;
  }
  return years;
};

function chs() {
  var today = new Date();
  var date = new Date("2007/06/01");
  var years = today.getFullYear() - date.getFullYear();
  var m = today.getMonth() - date.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < date.getDate())) {
    years--;
  }
  return years;
};

const slideImages = [
  {url: one},{url: two},
  {url: three},{url: four},
  {url: five},{url: six},
  {url: seven},{url: eight},
  {url: nine},{url: ten},
];

class Slideshow extends Component {
  constructor(props) {
    super(props)
    this.state = { 
      small: window.matchMedia("all and (max-device-width: 640px)").matches,
      medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
      large: window.matchMedia("all and (min-device-width: 1601px)").matches,
      tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").matches
    };
  }

  componentDidMount() {
    const handler = e => this.setState({small: e.matches});
    const handler2 = e => this.setState({medium: e.matches});
    const handler3 = e => this.setState({large: e.matches});
    const handler4 = e => this.setState({tablet: e.matches});
    window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
    window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").addEventListener('change', handler2);
    window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
    window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").addEventListener('change', handler4);
  }

  render() {
    var h;
    var w;
    var bullets = true;
    var navMar = 30;
    if(this.state.small) {h = 200; w = 315; bullets = false; navMar = 1;}
    if(this.state.medium || this.state.tablet) {h = 375; w = 550;}
    if(this.state.large) {h = 400; w = 600;}
    return (
      <div >
        <SimpleImageSlider
          style={{ margin: '0 auto'}}
          width={w}
          height={h}
          slideDuration={.5}
          autoPlayDelay={4}
          autoPlay={true}
          images={slideImages}
          navMargin={navMar}
          showBullets={bullets}
          showNavs={true}
        />
      </div>
    );
  }
}

class AboutMePage extends Component {
    constructor(props) {
      super(props)
      this.state = {
        small: window.matchMedia("all and (max-device-width: 640px)").matches,
        tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").matches,
        medium: window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").matches,
        large: window.matchMedia("all and (min-device-width: 1601px)").matches
      }
    }
  
    componentDidMount() {
      const handler = e => this.setState({small: e.matches});
      const handler2 = e => this.setState({medium: e.matches});
      const handler3 = e => this.setState({large: e.matches});
      const handler4 = e => this.setState({tablet: e.matches});
      window.matchMedia("all and (max-device-width: 640px)").addEventListener('change', handler);
      window.matchMedia("all and (min-device-width: 1025px) and (max-device-width: 1600px)").addEventListener('change', handler2);
      window.matchMedia("all and (min-device-width: 1601px)").addEventListener('change', handler3);
      window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px)").addEventListener('change', handler4);
    }
  
    render() {
      let margin;
      let pmargin = '1.25em 2em 0 2em';
      let pmargin2 = '1.25em 4em 0 4em';
      let top = '';
      let backSize;
      let height = 400;
      let positionY;
      let indent = '30px';
      let who1 = 375;
      let who2 = 500;
      let port1 = '32%';
      let port2 = '67%';

      let Who = () => {
        return (
          <div id='wrapper' style={{margin: '1em 2em'}}>
            <div id='first'>
              <img src={desk} alt='Desk.jpeg' style={{padding: '5px', height: who1, width: who2}} align='middle' />
            </div>
            <div id='second'>
              <img src={working} alt='Working.jpeg' style={{padding: '5px', height: who1, width: who2}} align='middle' />
            </div>
          </div>
        )
      };

      let About = () => {
        return (
          <div id='wrapper' style={{margin: '1em 2em'}}>
            <div id='first' style={{width: port1}}>
              <img src={portait} alt='Portait.jpeg' style={{objectPosition: '100% 35%', objectFit: 'cover', float: 'left', width: '100%', height: '100%'}} />
            </div>
            <div id='second' style={{width: port2}}>
              <p className='left' style={{margin: pmargin, textIndent: indent}}>
                My name is Alex Bailey and I am currently a ({<CTooltip 
                  PopperProps={{ modifiers: [{ name: "offset", options: {offset: [0, -10],}, },], }}
                  title='Began Fall 2018 & graduating Fall 2022' 
                  placement='top' 
                  arrow>
                    <font style={{color: '#0C4773', textDecoration: 'underline'}}>super</font></CTooltip>}) 
                senior at the College of Charleston studying Computer Science. I am currently {age()} years old 
                and I am currently living in {city}. During my time studying Computer Science at the College of 
                Charleston, I have acquired numerous different useful skills. I’ve discovered new ways of 
                approaching difficult problems using algorithms. I’ve expanded my knowledge of programming 
                languages and IDE’s. Some examples of languages I have learned are Java, Python, C, HTML, and 
                PHP, just to name a few. I have also gained a solid understanding of website development. I am 
                well educated on the MVC (model-view-controller) Pattern as well as SQL queries and relational 
                database concepts. 
              </p>
            </div>
          </div>
        )
      }

      let Story = () => {
        return (
          <div id='wrapper' style={{margin: '1em 2em'}}>
            <div id='first' style={{marginTop: '2em'}}>
              <p className='left' style={{margin: '0 2em', textIndent: indent}}>
                I was born just outside of the capital city of Phnom Penh, Cambodia. Well, at least I think I was. 
                In Cambodia, most people do not record the exact date of their children’s birth. About three months 
                after I was born, I was adopted from an orphanage in Phnom Penh by Janet and Tom Bailey. I was then 
                made a US Citizen and given the first name Alexander. My original name, Moly, was made my middle 
                name. My first home as a baby was in Virginia where I lived with my older brother and sister. My 
                brother, who was also adopted from Cambodia, is 2 years older than me while my sister, who was not 
                adopted, is 5 years older than me. From there, the Navy moved us to Hawaii, then to California, and 
                lastly, Charleston, SC. I have been living in Charleston for the past {chs()} years.
              </p>
            </div>
            <div id='second'>
              <Slideshow />
            </div>
          </div>
        )
      }
  
      if (this.state.small) { 
        margin = '0 1em'; 
        pmargin = '1em 2.5em';
        pmargin2 = '1em 2.5em';
        backSize = '400px 350px';
        top = '60px'; 
        positionY = 'center';
        height = 200;
        indent = '10px';

        Who = () => {
          return (
            <div>
              <img src={desk} alt='Desk.jpeg' style={{padding: '1px', height: '70%', width: '70%'}} align='middle' />
              <img src={working} alt='Working.jpeg' style={{padding: '1px', height: '70%', width: '70%'}} align='middle' />
            </div>
          )
        };

        About = () => {
          return (
            <>
              <p className='left' style={{margin: pmargin, textIndent: indent}}>
                My name is Alex Bailey and I am currently a ({<CTooltip 
                  PopperProps={{ modifiers: [{ name: "offset", options: {offset: [0, -10],}, },], }}
                  title='Began Fall 2018 & graduating Fall 2022' 
                  placement='top' 
                  arrow>
                    <font style={{color: '#1d824b', textDecoration: 'underline'}}>super</font></CTooltip>}) 
                senior at the College of Charleston studying Computer Science. I am currently {age()} years old 
                and I am currently living in {city}. During my time studying Computer Science at the College of 
                Charleston, I have acquired numerous different useful skills. I’ve discovered new ways of 
                approaching difficult problems using algorithms. I’ve expanded my knowledge of programming 
                languages and IDE’s. Some examples of languages I have learned are Java, Python, C, HTML, and 
                PHP, just to name a few. I have also gained a solid understanding of website development. I am 
                well educated on the MVC (model-view-controller) Pattern as well as SQL queries and relational 
                database concepts. 
              </p>
            </>
          )
        };

        Story = () => {
          return (
            <>
              <p className='left' style={{margin: '0 2em', textIndent: indent}}>
                I was born just outside of the capital city of Phnom Penh, Cambodia. About three months 
                after I was born, I was adopted from an orphanage in Phnom Penh by Janet and Tom Bailey. I was then 
                made a US Citizen and given the first name Alexander. My original name, Moly, was made my middle 
                name. My first home as a baby was in Virginia where I lived with my older brother and sister. My 
                brother, who was also adopted from Cambodia, is 2 years older than me while my sister, who was not 
                adopted, is 5 years older than me. From there, the Navy moved us to Hawaii, then to California, and 
                lastly, Charleston, SC. I have been living in Charleston for the past {chs()} years.
              </p>
              <p className='left' style={{margin: pmargin, textIndent: indent}}>
                In December of 2018, I had the incredible opportunity to travel to Cambodia to learn about my heritage
                as well as reunite with my birth family. We organized this trip with a company called <a 
                href='https://www.adoptivefamilytravel.com/about-us'><font style={{fontStyle: 'italic'}}>
                Adoptive Family Travel </font>&nbsp;by <font style={{fontStyle: 'italic'}}>The Ties Program</font></a>. 
                We joined a few other families who had planned a heritage trip through the same agency. The trip was a 
                total of two weeks and involved over 23 hours of flight (plus layovers), each way. 
              </p>
              <Slideshow />
            </>
          )
        }
      }

      if (this.state.tablet) { 
        margin = '0 3em'; 
        backSize = '1000px 950px'; 
        positionY = '-300px'; 
        pmargin = '1em 1em';
        pmargin2 = '1em 1em';

        About = () => {
          return (
            <div id='wrapper'>
              <div id='first'>
                <img src={portait} alt='Portait.jpeg' style={{padding: '1px', height: 230, width: 315}} align='middle' />
              </div>
              <div id='second'>
                <p className='left' style={{margin: pmargin, textIndent: indent}}>
                  My name is Alex Bailey and I am currently a ({<CTooltip 
                    PopperProps={{ modifiers: [{ name: "offset", options: {offset: [0, -10],}, },], }}
                    title='Began Fall 2018 & graduating Fall 2022' 
                    placement='top' 
                    arrow>
                      <font style={{color: '#1d824b', textDecoration: 'underline'}}>super</font></CTooltip>}) 
                  senior at the College of Charleston studying Computer Science. I am currently {age()} years old 
                  and I am currently living in {city}. During my time studying Computer Science at the College of 
                  Charleston, I have acquired numerous different useful skills. I’ve discovered new ways of 
                  approaching difficult problems using algorithms. 
                </p>
              </div>

              <p className='left' style={{margin: pmargin, textIndent: indent}}>
                  I’ve expanded my knowledge of programming 
                  languages and IDE’s. Some examples of languages I have learned are Java, Python, C, HTML, and 
                  PHP, just to name a few. I have also gained a solid understanding of website development. I am 
                  well educated on the MVC (model-view-controller) Pattern as well as SQL queries and relational 
                  database concepts. 
                </p>
            </div>
          )
        };

        Who = () => {
          return (
            <div id='wrapper'>
              <div id='first'>
                <img src={desk} alt='Desk.jpeg' style={{padding: '1px', height: '95%', width: '95%'}} align='middle' />
              </div>
              <div id='second'>
                <img src={working} alt='Working.jpeg' style={{padding: '1px', height: '95%', width: '95%'}} align='middle' />
              </div>
            </div>
          )
        };

        Story = () => {
          return (
            <div style={{margin: pmargin}}>
              <p className='left' style={{margin: '0 2em', textIndent: indent}}>
                I was born just outside of the capital city of Phnom Penh, Cambodia. Well, at least I think I was. 
                In Cambodia, most people do not record the exact date of their children’s birth. About three months 
                after I was born, I was adopted from an orphanage in Phnom Penh by Janet and Tom Bailey. I was then 
                made a US Citizen and given the first name Alexander. My original name, Moly, was made my middle 
                name. My first home as a baby was in Virginia where I lived with my older brother and sister. My 
                brother, who was also adopted from Cambodia, is 2 years older than me while my sister, who was not 
                adopted, is 5 years older than me. From there, the Navy moved us to Hawaii, then to California, and 
                lastly, Charleston, SC. I have been living in Charleston for the past {chs()} years.
              </p>
              <Slideshow />
              <p className='left' style={{margin: pmargin, textIndent: indent}}>
                  In December of 2018, I had the incredible opportunity to travel to Cambodia to learn about my heritage
                  as well as reunite with my birth family. We organized this trip with a company called <a 
                  href='https://www.adoptivefamilytravel.com/about-us'><font style={{fontStyle: 'italic'}}>
                  Adoptive Family Travel </font></a>. 
                  We joined a few other families who had planned a heritage trip through the same agency. The trip was a 
                  total of two weeks and involved over 23 hours of flight (plus layovers), each way. 
              </p>
            </div>
          )
        }
      }

      if (this.state.medium) { margin = '0 10em'; backSize = '1550px 1400px'; positionY = '-600px'; }
      
      if (this.state.large) { 
        margin = '0 10em'; 
        pmargin = '1em 12em';
        pmargin2 = '1em 12em';
        backSize = '2560px 1600px'; 
        positionY = '-800px'; 
        port1 = 338;
        port2 = 450;

        Who = () => {
          return (
            <div id='wrapper' style={{margin: '1em 10em'}}>
              <div id='first'>
                <img src={desk} alt='Desk.jpeg' style={{padding: '5px', height: who1, width: who2}} align='middle' />
              </div>
              <div id='second'>
                <img src={working} alt='Working.jpeg' style={{padding: '5px', height: who1, width: who2}} align='middle' />
              </div>
            </div>
          )
        };
  
        About = () => {
          return (
            <div id='wrapper' style={{margin: '1em 10em'}}>
              <div id='first'>
                <img src={portait} alt='Portait.jpeg' style={{padding: '1px', height: port1, width: port2}} align='middle' />
              </div>
              <div id='second'>
                <p className='left' style={{margin: '1em 0', textIndent: indent }}>
                  My name is Alex Bailey and I am currently a ({<CTooltip 
                    PopperProps={{ modifiers: [{ name: "offset", options: {offset: [0, -10],}, },], }}
                    title='Began Fall 2018 & graduating Fall 2022' 
                    placement='top' 
                    arrow>
                      <font style={{color: '#1d824b', textDecoration: 'underline'}}>super</font></CTooltip>}) 
                  senior at the College of Charleston studying Computer Science. I am currently {age()} years old 
                  and I am currently living in {city}. During my time studying Computer Science at the College of 
                  Charleston, I have acquired numerous different useful skills. I’ve discovered new ways of 
                  approaching difficult problems using algorithms. I’ve expanded my knowledge of programming 
                  languages and IDE’s. Some examples of languages I have learned are Java, Python, C, HTML, and 
                  PHP, just to name a few. I have also gained a solid understanding of website development. I am 
                  well educated on the MVC (model-view-controller) Pattern as well as SQL queries and relational 
                  database concepts. 
                </p>
              </div>
              <p className='left' style={{textIndent: indent}}>
                In my capstone class, I became well practiced in the SCRUM Development Process. During this 
                class, myself and three other teammates built a case management system for Soteria Digital 
                Forensics. We used React as our front-end and PHP and SQL for our backend. During this project, 
                I was able to apply my knowledge of PHP and SQL as well as learn the plethora of methods and 
                functions contained within React. Since then, I have continued to expand my knowledge of React 
                and JavaScript. 
              </p>
            </div>
          )
        }
  
        Story = () => {
          return (
            <div id='wrapper' style={{margin: '1em 10em'}}>
              <div id='first' style={{marginTop: '3em'}}>
                <p className='left' style={{margin: '1em 2em', textIndent: indent}}>
                  I was born just outside of the capital city of Phnom Penh, Cambodia. Well, at least I think I was. 
                  In Cambodia, most people do not record the exact date of their children’s birth. About three months 
                  after I was born, I was adopted from an orphanage in Phnom Penh by Janet and Tom Bailey. I was then 
                  made a US Citizen and given the first name Alexander. My original name, Moly, was made my middle 
                  name. My first home as a baby was in Virginia where I lived with my older brother and sister. My 
                  brother, who was also adopted from Cambodia, is 2 years older than me while my sister, who was not 
                  adopted, is 5 years older than me. From there, the Navy moved us to Hawaii, then to California, and 
                  lastly, Charleston, SC. I have been living in Charleston for the past {chs()} years.
                </p>
                <p className='left' style={{margin: '1em 2em', textIndent: indent}}>
                  In December of 2018, I had the incredible opportunity to travel to Cambodia to learn about my heritage
                  as well as reunite with my birth family. We organized this trip with a company called <a 
                  href='https://www.adoptivefamilytravel.com/about-us'><font style={{fontStyle: 'italic'}}>
                  Adoptive Family Travel </font></a>. 
                  We joined a few other families who had planned a heritage trip through the same agency. The trip was a 
                  total of two weeks and involved over 23 hours of flight (plus layovers), each way. 
                </p>
              </div>
              <div id='second'>
                <Slideshow />
              </div>
            </div>
          )
        }
      }
  
      return (
        <main style={{marginTop: top, padding:'0 0 0'}}>
          <section style={{ backgroundImage: `url(${image})`, backgroundRepeat: 'no-repeat', 
            backgroundSize: backSize, backgroundPositionX: 'center', backgroundPositionY: positionY, height: height, width: 'auto'}}>
            
            <div className='abt'>
              <Helmet>
                <title>Alex Bailey Resume | About Me</title>
              </Helmet>
              <div>
                <h1 className='abtTitle'>About Me</h1>
                <h3 style={{ color: 'white', marginTop: -100 }}>Alex Bailey</h3>
              </div>
            </div>
          </section>
  
          <section style={{margin: margin}}>
            <br />
            <hr style={{margin: margin}} />
            <h1>WHO AM I?</h1>
            <hr style={{margin: margin}} />
            <br />
            {this.state.small && <img src={portait} alt='Portait.jpeg' style={{padding: '1px', height: '70%', width: '70%'}} align='middle' />}
            <About />

            {!this.state.large && <p className='left' style={{margin: pmargin, textIndent: indent}}>
              In my capstone class, I became well practiced in the SCRUM Development Process. During this 
              class, myself and three other teammates built a case management system for Soteria Digital 
              Forensics. We used React as our front-end and PHP and SQL for our backend. During this project, 
              I was able to apply my knowledge of PHP and SQL as well as learn the plethora of methods and 
              functions contained within React. Since then, I have continued to expand my knowledge of React 
              and JavaScript. 
            </p>}

            <p className='left' style={{margin: pmargin, textIndent: indent}}>
              Outside of computer programming, I have many different interests and hobbies that I enjoy. I 
              have had a passion for music since I was very young. I have been actively performing in music 
              for the past {music()} years. I currently play the tuba, bass trombone, and bass guitar and I am well 
              versed in both jazz, classical, and marching styles. I am currently a member of the Orchestra, 
              Wind Ensemble, and Chucktown Sound (Pep Band) at the College of Charleston. 
            </p>

            <p className='left' style={{margin: pmargin, textIndent: indent}}>
              I have also gained a lot of experience in leadership during both high school and college. I 
              earned my Eagle Scout in the fall of 2018 and served in various leadership positions within BSA 
              Troop 11 including Senior Patrol Leader, Patrol Leader, Webmaster, and Quartermaster. 
              Additionally, I served as Tuba Section Leader in the Wando High School marching band in both 2016 
              and 2017. In order to qualify for this position, I attended numerous leadership seminars and 
              conducted a leadership project. I continued to expand and improve my leadership skills in college 
              by serving as Tuba Section Leader for Chucktown Sound. In June of 2022, I was also promoted to a 
              Store Experience Leader (Shift Manager) at YETI Charleston. 
            </p>

            <p className='left' style={{textAlign: 'center', margin: pmargin, textIndent: indent}}>
              I have learned a great number of things in my {age()} years of life. I continue to learn new skills and 
              gain more experience everyday.
            </p>

            <Who />

            <br />
            <br />
            <br />

            <hr style={{margin: margin}} />
            <h1>MY STORY</h1>
            <hr style={{margin: margin}} />
            <br />
            <Story />

            <p className='left' style={{margin: pmargin2, textIndent: indent}}>
              In December of 2018, I had the incredible opportunity to travel to Cambodia to learn about my heritage
              as well as reunite with my birth family. We organized this trip with a company called <a 
              href='https://www.adoptivefamilytravel.com/about-us'><font style={{fontStyle: 'italic'}}>
              Adoptive Family Travel </font></a>. 
              We joined a few other families who had planned a heritage trip through the same agency. The trip was a 
              total of two weeks and involved over 23 hours of flight (plus layovers), each way. 
            </p>

            <p className='left' style={{margin: pmargin2, textIndent: indent}}>
              Upon meeting my birth mother, I was able to gain some insight on why she gave me up and how I ended up
              at an orphanage in Phnom Penh. When my mom married my dad when she was 18, which is considered a normal
              age for marriage in Cambodia. Shortly thereafter, she became pregnant with me. Before I was born my dad 
              left, leaving my mom with no financial support and very little medical support. Following my birth, not 
              only was she extremely poor, but she also was very sick and would often times pass out for extended 
              periods of time. Of course, this made taking care of me very difficult. However, my mom was actually 
              still willing raise me regardless of her health condition. She told me that she would not have ever let
              me go, even if things (somehow) got worse. But, my grandma saw things differently. She did not think 
              that my mom would be able to provide a stable life for me, so she decided to take me from my mom and 
              pass me to someone else, presumably to take care of me temporarily. 
            </p>

            <p className='left' style={{margin: pmargin2, textIndent: indent}}>
              The woman I was given to promised my mom that she would care for me until she got better. But instead, 
              the woman gave me to an orphanage, so when my mom's health returned to normal, she could not find me 
              or the woman I was given to. This lead her to believe that I was dead. About a year or so after losing
              me, my mom met my stepdad, a man from Vietnam, and fell in love with him. She married him, and had my 
              two half-sisters and one half-brother. Despite the fact that her life moved on and she made a new 
              family, she never forgot about me. She told me that she “missed me everyday”. So when she got a call 
              from Adoptive Family Travel and was told that I was still alive, she simply broke down crying. When 
              I arrived at her house and stepped out of the van to meet her, she immediately ran into my arms and 
              began to cry.
            </p>
            <p className='left' style={{margin: pmargin2, textIndent: indent}}>
              A small part of me felt like I should be mad at my grandma for essentailly stealing me from my mom. 
              But if she had not intervened, then I would not have become who I am today. Who's to say if I would
              have survived infancy at all? If she had decided to keep me, there is the possiblility that she 
              still would have met my stepdad, who then would have helped raise me. However, in most Asian cultures, 
              men do not (usually) take care of children from previous men. So, who knows. Ultimately, everything
              worked out perfectly for everyone in the end. After leaving Cambodia, I was able to stay connected to
              my mom and the entire family via Facebook Messenger. We continue to communicate regularly via translated 
              text and video chat!
            </p>
          </section>
          <ScrollButton />
          <div style={{paddingBottom: '5em'}}>
            <Link to="/home">
              <GeneralButton>Home</GeneralButton>
            </Link>
          </div>
        </main>
      )
    }
  }
  
  export const AboutMe = () => {
    useEffect(() => {window.scrollTo(0, 0)}, []);
    return (
      <AboutMePage />
    );
  };