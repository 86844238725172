import React from 'react';
import { Navigate } from 'react-router-dom';

class Land extends React.Component {
    render() {
        return (
            <Navigate to='/home' />
        )
    }
}

export const LandingPage = () => {
    return (
        <Land />
    )
}