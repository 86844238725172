import React, { useState, ChangeEvent, Component } from "react";
import { signInWithEmailAndPassword, onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Alert, Box, Card, CardActions, CardContent, CardHeader, TextField, InputAdornment } from '@mui/material';
import { GetAuth } from "../Components/FirebaseConfig/Index";
import * as IoIcons5 from "react-icons/io5";
import * as IoIcons from "react-icons/io";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import { Link } from "react-router-dom";
import { FormSubmitButton } from "../Components/Buttons";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import '../Styles/Login.scss';

const auth = GetAuth();
let errMsg = false;

class Dash extends Component {
  render() {
      return (
          <Navigate to='/dashboard' />
      )
  }
}

const screens = {
    small: window.matchMedia("all and (max-device-width: 640px)").matches,
    medium: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1600px)").matches,
    large: window.matchMedia("all and (min-device-width: 1601px)").matches,
    tablet: window.matchMedia("all and (min-device-width: 641px) and (max-device-width: 1024px) and (orientation: portrait)").matches
};
  
function getWidth() {
    let width;
    if (screens.small) {width = 250}
    if (screens.medium) {width = 400}
    if (screens.large) { width = 500}
    if (screens.tablet) {width = 350}
    return width;
}

const theme = createTheme({
    typography: {
        fontFamily: [
        "Avenir"
        ].join()
    }
});

type FormState = {
    email: string;
    password: string;
};

type ServiceMessage = {
    error: boolean;
    text: string;
};
  
function Login() {

  const initialFormState = {
    email: "",
    password: ""
  };

  const [formState, setFormState] = useState<FormState>(initialFormState);
  const [message, setMessage] = useState<ServiceMessage>();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) { setLoggedIn(true); } 
    else { setLoggedIn(false); }
  });

  const login = async () => {
    try {
      setSubmitting(true);
      const user = await signInWithEmailAndPassword(
        auth,
        loginEmail,
        loginPassword
      );
      console.log(user);
      setSubmitting(false);
      } catch (error) {
          console.log(error.message);
          setSubmitting(false)
          setMessage({
              error: true,
              text: "Incorrect email or password",
            });
      }
  };

  const loginGoogle = () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');
    signInWithPopup(auth, provider).then(function(result) {
      console.log(result);
    }).catch((error)=>{
      console.log(error);
    });
  }

  const updateFormControlEmail = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    setFormState(updatedFormState);
    setLoginEmail(value);
  };

  const updateFormControlPassword = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement >
  ) => {
    const { id, value } = event.target;
    const key = id as keyof FormState;
    const updatedFormState = { ...formState };
    updatedFormState[key] = value;
    setFormState(updatedFormState);
    setLoginPassword(value);
  };

  const title = <><IoIcons5.IoLogIn /> Log In</> ;

  const [isSelected1, setIsSelected1] = useState(false);
  const [isSelected2, setIsSelected2] = useState(false);

  const emailAdornment = isSelected1
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <IoIcons.IoIosMail style={{fontSize: "110%"}}/>
          </InputAdornment>
        )
      }
  : {};

  const passAdornment = isSelected2
    ? {
        startAdornment: (
          <InputAdornment position="start">
            <FaIcons.FaKey />
          </InputAdornment>
        )
      }
  : {};

  return (
    <main>
      <div id='top' style={{justifyContent: 'center', paddingBottom: 40}}>
        <Helmet>
            <title>Alex Bailey Resume | Login</title>
        </Helmet>
        <Box sx={{ display: 'inline-block', margin: '0 1rem'}}>
          <ThemeProvider theme={theme}>
            <Card sx={{ minWidth: 150, backgroundColor: "#e3e3e3", fontFamily: 'Oswald' }}>
              <CardHeader title={title} />

              {message && (
                  <div>
                      <Alert style={{margin: "0 1rem"}} severity="error">{message.text}</Alert>
                  </div>
              )}
              
              <CardContent> 

              {/* Email */}
              <div className="padded">
                  <TextField 
                  required
                  id="email" 
                  label="Email"
                  variant="outlined"
                  type="email"
                  onChange={updateFormControlEmail}
                  value={formState?.email} 
                  sx={{width: getWidth()}}
                  InputProps={emailAdornment}
                  onFocus={e => setIsSelected1(true)}
                  onBlur={formState.email ? undefined : e => setIsSelected1(false)}
                  onInput={e => setIsSelected1(true)}
                  />
              </div>

              {/* Password */}
              <div className="padded">
                  <TextField 
                  required
                  error={errMsg}
                  id="password" 
                  label="Password" 
                  variant="outlined"
                  type="password"
                  onChange={updateFormControlPassword}
                  value={formState?.password} 
                  sx={{width: getWidth()}}
                  InputProps={passAdornment}
                  onFocus={e => setIsSelected2(true)}
                  onBlur={formState.password ? undefined : e => setIsSelected2(false)}
                  onInput={e => setIsSelected2(true)}
                  />
              </div> 

              </CardContent>

              <CardActions sx={{margin: '1em 1em', justifyContent: 'center'}}>
              <FormSubmitButton onClick={login} disabled={submitting} style={{marginTop: "-30px"}}>
                  {submitting ? "Logging In..." : "Log In"}
              </FormSubmitButton>
              </CardActions>
              <CardActions sx={{margin: '1em 1em', marginTop: '-1em', justifyContent: 'center'}}>
                <button type="button" className="login-with-google-btn" onClick={loginGoogle}>Sign in with Google</button>
              </CardActions>
              <CardActions sx={{margin: '0 1em', justifyContent: 'right'}}>
                <Link to='/create-account'>Create an account</Link>
              </CardActions>
            </Card>
          </ThemeProvider>
        </Box>
        <p style={{color: '#0C4773', fontWeight: 'bold'}}><BsIcons.BsFillInfoCircleFill style={{color: '#323232'}} /> Please ensure popups are enabled for Google Sign-In</p>
        
        {loggedIn && <Dash />}

      </div>
    </main>
  );
}

export default Login;