import './App.css';
import Sidebar from './Components/Sidebar';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { LandingPage } from './Pages/Landing';
import { Home } from './Pages/Home';
import { Desktop } from './Pages/Desktop';
import { Resume } from './Pages/Resume';
import { Footer } from './Pages/Footer';
import { UnderConst } from './Pages/UnderConstruction';
import { GitPage } from './Pages/Git';
import { JDetails } from './Pages/JobDetails';
import { EDetails } from './Pages/EdDetails';
import { AboutMe } from './Pages/AboutMe';
import { Dashboard } from './Pages/Dashboard';
//import { AddJobPage } from './Pages/Admin/Jobs/AddJob';
import { NotFoundPage } from './Pages/NotFound';
import ContactMeForm from './Pages/ContactMe.tsx';
import Login from './Pages/Login.tsx';
import Create from './Pages/CreateAccount.tsx';

function App() {
  return (
    <Router>
      <Sidebar />
      <Routes>
        {/* Home */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/:id" element={<NotFoundPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/home/:id" element={<NotFoundPage />} />

        {/* Hardware */}
        <Route path="/hardware/:id" element={<NotFoundPage />} />
        <Route path="/hardware/desktop" element={<Desktop />} />
        <Route path="/hardware/desktop/:id" element={<NotFoundPage />} />
        <Route path="/hardware/laptop" element={<UnderConst />} />
        <Route path="/hardware/laptop/:id" element={<NotFoundPage />} />

        {/* Resume */}
        <Route path="/resume/:id" element={<NotFoundPage />} />
        <Route path="/resume/about" element={<AboutMe />} />
        <Route path="/resume/about/:id" element={<NotFoundPage />} />
        <Route path="/resume/view_resume" element={<Resume />} />
        <Route path="/resume/view_resume/:id" element={<NotFoundPage />} />
        <Route path="/resume/view_resume/jobs/:id" element={<JDetails />} />
        <Route path="/resume/view_resume/education/:id" element={<EDetails />} />

        {/* Projects */}
        <Route path="/projects/:id" element={<NotFoundPage />} />
        <Route path="/projects/git" element={<GitPage />} />
        <Route path="/projects/git/:id" element={<NotFoundPage />} />
        <Route path="/projects/soteria_cat" element={<UnderConst />} />
        <Route path="/projects/soteria_cat/:id" element={<NotFoundPage />} />
        <Route path="/projects/spot_finder" element={<UnderConst />} />
        <Route path="/projects/spot_finder/:id" element={<NotFoundPage />} />
        <Route path="/projects/resume_site" element={<UnderConst />} />
        <Route path="/projects/resume_site/:id" element={<NotFoundPage />} />
        <Route path="/projects/chucktown_sound" element={<UnderConst />} />
        <Route path="/projects/chucktown_sound/:id" element={<NotFoundPage />} />

        {/* Contact */}
        <Route path="/contact/" element={<ContactMeForm />} />
        <Route path="/contact/:id" element={<NotFoundPage />} />

        {/* Login */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/:id" element={<NotFoundPage />} />
        <Route path="/create-account" element={<Create />} />
        <Route path="/create-account/:id" element={<NotFoundPage />} />

        {/* Admin */}
        <Route path="/admin" element={<UnderConst />} />
        <Route path="/admin/:id" element={<NotFoundPage />} />
        <Route path="/admin/manage-jobs" element={<UnderConst />} />
        <Route path="/admin/manage-jobs/add" element={<UnderConst />} />
        <Route path="/admin/manage-jobs/edit" element={<UnderConst />} />
        <Route path="/admin/manage-jobs/:id" element={<NotFoundPage />} />
        <Route path="/admin/manage-ed" element={<UnderConst />} />
        <Route path="/admin/manage-ed/add" element={<UnderConst />} />
        <Route path="/admin/manage-ed/edit" element={<UnderConst />} />
        <Route path="/admin/manage-ed/add-ex" element={<UnderConst />} />
        <Route path="/admin/manage-ed/edit-ex" element={<UnderConst />} />
        <Route path="/admin/manage-ed/:id" element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
